

<!--<div class="margin-side-35p"> -->
  <div style="margin-left:{{gvars['center-width']}}px">

      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" style='width:80%'>
                <div ngxSlickItem class="slide">
                          <mat-card class="example-card">
                                 <mat-card-actions>
                                   <div class='flex-column'>
                                     <p> </p> <!--space holder for alignment -->
                                     <ng-container *ngFor="let button of buttons">
                                        <button mat-raised-button
                                                class="{{mouseClass[button]}} max-width margin-top-10px curvey-border "
                                                 (click)="clicked(button,0)">{{button}}
                                                 <mat-icon *ngIf="button=='Audio'"> play_circle</mat-icon>
                                               </button>
                                     </ng-container>

                                  </div>
                                 </mat-card-actions>
                         </mat-card>
                </div>
                <div ngxSlickItem class="slide">
                        <mat-card class="example-card">

                               <mat-card-title> {{matcard.label}}
                                 </mat-card-title>

                                <mat-card-subtitle> {{ matcard.subtitle}}
                                </mat-card-subtitle>

                               <mat-card-content>
                                 <p> {{matcard.content}}</p>
                               </mat-card-content>

                               <mat-card-actions>
                                 <div class='flex-column'>
                                   <ng-container *ngFor="let button of buttons">
                                      <button mat-raised-button
                                              class="{{mouseClass[button]}} max-width margin-top-10px curvey-border " (click)="clicked(button,1)">
                                              {{button}}
                                                 <mat-icon *ngIf="button=='Audio'"> play_circle</mat-icon>
                                            </button>
                                   </ng-container>

                                </div>
                               </mat-card-actions>
                       </mat-card>
                 </div>
      </ngx-slick-carousel>

      <div style="width:25%;margin-left:50px; margin-top:20px" *ngIf="audioEnabled" >
            <ngx-audio-player [autoPlay]="false" muted="muted"

                [playlist]="mssapPlaylist"
                [disablePositionSlider]="false"
                [displayRepeatControls]="true"
                [displayVolumeControls]="true"
                [displayVolumeSlider]="true"

                [displayTitle]="'adding-count'"

                (trackEnded)="onEnded($event)"
                >

           </ngx-audio-player>
       </div>

   </div>
