import { Injectable } from '@angular/core';
import { DealerService } from '../../services/deck.service';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { print } from '../../services/common.service';
import { Arbiter } from './arbiter.service';
import { globalVars } from '../../services/global.service';
import {
  Cntrl2SwitchSetupDm, Cntrl2switchDecisionDm, Switch2CntrlDm, NONE, SECOND_SPEED, DISABLE_EXTENDED,
  DROP_BEFORE_DRAW, REPLACE, DEALER_DISPLAY_ID,
  DEALER_FACEUP,DEALER,
  CARDS_DRAWN, Switch2CardDisplayDm, CardDm, GET_DECISION, END_OF_HAND,  IGNORE, DISPLAY_ARBITER, ENABLE_EXTENDED,UPDATE_COUNT,
  INSURANCE, RESET, SPLIT, STAND, DOUBLE, HIT, INSURANCE_YES, INSURANCE_NO, DROP, PLAYER,  DELETE_SECOND,
  Hand, DISPLAY_BADGE,
  FAKE_CARD, Switch2CntrParam, REPLACE_BLANK,  CHANGE_BORDER,  CardDisplay2SwtichDm } from '../../store/switchcards.model';

import { InsertS2CAction, InsertSwitch2CardDisplayAction} from '../../store/switchcards.action.reducer';
import { MobileInsertPlayCardAction, MobileInsertStackBadgeAction} from '../store/cards.action.reducer'
import { Subscription } from 'rxjs';
import { CardOverWrite } from './cardoverwrite.service';
import {GenericUpdateRule} from '../store/generic.action.reducer';
const DRAW = 'Draw';



interface BufferedCardsDm {
  id: number;
  card: CardDm;
  type: string;
};
type BufferedCardsList = BufferedCardsDm [];
const TWO_CARDS_DRAW = '2cards-draw';

interface BufferdActionList {
  list: BufferedCardsList;
  phase: string;
}


@Injectable({providedIn: 'root'})
export class CardswitchMobileService {
  arbiter = new Arbiter();
  subscription: Subscription [] = [];
  bufferedCards: BufferedCardsList = [] ;
  bufferedAction: BufferdActionList = { phase: 'None', list:[]};
  cntrl2SwitchSetupDm: Cntrl2SwitchSetupDm;
  decisionList: string [];
  cardCount = 0;
  previousId =  -1;
  extendedFor = -1;
  // aceDoneWithSplit = false;
  displayed = {};
  endOfHand = false;
  did: number;
  dealerValue: number;
  debug = {
    INSURANCE: false,
    STAND: false,
    DOUBLE: false,
    SPLIT: false,
    HIT: false,
    DEALER: false,
    others: false
  };
  scheduleDealer = false;
  cardSpeed: number;
  extraDelay: number;
  dealerCardsout = 0;
  dealerTurn=false;
  interval: any;
  extendedHandActive=false;

  insuranceAllowed: boolean;

  queuedSplitHand:any = [];

  constructor(
    private dealerService: DealerService,
    private store: Store<any>,
    private cardOverWrite: CardOverWrite

  ) {
    if (0) {
  // moved to train-play
      this.cardOverWrite = new CardOverWrite();


      // this.cardOverWrite.playSplitSingleDealer2Aces();
      // this.cardOverWrite.playSplitSingle()
      //this.cardOverWrite.playSplitDouble()
      // this.cardOverWrite.play3dealerTest();
      // this.cardOverWrite.play1dealerAceup2();
      // this.cardOverWrite.play1split2();
      // this.cardOverWrite.play3split1();
      //this.cardOverWrite.play3random();
      // this.cardOverWrite.play3split31();
      // this.cardOverWrite.play1splitAce();
      // this.cardOverWrite.play1splitAce4();
      // this.cardOverWrite.play1soft17();
      // this.cardOverWrite.play3splitAceMiddle4();
      // this.cardOverWrite.play3bjack();
      // this.cardOverWrite.play3bjack1();
      //this.cardOverWrite.play3bjack1v2();
      //this.cardOverWrite.ruleErr_tmp();
      //this.cardOverWrite.nines();
      //this.cardOverWrite.player21();
      //this.cardOverWrite.misc();
      //this.cardOverWrite.sixteen_hi();
      //this.dealerService.modifyCards( this.cardOverWrite.getCards());
      // dealer 4th cards are not drawn
      // this.cardOverWrite.dealer4cards();
      //this.cardOverWrite.dealerShowingAce();
      // this.cardOverWrite.dealer21();
       // this.cardOverWrite.dealer21f3hands();
      // this.cardOverWrite.dealerAceUp3hands();

      // this.cardOverWrite.dealer1f3hands();
      // this.cardOverWrite.play3dealerAceUp();
      // this.cardOverWrite.dealer1f3hands();
      // this.cardOverWrite.playOneSimpleSplit();
      // this.cardOverWrite.play3splitAceMiddle();
      // this.cardOverWrite.play3borderStopOn3ndhand();
      // this.cardOverWrite.play3borderStopOn2ndhand();
      // this.cardOverWrite.split4strongHigh();
      // this.cardOverWrite.play3splitAceMiddle();
      // this.cardOverWrite.misc2();
      // this.cardOverWrite.play1soft17();
      // this.cardOverWrite.playSplitSingleDealer2Aces();
      // this.cardOverWrite.misc1();
      // this.cardOverWrite.cardcounting_demo();
      // this.cardOverWrite.playerBjDealerFaceupSingleHand();
      //this.cardOverWrite.playSplit8();
      // this.cardOverWrite.player3handsMisc();
        // this.cardOverWrite.player3handsMisc();
      // this.cardOverWrite.player2handsMisc();
  //    this.cardOverWrite.player1handMisc();
        this.cardOverWrite.exposeSingleHand();
        this.dealerService.insertOverWrite(this.cardOverWrite.getcards());

    }
  }

  destroy():void {
    for (const subscription of this.subscription) {
      subscription.unsubscribe();
    }
  }

  getCard():any {
    // this.cardCount is different that cardCount class; it is used mainly for tracking of cards
    const card = this.dealerService.singleCard(this.cardCount);
    this.cardCount++;
    return card;
  }
  
  draw2cards() :void{
    const ddm:Cntrl2SwitchSetupDm = this.cntrl2SwitchSetupDm ;
    this.did=ddm.did();
    // console.log(`draw2cards`);
    const handsIndex = ddm.handsIndex();
    this.arbiter.reset(handsIndex);
    this.arbiter.dealer(this.did);
    this.dealerTurn= false;
    this.insuranceAllowed= true;
    if (this.debug.others) {
      console.log(`arbiter number of hands: ${handsIndex.length}  handsIndex:${handsIndex}`);
    }
    this.bufferedAction.phase = TWO_CARDS_DRAW;
    const list = this.bufferedAction.list;
    this.cardSpeed= globalVars['speed'];
    this.cardCount = 0;
    this.extendedFor = -1;
    let dcardsCount = 1;
    for(let k = 0; k !== 2; k++) { // 2 is for drawing 2 cards for each hand
      for (const index of handsIndex) {
        const displayId: number = this.arbiter.get(DRAW);
        const handType = ddm.hands[index].type;
        const card: CardDm = this.getCard();
//print(['draw2cards::card', card]);
        // console.log(`setting displayid:${displayId} to true in draw2cards`);
        // let type = PLAYER;
        let type = NONE;
        if (handType === DEALER) {
          type = DEALER;
          dcardsCount++;
        } else if (handType === PLAYER) {
          // type = `hand ${displayId + 1}`;
          type = ddm.getTitle(displayId)
        }
        // console.log(`arbiter displayId: ${displayId} card:${JSON.stringify(this.dealerService.get())}`);
        list.push({ id: displayId, card: card, type: type});
      }
    }
    // console.log(`draw2cards: buffer-list-length:${list.length}`);
//print(`draw2cards here-1`);
    this.sendBuffered();
//print(`draw2cards here-2`);
  }
  sendBuffered(sendEnable?: boolean):void {
    const buffered:any = this.bufferedAction.list.shift();
    this.cntrl2SwitchSetupDm.hands[buffered.id].push(buffered.card);
    MobileInsertPlayCardAction (this.store,[buffered.card], buffered.id, buffered.type,0) ;
  }

// export type Switch2CntrParam = {
//   id: number;
//   action: string;
//   hands: {};
//   decisions: string[];
// };

  tookInsurance(data: Cntrl2switchDecisionDm, taken: boolean):void {
    const ddm = this.cntrl2SwitchSetupDm;
    let id = this.arbiter.current();
    const action = data.action;
    let hand = ddm.hands[id];
//print(['tookInsurance:', taken]);
    if (taken) {
        ddm.tookInsurance(id);
        hand.adjust('Insurance');
    }
    id = this.arbiter.get(INSURANCE);
    let speed=1000;
    if (id === this.did) {
       this.insuranceAllowed=false;
       this.moveBorder(DEALER_DISPLAY_ID,false,speed);
      if( ddm.dealerBjack()) {
        setTimeout(() =>  this.dealerHit2end() , speed+500);
        return ;
      } else {
          id = this.arbiter.get(INSURANCE);
          hand = ddm.hands[id];
          if (hand.sum()>16) {
              this.arbiter.drop(id);
              if (id === this.did) {
                    setTimeout(() =>  this.dealerHit2end() , speed+1000);
                    return;
              }
              const nxtHandId =  this.arbiter.get(HIT);
              this.handEnded(nxtHandId,0)
              return;
          }
          const offset = hand.sum()>16 ? 500 : 1000;
          this.moveBorder(id,false,speed+offset);
          GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));
          setTimeout(()=>
             this.send2cntrl(
               {
                 id: id, action: GET_DECISION,
                 hands: this.cntrl2SwitchSetupDm.hands,
                 decisions: ddm.getDecisions(id, globalVars['strategy'])
               }), speed+offset);
      }
      return ;
    } else {
     this.moveBorder(id,false,speed);
     setTimeout(()=>
       this.send2cntrl(
         {
           id: id, action: GET_DECISION,
           hands: this.cntrl2SwitchSetupDm.hands,
           decisions: [INSURANCE_YES,INSURANCE_NO]
         }), speed+500);
    }
  }
//
// add ace to the value for badging
  updateAce(value):any {
    return (1<<7) | value;
  }
 dsum(dhand) :any{
   let sum = dhand.sum();
   if (dhand.soft17() && globalVars['soft17']=='stay') { sum = 17;}
   else if ( dhand.soft18() ) { sum = 18; }
   return sum;
 }
//////////////////
//khan dealer cards
//////////////////
  dealerHit2end():void {
    const ddm = this.cntrl2SwitchSetupDm;
    const cards: CardDm[] = [];
    const dhand = ddm.hands[this.did];
    let aceExists=dhand.aceExists() == -1 ? false: true;
    const length = dhand.length();
    this.dealerValue = this.dsum(dhand);

    //const over =  ddm.allBusted() ||  ddm.allBlackJack() || this.dealerValue>16;
    const over =  ddm.dealerOpencards() ? this.dealerValue>16: true;
    this.moveBorder(this.did, false);
    // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(this.did, [], DEALER_FACEUP,  over?this.dealerValue: 0 )));


    if (this.debug.DEALER) { dhand.display(); }
    if  (over) {
      if ( this.debug.others) {
        console.log(`ddm.allbusted:${ddm.allBusted()} ddm.allBlackJack:${ddm.allBlackJack()}`);
      }
      MobileInsertPlayCardAction (this.store,[], this.did, DEALER_FACEUP,over?this.dealerValue: 0) ;
      ddm.calculate(this.dealerValue, length);
//print('dealerHit2end sending end-of-hand@1');
      this.send2cntrl({ id: 0, action: END_OF_HAND, hands: this.cntrl2SwitchSetupDm.hands, decisions: []}, 1000);
      MobileInsertStackBadgeAction(this.store, `${this.dealerValue}`, this.did);
      return;
    }
//print(`exiting early out of dealer2hit`); return;
    this.dealerCardsout=0;
    // this.dealerService.adjustCardCount();

    while (true) {
      //  console.log(`dealer cards value: ${value}`);
      if (this.debug.DEALER) { dhand.display(); }
      if (this.dealerValue > 16 ){
        break;
      }
      else {
        const card: CardDm = this.getCard();
        //console.log(`dealer drawing : ${JSON.stringify(card)}`);
        this.dealerCardsout++;
        dhand.push(card);
        cards.push(card);
        this.dealerValue=this.dsum(dhand);
        // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(this.did, [card], DEALER,0)));

      }
    }
    MobileInsertPlayCardAction (this.store,cards, this.did, DEALER, 0) ;

    this.endOfHand = true; // terminate when the cards are drawn
    setTimeout(() => {
      MobileInsertStackBadgeAction(this.store, `${this.dealerValue}`, this.did);
    }, cards.length*globalVars['speed']);
    ddm.calculate(this.dealerValue, dhand.length);
  }
  ////////////////
  // moveBorder definition
 //////////////////
  moveBorder(index: number, extended:any,delay=0):void {
    const list = [`${index}`]
    const ddm=this.cntrl2SwitchSetupDm;
    if (extended) {
       list.push(`${ddm.getExtendedDisplay()}`);
    }
    setTimeout(() => {
      this.send2cntrl( { id: -1, action: CHANGE_BORDER,
             hands: ddm.hands, decisions: list });
    },delay);

  }

  send2cntrl(params: Switch2CntrParam, delay=0):void {
   if (delay) {
    setTimeout(()=>{this.store.dispatch(new InsertS2CAction(new Switch2CntrlDm(params)));}, delay);
    return;
   }
   this.store.dispatch(new InsertS2CAction(new Switch2CntrlDm(params)));
  }

  aceSplitAllowed() :boolean{
    return  [1, 2].includes(globalVars['ndecks']) ? false: true;
  }

  /*
   * taking card
   */

  takeCard(data: Cntrl2switchDecisionDm):void {
    const ddm = this.cntrl2SwitchSetupDm;
    let action = data.action;
    let id =  this.arbiter.current() ;
    const hand = ddm.hands[id];
    this.extraDelay = 0;
    const card: CardDm = this.getCard();
    let type = hand.type;
    const tHands = ddm.totalHands();
    let hLength = ddm.pHandLength(id);
    const split = hLength == 1;

    hand.push(card);
    hand.pushDecision(action);
    hLength++;
    const extendedDisplay = tHands > 1 && hLength > 2 ;
    const iid = id & 7;
    const displayId = extendedDisplay ? ddm.getExtendedDisplay() : iid;
    if (extendedDisplay) {
      type = ddm.getTitle(id);
    }
    if (this.debug.HIT) {
      console.log(`takeCard hand-id: ${id} next: display:${displayId} action:${action} type:${type} total-hands:${tHands}`);
    }

    if (this.debug.HIT) {
      console.log(`@${this.dealerService.time()} takecard scheduling card`);
    }
//debugging speed
// send every card to display
   const double = action === DOUBLE;
   const currentHandSplit=this.arbiter.isSplitId(id);
   const aceSplit = currentHandSplit && hand.wasItAceSplit() ;
   const hSum =  hand.sum();
   const g16 =  hSum>16;
   const stillSplit = g16 && hand.split();
   const displayValue = g16 || aceSplit || double ? hSum: 0;
   // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(displayId, [card],type,displayValue)));
   // console.log(`Going to insert a card to display-id:${displayId}`);

   // MobileInsertPlayCardAction (this.store,[card], displayId, type, displayValue) ;
   MobileInsertPlayCardAction (this.store,[card], iid, type, displayValue) ;



    let speed = this.cardSpeed;
//print (['card speed:', speed]);
   if (double || ddm.busted(id)) {
      this.arbiter.drop(id);
      const nxtHandId =  this.arbiter.get(HIT);
      if (double) {
         hand.adjust('Double');
      }

      if (this.arbiter.isSplitId(id)) {
       if (this.arbiter.isSplitId(nxtHandId)) {
          this.dropHand(id, speed+1000, REPLACE);
          this.splitAction(data,nxtHandId, speed+1500);
          return ;
       }
      }
      // if (qualify) {
      //    this.dropHand(id, speed+1000);
      // }
      // if (nxtHandId!=this.did) {
      //     this.dropHand(id, speed+1000);
      // }
      // MobileInsertStackBadgeAction(this.store, `${hSum}`, iid);
      MobileInsertStackBadgeAction(this.store, `${hSum}`, this.arbiter.realId(id));

      this.handEnded(nxtHandId, speed);
      return;
   }
   if (currentHandSplit) {
      if (hand.wasItAceSplit()) {
         this.arbiter.drop(id);
         const nxtHandId =  this.arbiter.get(HIT);
         const nxtHandDealer =  nxtHandId==this.did;

         if (this.arbiter.isSplitId(nxtHandId)) {
             this.dropHand(id, speed+1000, REPLACE);
             this.splitAction(data,nxtHandId, speed+1500);
             return ;
         }
         // if (!nxtHandDealer) {
         //     this.dropHand(id, speed+1000);
         // }
           // MobileInsertStackBadgeAction(this.store, `${hSum}`, iid);
           MobileInsertStackBadgeAction(this.store, `${hSum}`, this.arbiter.realId(id));

         this.handEnded(nxtHandId, speed);
         return;
      }
      // non ace split
      if (g16 && !stillSplit) {
        this.arbiter.drop(id);
        const nxtHandId =  this.arbiter.get(HIT);
        const nxtHandDealer =  nxtHandId==this.did;

        if (this.arbiter.isSplitId(nxtHandId)) {
             this.dropHand(id, speed+1000, REPLACE);
             this.splitAction(data,nxtHandId, speed+1500);
             return ;
        }
        // if (qualify) {
        //      this.dropHand(id, speed+1000);
        // }
        // if (!nxtHandDealer) {
        //     this.dropHand(id, speed+1000);
        // }
          // MobileInsertStackBadgeAction(this.store, `${hSum}`, iid);
          MobileInsertStackBadgeAction(this.store, `${hSum}`, this.arbiter.realId(id));

        this.handEnded(nxtHandId,speed);
        return;
      } else { // continue with the same hand
        GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));

         setTimeout(() => {
            this.send2cntrl({
                 id: id, action: GET_DECISION,
                 hands: this.cntrl2SwitchSetupDm.hands,
                 decisions: ddm.getDecisions(id, globalVars['strategy'])
             });
           }, speed+1500);
           return ;
      }
   }


    if (g16) {
//print(`takecard id:${id}, hand.sum: ${hand.sum()}`);
        this.arbiter.drop(id);
        const nxtHandId =  this.arbiter.get(HIT);
        const nxtHandDealer =  nxtHandId==this.did;
        GenericUpdateRule(this.store, `Stand`);

        if (this.arbiter.isSplitId(nxtHandId)) {
          this.dropHand(id, speed+1000, REPLACE);
          this.splitAction(data,nxtHandId, speed+1500);
           return;
        }
        // if (qualify) {
        //   this.dropHand(id, speed+1000);
        // }
          // MobileInsertStackBadgeAction(this.store, `${hSum}`, iid);
          MobileInsertStackBadgeAction(this.store, `${hSum}`, this.arbiter.realId(id));

        this.handEnded(nxtHandId, speed);
        return;
    }
    setTimeout(() => {
      GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));
      this.send2cntrl({
        id: id, action: GET_DECISION,
        hands: this.cntrl2SwitchSetupDm.hands,
        decisions: ddm.getDecisions(id, globalVars['strategy'])
      });
    }, speed+500);
  }
//////////////////
// dropHand define
//////////////////
  dropHand (id:any, delay:any, type=DROP):void {
    const length = this.cntrl2SwitchSetupDm.hands[id].length();
    if (length > 2) {
       setTimeout(() => {
             // this.store.dispatch(new InsertSwitch2CardDisplayAction(
             //      new Switch2CardDisplayDm(this.cntrl2SwitchSetupDm.getExtendedDisplay(), [], DROP,0)));
                  MobileInsertPlayCardAction (this.store,[], this.cntrl2SwitchSetupDm.getExtendedDisplay(), DROP, 0) ;

        }, delay/2);
    }
    setTimeout(() => {
        // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(id&7, [], type,0)));
        MobileInsertPlayCardAction (this.store,[], id&7, type, 0) ;

      }, delay);
  }

  splitAction(data,id, delay) {
//print(`cardSpeed: ${this.cardSpeed}, dropDealy: ${delay}`);
    data.action = HIT;
    setTimeout(() => this.takeCard(data), delay);
  }

 walkStandHands() :void {
     const nxtHandId =  this.arbiter.get(HIT);
     const ddm = this.cntrl2SwitchSetupDm;
     const hand = ddm.hands[nxtHandId];
      this.moveBorder(nxtHandId, false);
      // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(nxtHandId&7, [],DISPLAY_BADGE,hand.sum())));
      MobileInsertPlayCardAction (this.store,[], nxtHandId&7, DISPLAY_BADGE, hand.sum()) ;

     // look at next hand
//print(`walkStandHand:  nxtHandId:${nxtHandId}`);
     if (nxtHandId==this.did) {
        const id=this.arbiter.previousId();
        //console.log(`handEnded: id:${id}, sum:${this.cntrl2SwitchSetupDm.playerSum(id)}`)
        MobileInsertStackBadgeAction(this.store, `${this.cntrl2SwitchSetupDm.playerSum(id)}`, this.arbiter.realId(id));
         setTimeout(() => { this.dealerHit2end() ; }, 500);
         clearTimeout(this.interval);
         return;
     }
     const nxtDecision = ddm.getDecisions(nxtHandId, globalVars['strategy']);
     if (nxtDecision.length==1 && nxtDecision[0]==STAND) {
         this.arbiter.drop(nxtHandId);
         return;
     }
     GenericUpdateRule(this.store, ddm.getDecisions(nxtHandId, 'book'));
    setTimeout(() => {
             this.send2cntrl({
                id: nxtHandId, action: GET_DECISION,
                hands: this.cntrl2SwitchSetupDm.hands,
                decisions: nxtDecision
           });
     }, 1000);
     clearTimeout(this.interval);
  }
//////////////////
//handEnded definition
//////////////////
  handEnded( nxtHandId:any, speed:any):void {
      this.moveBorder(nxtHandId, false,speed+300);
//print(['handEnded: nxtHandId:', nxtHandId,', this.did:', this.did,', dealer-hand:',nxtHandId==this.did ])
      if (nxtHandId==this.did) {

         setTimeout(() => { this.dealerHit2end() ; }, speed+500);
         return;
      }
      const nxtDecision = this.cntrl2SwitchSetupDm.getDecisions(nxtHandId, globalVars['strategy']);
      const psum=this.cntrl2SwitchSetupDm.playerSum(nxtHandId);
      // if (nxtDecision.length==1 && nxtDecision[0]==STAND) {
        if (nxtDecision.length==1 && nxtDecision.includes(STAND)) {

         this.arbiter.drop(nxtHandId);
         GenericUpdateRule(this.store, `Stand`);

         MobileInsertStackBadgeAction(this.store, `${psum}`, this.arbiter.realId(nxtHandId));
         this.interval= setInterval( ()=> { this.walkStandHands(); }, speed+1000);
         return;
      }
       GenericUpdateRule(this.store, this.cntrl2SwitchSetupDm.getDecisions(nxtHandId, 'book'));
      setTimeout(() => {
           this.send2cntrl({
              id: nxtHandId, action: GET_DECISION,
              hands: this.cntrl2SwitchSetupDm.hands,
              decisions: nxtDecision });
       }, speed+500);

  }

  qualifyForHandPanel(length: number, isSplit:any, nxtDid:any) {
/*
    if (nxtDid) return false;// if dealer is next
    if (isSplit) return true; // is split hand
    return length>2;
*/
   return nxtDid? false: true;
  }



  tookSplit(data: Cntrl2switchDecisionDm) :void{
    const ddm = this.cntrl2SwitchSetupDm;
    const id = this.arbiter.get(data.action);
    if (id < 16) {
      ddm.setFirstSplitTitle(id);
    }
    // const type = ddm.hands[id].type;
    ddm.newHand(id,this.arbiter.splitId());
    this.dropHand(id, 0, REPLACE);
    this.splitAction(data, id, 1000);
  }

  tookStand(data: Cntrl2switchDecisionDm) :void{
    const ddm = this.cntrl2SwitchSetupDm;
    let  id = this.arbiter.current();
    const hand = ddm.hands[id];
    const length = hand.cards.length;
    this.arbiter.drop(id);
    const nxtHandId =  this.arbiter.get(HIT);
    const currentHandsplit = this.arbiter.isSplitId(id);
    const isNxtsplit = this.arbiter.isSplitId(nxtHandId);
    const nxtDealerhand = nxtHandId==this.did;
    // case to send to hands panel


    if (currentHandsplit) {
       if (isNxtsplit) {
         this.dropHand(id, 1000, REPLACE);
         this.splitAction(data,nxtHandId, 1500);
         return ;
       }
    }
     // if (!nxtDealerhand){ this.dropHand(id, 300); }
    let sum=hand.sum();
    sum = [7,8].includes(sum)? sum+10: sum;
    // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(id&7, [],DISPLAY_BADGE,sum)));
    // MobileInsertPlayCardAction (this.store,[], id&7, DISPLAY_BADGE, sum) ;

    // if (nxtDealerhand) {
    //    this.dealerHit2end();
    //    return;
    // }
    ddm.hands[id].pushDecision(STAND);
    MobileInsertStackBadgeAction(this.store, `${sum}`, this.arbiter.realId(id));
    this.handEnded(nxtHandId,0);
  }

  dealerBj():void {
//print('dealerHit2end sending end-of-hand@3');
       const ddm = this.cntrl2SwitchSetupDm;
            // this.store.dispatch(new InsertSwitch2CardDisplayAction(
            //        new Switch2CardDisplayDm(this.did, [], DEALER_FACEUP,21)));
                   MobileInsertPlayCardAction (this.store,[], this.did, DEALER_FACEUP, 21) ;

            ddm.calculate(21,2);
            this.send2cntrl({ id: 0, action: END_OF_HAND, hands: ddm.hands, decisions: [] }, 1000);
  }


 byPassHand():boolean { // returns true if last hand is dealer
   const ddm = this.cntrl2SwitchSetupDm;
   let id = this.arbiter.get(INSURANCE);
   if (id ==  this.did) {
    this.insuranceAllowed= false;
    if (ddm.dealerBjack()) {
     //    setTimeout(() =>  this.dealerHit2end() , 1500);
         return true;
    } else {
     const handsIndex = ddm.handsIndex();
     this.arbiter.reset(handsIndex);
     id=this.arbiter.current();
     this.moveBorder(id, false,1500);
     GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));
     const decision =  ddm.getDecisions(id, globalVars['strategy']);
     this.send2cntrl({ id: id, action: GET_DECISION, hands: this.cntrl2SwitchSetupDm.hands, decisions:decision }, 1800);
     return false;

    }
   } else {
     this.moveBorder(id, false,1500);
     GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));
     const decision =  ddm.getDecisions(id, globalVars['strategy']);
     this.send2cntrl({ id: id, action: GET_DECISION, hands: this.cntrl2SwitchSetupDm.hands,
                                           decisions:[INSURANCE_YES,INSURANCE_NO] }, 1800);

     return false;
   }
 }

  config() :void{
    this.subscription.push(this.store.select('scard')
      .pipe(filter(data => data && data.dtype === 'Cntrl2SwitchSetupDm'))
      .subscribe((data: Cntrl2SwitchSetupDm) => {
        if (this.debug.others) {
          console.log(`switch2controlSetup data received: ${JSON.stringify(data)}`);
        }
        globalVars['reset'] = false;
        this.cntrl2SwitchSetupDm = data;
        this.draw2cards();
      }));
      this.subscription.push( this.store.select('scard')
      .pipe(filter(data => data.data_type === 'Cntrl2switchDecisionDm'))
      .subscribe((data: Cntrl2switchDecisionDm) => {
           if (this.debug.others) {
             console.log(`incoming action from cntrl2switch : action:${data.action}`);
           }
           switch(data.action) {
             case DISPLAY_ARBITER: {
               if (this.debug.others) {
                 this.arbiter.display();
               }
               break;
             }
             case UPDATE_COUNT: {
               // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(0, [], UPDATE_COUNT,0)));
               MobileInsertPlayCardAction (this.store,[], 0, UPDATE_COUNT, 0) ;

               break;
             }
             case RESET: {
               for(const displayId of  this.cntrl2SwitchSetupDm.handsIndex()) {
                 // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(+displayId, [], DROP,0)));
                 MobileInsertPlayCardAction (this.store,[], +displayId, DROP, 0) ;
               }
               break;
             }
             case INSURANCE_YES: {
               this.tookInsurance(data, true);
               break;
             }
             case INSURANCE_NO: {
               this.tookInsurance(data, false);
               break;
             }
             case DOUBLE:
             case HIT: {
               if (this.insuranceAllowed && this.cntrl2SwitchSetupDm.dealerPossibleBjack()) {
                   if (this.byPassHand()) {
                       this.dealerBj();
                   }
                   return ;
               }
               this.takeCard(data);
               break;
             }
             case STAND: {
               if (this.insuranceAllowed && this.cntrl2SwitchSetupDm.dealerPossibleBjack()) {
                   if (this.byPassHand()) {
                       this.dealerBj();
                   }
                   return ;
               }
               this.tookStand(data);
               break;
             }
             case SPLIT: {
               if (this.insuranceAllowed && this.cntrl2SwitchSetupDm.dealerPossibleBjack()) {
                   if (this.byPassHand()) {
                       this.dealerBj();
                   }
                   return ;
               }
               this.tookSplit(data);
               break;
             }
           }
      }));
      // Card display sending message of cards displayed
    this.subscription.push(this.store.select('scard')
      .pipe(filter(data => data.data_type === 'CardDisplay2SwtichDm'))
      .subscribe((data: CardDisplay2SwtichDm) => {
        // console.log(`cardswitch rcvd event(maybe:drawn) data:${JSON.stringify(data)}`)
        if (this.dealerTurn) {
           this.dealerTurn= false;
           this.dealerHit2end();
           return;
        }
        if (this.endOfHand) {
          if (--this.dealerCardsout) { return; }
          // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(DEALER_DISPLAY_ID, [],DISPLAY_BADGE,this.dealerValue)));
          // MobileInsertPlayCardAction (this.store,[], DEALER_DISPLAY_ID, DISPLAY_BADGE, this.dealerValue) ;

          this.send2cntrl(
            {
              id: 0, action: END_OF_HAND,
              hands: this.cntrl2SwitchSetupDm.hands,
              decisions: []
            });
            this.endOfHand = false;
            return;
        }
        // console.log(`buffer length: ${this.bufferedAction.list.length}`);
        if (this.bufferedAction.list.length) {
              this.sendBuffered();
              return ;
        }
        if (this.bufferedAction.phase === TWO_CARDS_DRAW ) {
            // const dealer = 3;
            const ddm = this.cntrl2SwitchSetupDm;
            const dealerAceUp = ddm.dealerAce();
            this.bufferedAction.phase = NONE;
            this.moveBorder(0,false);
            // console.log(`into this.bufferedAction`);
            if (dealerAceUp) {
              const decision= ddm.getDecisions(0, globalVars['strategy']);
              GenericUpdateRule(this.store, `No-insurance`);

              this.send2cntrl({ id:0, action:GET_DECISION,
                hands: ddm.hands,
                  decisions: [INSURANCE_YES,INSURANCE_NO] });
                return ;
            } else if (ddm.dealerBjack()) {
               // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(this.did, [], DEALER_FACEUP,21)));
               MobileInsertPlayCardAction (this.store,[], this.did, DEALER_FACEUP,0) ;
               ddm.calculate(21,2);
//print('dealerHit2end sending end-of-hand@5');
                this.send2cntrl({ id: 0, action: END_OF_HAND, hands: ddm.hands, decisions: [] }, 1000);
              return;
            } else if (ddm.dealerSum()===21) {
                  setTimeout(()=>this.dealerHit2end(), 500);
                  return;
            } else {
              // scan player to see which has blackjack
              let id=0;
              const hand = ddm.hands[id];
              let speed = 0;
              const split=hand.split();
              const sum=hand.sum();
              const soft=[17,18].includes(sum) && ddm.isSoft(id)? false: true;
              // console.log(`sum:${sum}, soft:${ddm.isSoft(id)}, soft:${soft}`)
              if (soft && sum>16 && !split)  {
                this.arbiter.drop(id);
                GenericUpdateRule(this.store, "Stand");
                // console.log('xxx')
                MobileInsertStackBadgeAction(this.store, `${sum}`, 0);
                const nxtHandId =  this.arbiter.get(HIT);
                // this.store.dispatch(new InsertSwitch2CardDisplayAction( new Switch2CardDisplayDm(id&7, [],DISPLAY_BADGE,hand.sum())));
                // MobileInsertPlayCardAction (this.store,[], id&7, DISPLAY_BADGE, hand.sum()) ;
                if (nxtHandId === this.did) {
                     setTimeout(() =>  this.dealerHit2end() ,speed+500);
                     return;
                }
                  // this will scann the next hand if is of 'stand' it will move the player id
                this.handEnded(nxtHandId,0);
                return;
              } /*
              else {
                this.moveBorder(id,false);
              }*/
              // console.log(`decisions:${ddm.getDecisions(id, globalVars['strategy'])}`);
              GenericUpdateRule(this.store, ddm.getDecisions(id, 'book'));
              this.send2cntrl({  id: id, action: GET_DECISION,
                                           hands: this.cntrl2SwitchSetupDm.hands,
                                           decisions: ddm.getDecisions(id, globalVars['strategy']) }, speed);
        }
      }
  }));
 }
}
