  <!-- <button mat-button class="thumbs-dims leave" > <mat-icon  [@rotate]="{value: value, params: {turn:turn}}" class="scale-p7" [color]="color"> {{icon}} </mat-icon>  {{value}}</button> -->
<!-- <mat-card class="leave" style="width:60px; height:30px; margin:0px; padding:0px !important"> -->
  <!-- <mat-card  style="width:60px; height:30px; margin:0px; padding:0px !important"> -->


     <ng-container *ngIf="label=='up' else down">
           <mat-icon  [@rotateUp]="_value"  (@rotateUp.done)="done()" [color]="'primary'"> {{icon}} </mat-icon> <span style="margin-left:5px"> {{value}} </span>
      </ng-container>
      <ng-template #down>
        <span style="margin-left:5px;color:red"> {{value}} <mat-icon  [@rotateDown]="_value" (@rotateDown.done)="done()"   [color]="'orange'"> {{icon}} </mat-icon> </span>
      </ng-template>
  <!-- </mat-card> -->
