export const rules = {
   'Book': {
     Soft:{
     7: {
        2:'s',3:'d',4:'d',5:'d',6:'d',7:'s',8:'s',9:'h',10:'h', 'A':'h'
        },
     6: {
        2:'d',3:'d',4:'d',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', 'A':'h'
        },
     5: {
        2:'h',3:'h',4:'d',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', 'A':'h'
        },
     4: {
        2:'h',3:'h',4:'d',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', 'A':'h'
        },
     3: {
        2:'h',3:'h',4:'h',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', 'A':'h'
        },
     2: {
        2:'h',3:'h',4:'h',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', 'A':'h'
        },
      A: {
            2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'sp',8:'sp',9:'sp',10:'sp', 'A':'sp'
       },
      },
     Split:{
       A: {
            2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'sp',8:'sp',9:'sp',10:'sp', A:'sp'
       },
     10: {
       2:'s',3:'s',4:'s',5:'s',6:'s',7:'s',8:'s',9:'s',10:'s', A:'s'
       },

   9: {
       2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'s',8:'sp',9:'sp',10:'s', A:'s'
       },
   8: {
       2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'sp',8:'sp',9:'sp',10:'sp', A:'sp'
       },
   7: {
       2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'h',8:'h',9:'h',10:'h', A:'h'
       },
   6: {
       2:'sp',3:'sp',4:'sp',5:'sp',6:'sp',7:'h',8:'h',9:'h',10:'h', A:'h'
       },
   5: {
       2:'d',3:'d',4:'d',5:'d',6:'d',7:'d',8:'d',9:'d',10:'h', A:'h'
       },
   4: {
       2:'h',3:'h',4:'h',5:'h',6:'h',7:'h',8:'h',9:'h',10:'h', A:'h'
       },
   3: {
       2:'h',3:'h',4:'sp',5:'sp',6:'sp',7:'h',8:'h',9:'h',10:'h', A:'h'
       },
   2: {
       2:'h',3:'h',4:'sp',5:'sp',6:'sp',7:'h',8:'h',9:'h',10:'h', A:'h'
       },
     },
     Hard:{
    16: {
        2:'s',3:'s',4:'s',5:'s',6:'s',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    15: {
        2:'s',3:'s',4:'s',5:'s',6:'s',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    14: {
        2:'s',3:'s',4:'s',5:'s',6:'s',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    13: {
        2:'s',3:'s',4:'s',5:'s',6:'s',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    12: {
        2:'h',3:'h',4:'s',5:'s',6:'s',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    11: {
        2:'d',3:'d',4:'d',5:'d',6:'d',7:'d',8:'d',9:'d',10:'d', A:'d'
        },
    10: {
        2:'d',3:'d',4:'d',5:'d',6:'d',7:'d',8:'d',9:'d',10:'h', A:'h'
        },
    9: {
        2:'h',3:'d',4:'d',5:'d',6:'d',7:'h',8:'h',9:'h',10:'h', A:'h'
        },
    8: {
        2:'h',3:'h',4:'h',5:'h',6:'h',7:'h',8:'h',9:'h',10:'h', A:'h'
        }
     }
   },
   'high cards with high-likelihood': {
     Soft:[
       {'p': 'A,2', 'd': '4', 'counts': ['d:202', 'h:163'], 'Prediction': 'd', 'Book': 'h'},
        {'p': 'A,3', 'd': '4', 'counts': ['d:244', 'h:154'], 'Prediction': 'd', 'Book': 'h'},
        {'p': 'A,6', 'd': '7', 'counts': ['d:20', 'h:-18'], 'Prediction': 'd', 'Book': 'h'},
        {'p': 'A,7', 'd': '2', 'counts': ['s:110', 'd:72'], 'Prediction': 's', 'Book': 'd'},
        {'p': 'A,7', 'd': 'Ace', 'counts': ['s:-195', 'h:-230'], 'Prediction': 's', 'Book': 'h'}
     ],
     Split:[
       {'p': '2,2', 'd': '3', 'counts': ['sp:70', 'h:15'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '2,2', 'd': '7', 'counts': ['sp:4', 'h:-85'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '3,3', 'd': '2', 'counts': ['sp:-28', 'h:-74'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '3,3', 'd': '3', 'counts': ['sp:177', 'h:25'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '3,3', 'd': '7', 'counts': ['sp:-141', 'h:-188'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '4,4', 'd': '4', 'counts': ['d:270', 'h:194'], 'Prediction': 'd', 'Book': 'h'},
        {'p': '4,4', 'd': '5', 'counts': ['sp:377', 'h:246'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '4,4', 'd': '6', 'counts': ['sp:781', 'h:410'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '5,5', 'd': '10', 'counts': ['d:70', 'h:48'], 'Prediction': 'd', 'Book': 'h'},
        {'p': '5,5', 'd': 'Ace', 'counts': ['d:248', 'h:97'], 'Prediction': 'd', 'Book': 'h'},
        {'p': '6,6', 'd': 'Ace', 'counts': ['s:-408', 'h:-427'], 'Prediction': 's', 'Book': 'h'},
        {'p': '7,7', 'd': '7', 'counts': ['sp:-52', 'h:-417'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '7,7', 'd': 'Ace', 'counts': ['s:-400', 'h:-529'], 'Prediction': 's', 'Book': 'h'},
        {'p': '8,8', 'd': '10', 'counts': ['s:-634', 'sp:-664'], 'Prediction': 's', 'Book': 'sp'},
        {'p': '8,8', 'd': 'Ace', 'counts': ['s:-414', 'sp:-541'], 'Prediction': 's', 'Book': 'sp'}
     ],
     Hard:[
       {'p': '9', 'd': '2', 'counts': ['d:206', 'h:144'], 'Prediction': 'd', 'Book': 'h'},
       {'p': '9', 'd': '7', 'counts': ['d:370', 'h:300'], 'Prediction': 'd', 'Book': 'h'},
       {'p': '9', 'd': '8', 'counts': ['d:346', 'h:195'], 'Prediction': 'd', 'Book': 'h'},
       {'p': '10', 'd': '10', 'counts': ['d:88', 'h:75'], 'Prediction': 'd', 'Book': 'h'},
       {'p': '10', 'd': 'Ace', 'counts': ['d:274', 'h:114'], 'Prediction': 'd', 'Book': 'h'},
       {'p': '12', 'd': '3', 'counts': ['s:-90', 'h:-180'], 'Prediction': 's', 'Book': 'h'},
       {'p': '13', 'd': 'Ace', 'counts': ['s:-436', 'h:-480'], 'Prediction': 's', 'Book': 'h'},
       {'p': '14', 'd': 'Ace', 'counts': ['s:-458', 'h:-558'], 'Prediction': 's', 'Book': 'h'},
       {'p': '15', 'd': '7', 'counts': ['s:-466', 'h:-504'], 'Prediction': 's', 'Book': 'h'},
       {'p': '15', 'd': '8', 'counts': ['s:-500', 'h:-568'], 'Prediction': 's', 'Book': 'h'},
       {'p': '15', 'd': '9', 'counts': ['s:-548', 'h:-633'], 'Prediction': 's', 'Book': 'h'},
       {'p': '15', 'd': '10', 'counts': ['s:-600', 'h:-638'], 'Prediction': 's', 'Book': 'h'},
       {'p': '15', 'd': 'Ace', 'counts': ['s:-418', 'h:-634'], 'Prediction': 's', 'Book': 'h'},
       {'p': '16', 'd': '7', 'counts': ['s:-474', 'h:-493'], 'Prediction': 's', 'Book': 'h'},
       {'p': '16', 'd': '8', 'counts': ['s:-524', 'h:-546'], 'Prediction': 's', 'Book': 'h'},
       {'p': '16', 'd': '9', 'counts': ['s:-564', 'h:-644'], 'Prediction': 's', 'Book': 'h'},
       {'p': '16', 'd': '10', 'counts': ['s:-616', 'h:-678'], 'Prediction': 's', 'Book': 'h'},
       {'p': '16', 'd': 'Ace', 'counts': ['s:-436', 'h:-635'], 'Prediction': 's', 'Book': 'h'}

       ]
   },
   'low cards with high-likelihood': {
     Soft:[

       {'p': 'A,2', 'd': '5', 'counts': ['h:59', 'd:-54'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,2', 'd': '6', 'counts': ['h:266', 'd:254'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,3', 'd': '5', 'counts': ['h:21', 'd:-162'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,4', 'd': '4', 'counts': ['h:38', 'd:-34'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,4', 'd': '5', 'counts': ['h:37', 'd:14'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,5', 'd': '4', 'counts': ['h:-26', 'd:-86'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,6', 'd': '4', 'counts': ['h:-23', 'd:-46'], 'Prediction': 'h', 'Book': 'd'},
        {'p': 'A,7', 'd': '3', 'counts': ['s:66', 'd:-32'], 'Prediction': 's', 'Book': 'd'},
        {'p': 'A,7', 'd': '4', 'counts': ['s:108', 'd:76'], 'Prediction': 's', 'Book': 'd'},
        {'p': 'A,7', 'd': '5', 'counts': ['s:121', 'd:78'], 'Prediction': 's', 'Book': 'd'}
    ],
     Split:[
       {'p': 'A,A', 'd': '3', 'counts': ['h:157', 'sp:132'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': 'A,A', 'd': '8', 'counts': ['h:230', 'sp:77'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': 'A,A', 'd': '9', 'counts': ['h:151', 'sp:47'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': 'A,A', 'd': '10', 'counts': ['h:100', 'sp:3'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': 'A,A', 'd': 'Ace', 'counts': ['h:104', 'sp:-249'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '2,2', 'd': '4', 'counts': ['h:-115', 'sp:-146'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '2,2', 'd': '5', 'counts': ['h:-66', 'sp:-183'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '2,2', 'd': '7', 'counts': ['sp:44', 'h:-39'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '3,3', 'd': '4', 'counts': ['h:-179', 'sp:-283'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '3,3', 'd': '5', 'counts': ['h:-136', 'sp:-217'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '3,3', 'd': '6', 'counts': ['h:70', 'sp:-24'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '3,3', 'd': '7', 'counts': ['sp:3', 'h:-74'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '4,4', 'd': '6', 'counts': ['sp:177', 'h:-6'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '5,5', 'd': '8', 'counts': ['h:104', 'd:28'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '5,5', 'd': '9', 'counts': ['h:87', 'd:-4'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '6,6', 'd': '2', 'counts': ['h:-230', 'sp:-526'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '6,6', 'd': '3', 'counts': ['h:-159', 'sp:-422'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '6,6', 'd': '4', 'counts': ['h:-108', 'sp:-478'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '6,6', 'd': '5', 'counts': ['h:-155', 'sp:-317'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '6,6', 'd': '6', 'counts': ['h:-59', 'sp:-95'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '7,7', 'd': '2', 'counts': ['h:-286', 'sp:-463'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '7,7', 'd': '3', 'counts': ['s:-288', 'sp:-510'], 'Prediction': 's', 'Book': 'sp'},
        {'p': '7,7', 'd': '4', 'counts': ['h:-282', 'sp:-340'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '7,7', 'd': '5', 'counts': ['h:-246', 'sp:-261'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '7,7', 'd': '7', 'counts': ['sp:-230', 'h:-243'], 'Prediction': 'sp', 'Book': 'h'},
        {'p': '8,8', 'd': '10', 'counts': ['h:-473', 'sp:-521'], 'Prediction': 'h', 'Book': 'sp'},
        {'p': '8,8', 'd': 'Ace', 'counts': ['h:-417', 'sp:-431'], 'Prediction': 'h', 'Book': 'sp'},
     ],
     Hard:[

       {'p': '9', 'd': '3', 'counts': ['h:-12', 'd:-154'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '9', 'd': '4', 'counts': ['h:-64', 'd:-256'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '9', 'd': '5', 'counts': ['h:42', 'd:-72'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '9', 'd': '6', 'counts': ['h:108', 'd:88'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '10', 'd': '7', 'counts': ['h:172', 'd:72'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '10', 'd': '8', 'counts': ['h:130', 'd:100'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '10', 'd': '9', 'counts': ['h:31', 'd:-142'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '11', 'd': '8', 'counts': ['h:170', 'd:56'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '11', 'd': '9', 'counts': ['h:90', 'd:10'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '11', 'd': '10', 'counts': ['h:91', 'd:62'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '11', 'd': 'Ace', 'counts': ['h:62', 'd:30'], 'Prediction': 'h', 'Book': 'd'},
        {'p': '12', 'd': '4', 'counts': ['h:-185', 's:-340'], 'Prediction': 'h', 'Book': 's'},
        {'p': '12', 'd': '5', 'counts': ['h:-127', 's:-222'], 'Prediction': 'h', 'Book': 's'},
        {'p': '12', 'd': '6', 'counts': ['h:-52', 's:-138'], 'Prediction': 'h', 'Book': 's'},
        {'p': '13', 'd': '2', 'counts': ['h:-201', 's:-372'], 'Prediction': 'h', 'Book': 's'},
        {'p': '13', 'd': '3', 'counts': ['h:-162', 's:-360'], 'Prediction': 'h', 'Book': 's'},
        {'p': '13', 'd': '4', 'counts': ['h:-196', 's:-302'], 'Prediction': 'h', 'Book': 's'},
        {'p': '13', 'd': '5', 'counts': ['h:-171', 's:-248'], 'Prediction': 'h', 'Book': 's'},
        {'p': '14', 'd': '2', 'counts': ['h:-262', 's:-344'], 'Prediction': 'h', 'Book': 's'},
        {'p': '14', 'd': '3', 'counts': ['h:-285', 's:-348'], 'Prediction': 'h', 'Book': 's'},
        {'p': '14', 'd': '4', 'counts': ['h:-255', 's:-330'], 'Prediction': 'h', 'Book': 's'},
        {'p': '14', 'd': '5', 'counts': ['h:-261', 's:-304'], 'Prediction': 'h', 'Book': 's'},
        {'p': '14', 'd': '6', 'counts': ['h:-168', 's:-184'], 'Prediction': 'h', 'Book': 's'},
        {'p': '15', 'd': 'Ace', 'counts': ['s:-402', 'h:-433'], 'Prediction': 's', 'Book': 'h'}

       ]
   }
};
const transform = {
	s: 'Stand',
	d: 'Double-down',
	h: 'Hit',
	sp: 'Split',

};

const getRule=(rule, dcard, pcard) => {

	const _rule=rules['Book'][rule];
	if (pcard in _rule) {
		//console.log(`inside dcard:${dcard}, pcard:${pcard}, rule:${rule}`);
           return transform[_rule[pcard][dcard]];
	}
		//console.log(`inside dcard:${dcard}, pcard:${pcard}, rule:${rule}`);
	return (pcard>16)? 'Stand': 'Hit';
};

export const book_rule_get = (ddm, pid) => {
	const dfaceCard=ddm.dealerFacecard();
	const playerHand=ddm.hands[pid];
	const _2cards=playerHand.length()==2;
	const sum=playerHand.sum();
	if (_2cards) {
		// console.log(`isSoft:${ddm.isSoft(pid)}, isSplit(pid):${ddm.isSplit(pid)}`);
                // playerHand.display();
		if (ddm.isSoft(pid)) {
			const index=playerHand.valuePerCard(0)==1? 1: 0;
		       	return getRule('Soft', dfaceCard, playerHand.valuePerCard(index));
		}
		if (ddm.isSplit(pid)) return getRule('Split', dfaceCard, playerHand.valuePerCard(1));
		return getRule('Hard', dfaceCard, sum);
	}
	return getRule('Hard', dfaceCard, sum);
};
