

        <div style="position:relative; margin-top:30px" >
          <div class="flex-column" *ngIf="showCards">

              <div class="flex-row" [@blist]="images['low'].length" *ngIf="showCards['low']">
                     <img  src="{{image}}"   *ngFor="let image of images['low']" class="cards-spacing">
              </div>
              <div class="flex-row" [@blist]="images['high'].length" *ngIf="showCards['high']">
                     <img  src="{{image}}"   *ngFor="let image of images['high']" class="cards-spacing">
              </div>
              <div class="flex-row" [@blist]="images['ignored'].length" *ngIf="showCards['ignored']">
                     <img  src="{{image}}"   *ngFor="let image of images['ignored']" class="cards-spacing">
              </div>
          </div>
          <div class="flex-row" *ngIf="dropLabel" [@sdrop]="numbers.length" style="position:absolute; top:30px;"  >
                 <button mat-mini-fab [color]="'primary'"  style="position:absolute; left:{{i*cards['width']+hwidth}}px" *ngFor="let i of numbers"> L</button>
          </div>
          <div class="flex-row" *ngIf="dropLabel" [@sdrop]="numbers.length" style="position:absolute; top:{{30+cards['height']}}px;"  >
                 <button mat-mini-fab [color]="'accent'"  style="position:absolute;left:{{i*cards['width']+hwidth}}px" *ngFor="let i of numbers"> H</button>
          </div>
          <div class="flex-row" *ngIf="dropLabel" [@sdrop]="inumbers.length" style="position:absolute;  top:{{30+2*cards['height']}}px"  >
                 <button mat-mini-fab [color]="'warn'"  style="position:absolute; left:{{i*cards['width']+hwidth}}px" *ngFor="let i of inumbers"> I</button>
          </div>

          <!-- <button mat-flat-button   (click)="clicked()" style="position: absolute; top:400px; left:250px"
                   [color]="'warn'"  class="scale-p9"> <mat-icon > skip_next </mat-icon></button> -->

        </div>
