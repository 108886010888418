import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars,getMethodDesc } from '../../services/global.service';

import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction, GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';

enum SettingActiveEnum {
	active,
	idle
};

@Component({
  selector: 'anms-custom-top-mobile-train',
  templateUrl: './concept.top.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.manim,
    CommonListAnimations.flip
  ]
})

export class ConceptTopComponent implements OnDestroy, OnInit  {
@ViewChild('slickModal') slickModal: SlickCarouselComponent;
  routes={};
  header='concept';
  buttons=[];
  opacity='opacity-0';
  subscriptions=[];
	mouseClass={
 	 cards: 'enter',
 	 'Running-count':'leave',
	 'Audio':'leave',
 	 count:'leave',

  };
  chosen="cards";
  mouse(key) {
 	 this.mouseClass[key]='enter';
 	 this.mouseClass[this.chosen]='leave';
 	 this.chosen=key;
  }
 settingState:SettingActiveEnum=SettingActiveEnum.idle;
 settingWasActive=false;


  constructor(public store: Store<any>, private routeService:MobileRouterService) {
      // super(store,['Concept', 'Count', 'Classification', 'Rules', 'Betting', 'Playing']);

    }
    slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': true,
 };

 gvars=globalVars;
 audioEnable = false;

 mssapPlaylist= [
 {
	 title: 'Audio Title',
	 link: 'assets/audio/jackcount/countingconcept.m4a',
	 artist: 'Jack Count',
	 duration: 49000
 }
];

  istate() {
    GenericTopTopAction(this.store, [getMethodDesc(''), 'Cards makeup', 'Counting overview'], 'tigger');
    BottomAppleModify(this.store, {switch:'apple',
                     disable:['forward'], enable:['menu', 'backward', 'lastIcon'], lastIcon:'settings'});

  }
  ngOnInit() :void{

	 switch(globalVars['method']) {
		 case 'single': {
			 this.routes = {
          'cards': 'mobile/card-classification',
          'Up-Running-count':'mobile/adding-strategy',
        };
		    break;
		 }
		  case 'advance':
			case 'jack': {
				this.routes = {
					 'cards': 'mobile/card-classification',
					 'count':'mobile/adding-strategy',
					 'Audio':'',
				 };
					break;
			 }
		 default: {
			 this.routes = {
					'cards': 'mobile/card-classification',
					'count':'mobile/adding-strategy'
				};
				break;
		 }
	 }

     this.istate();

     setTimeout(() => {
       this.opacity='opacity-1';
      this.buttons=Object.keys(this.routes);
     }, 500);


     this.subscriptions.push(
         this.store
           .select('mobileTopBottom')
           .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
           .subscribe((data: KeyPressedDm) => {
	        if (data.key=="settings") {
								globalVars['setting'] = {
								 func: GenericSlideConceptTrain,
								 route:'mobile/concept-top'
							 };
								this.routeService.navigate('mobile/setting-train');
								return;
	        }
	        if (data.key=="backward") {
                 this.routeService.navigate('mobile/train');
		            return;
	         }

           })
       );
      }
  clicked(button:any):void {
		if (button=='Audio') {
			this.audioEnable = this.audioEnable? false: true;
			return;
		}
    this.routeService.navigate(this.routes[button]);
    // this.changeColor(button);
  }

   ngOnDestroy() :void{
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
    MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

}
