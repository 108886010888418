import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import {  MobileRouterService, globalVars, imageDims  } from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
@Component({
  selector: 'anms-mobile-card-classification',
  templateUrl: './card.classification.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.manim,
    CommonListAnimations.flip,
    CommonListAnimations.cards
  ]
})
export class MobileCardClassificationComponent implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  // images= [];
  subscriptions=[];
  savedIndex=0;
  topEnabled=true;
  images=[];
  min_height=0;
  cards= {
      'High cards':[
        '/assets/cards/10C',
        '/assets/cards/JD',
        '/assets/cards/QH',
        '/assets/cards/KS',
        '/assets/cards/AC',
      ],
      'Low cards': [
        '/assets/cards/2C',
        '/assets/cards/3D',
        '/assets/cards/4H',
        '/assets/cards/5S',
        '/assets/cards/6C',
      ],
      'Ignored cards': [
        '/assets/cards/7C',
        '/assets/cards/8D',
        '/assets/cards/9H',
      ]
  };

  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
    arrows: false,
   "infinite": false,
   // 'showArrow': false,
   'swipeToSlide': true,
 };

  disabled = {
    next:false,
    previous: true
  };
  gvars=globalVars;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
      }


  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }
   index2keys(index) {
     switch(index) {
       case 0: return 'High cards';
       case 1: return 'Low cards';
       default: return 'Ignored cards';

     }
   }
   setImages(key) {
     this.images=[...this.cards[key]];
   }

   // upDown(key) {
   //   if (key=='up') {
   //     this.up();
   //   } else {
   //     this.down();
   //   }
   // }
   afterChange(event:any):void {
    // console.log( event);
    // restore everything to what globalvars is pointing to.
    const page=event.currentSlide;
    this.setImages(this.index2keys(page));
    switch(page) {
      case 0 : { // method
         // this.stage='High cards';
         GenericTopTopAction(this.store, ['Five high cards', 'Five low cards', 'Blanced'], 'tigger');
         this.disabled.next=false;
         break;
      } case 1: { //decks
        GenericTopTopAction(this.store, ['Five high cards', 'Five low cards', 'Blanced'], 'tigger');

        this.disabled.previous=false;
        this.disabled.next=false;

         // this.stage='Low cards';
        break;
      } case 2: { // cards-out
        GenericTopTopAction(this.store, ['To keep card counting simple', 'Ignore these 3 cards'], 'tigger');
        this.disabled.next=true;
        break;
      }

    }
  }

   clicked(key) {
     const length = this.images.length;
     this.images=[];
     setTimeout(() => {
       switch(key) {
         case 'forward': {
           this.slickModal.slickNext();
           break;
         }
         case 'backward': {
           this.slickModal.slickPrev()
           break;
         }
         default: this.router.navigate('mobile/concept-top');
       }
     }, length*600);

   }

   ngOnInit() :void{
     GenericTopTopAction(this.store, ['High cards classification', 'Note: cards are balanced'], 'tigger');
     BottomAppleModify(this.store, { enable:['menu', 'backward', 'forward']});
     imageDims();

     const image=globalVars['image'];
     this.min_height=image.card.height+70;

      for (const key in this.cards) {
        this.cards[key] = this.cards[key].map(img=> `${img}${image.dims}.png`);
      }
    this.subscriptions.push(
        this.store
          .select('mobileTopBottom')
          .pipe(filter(data => data.typeof=== 'KeyPressedDm' ))
          .subscribe((data: KeyPressedDm) => {
             this.topEnabled=false;
             if (data.target == 'child') {
                const page = data.key == 'forward'? 'mobile/adding-strategy': 'mobile/concept-top';
                setTimeout(() => {
                  this.router.navigate(page);
                }, 100);
              }
          })
      );
      this.subscriptions.push(
            this.store
              .select('genericReducer')
              .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
              .subscribe((data: GenericDataUnit) => {
                if (data.action==GenericDataUnitEnum.die) {
                  this.topEnabled=false;
                  return;
                }
              }
            )
       );

      this.setImages(this.index2keys(0));
    }
  }
