 <div  style="margin-top:10px;margin-left:{{gvars['center-width']}}px;" >

     <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  >
       <div ngxSlickItem class="slide">
         <mat-card class="example-card">
              <mat-card-actions>

                 <div class='flex-column'>
                   <ng-container *ngFor="let button of buttons">
                       <button mat-raised-button
                              class="{{mouseClass[button]}} margin-top-10px curvey-border" (click)="clicked(button)">{{button}}</button>
                     </ng-container>


              </div>
            </mat-card-actions>
        </mat-card>
       </div>

     </ngx-slick-carousel>
 </div>
