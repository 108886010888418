import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { isJack,MobileRouterService, globalVars,getMethodDesc,imageDims} from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { GenericTopTopAction,GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import { nFlash} from '../../services/common.service';
import {MobileDrawCardAction,MobileDrawCardResetAction} from '../store/cards.action.reducer';

enum State {
    idle,
    wait_for_buttons_pressed,
    button_pressed
};

enum Action {
  draw,
  buttonsReset,
  none,
  move
};

@Component({
  selector: 'anms-mobile-adding-strategy',
  templateUrl: './adding.strategy.component.html',
  styleUrls: ['../styles.scss','./adding.strategy.component.scss'],
  animations: [
        CommonListAnimations.flash
  ]
})
export class MobileAddingStrategyComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscriptions=[];
  vars=globalVars;
  disabled={forward:false, backward:true, audio: false, dbackward:false};
  method=globalVars['method'];
  margin={top:10};
	settingCurrentlyActive=false;
  cachedDisabledKeys=[];
  flashes = {};

  topMargin=0;

  action = Action.none;
  actionMethod:any;

  statements= {

    mit:[
          [// one set
            "Count starts at '0'",
            "add 1 for each low-card",
            "subtract 1 for each high-card",
            // "press next->"
           ],
           [
             "Press any of the below buttons to see some example of low or high counts"
           ],
           [
             "Press any of the below buttons to see some example of mix cards counts"
           ]

         ],
    advance:[
           [
             "Note, the count starts at '0'",
             "For every low or high card add 1 to the count",
             "Count containing low or high cards only",
             " Label the count as 'L' for low cards",
             " Label the count as 'H' for high cards",
             "press next for see examples"
           ],
           [
             "Press any of the below buttons to see example of low or high counts"
           ],
           [
             "Counts containing mixture of low and high cards:",
             "Put two counts side by side",
             "High count first, low count second",
             "For example 2 high cards followd by 4 low cards",
             "Gives the count of '2 4'",
             "It is pronounced 'two four' and not 'twentyfour'",
             "press next for some examples"

           ],

           [
             "Press any of the below buttons to see example of mix cards counts"
           ],
           [
             "Calculate the running count:",
             "Objective here is to end up with either low or high card count",
             "Subtract these two counts from one another",
             "For example:",
             "if the count is '2 4', running count is '2L'",
             "if the count is '4 2', running count is '2H'",
             "press next for some examples"
           ],

           [
             "Press any of the below buttons to see example of running counts"
           ],
       ]


  }
  audioEnable=false;
  slidesCount=0;
  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    arrows:false,
    "infinite": false,
     'swipeToSlide': true,

  };
  settingWasActive=false;

  mssapPlaylist= [
  {
    title: 'Audio Title',
    link: 'assets/audio/First-sample.m4a',
    artist: 'Jack Count',
    duration: 90000
  }
];
audioCount = {
  ten:0,
  mit:0,
  advance:0
};
clearCard = false;
ncards = 0;
mit=false;
mix = {
  buttons:['+4', '-4', '-6','+6', '2 4', '4 2', '3 5', '5 3'],
  count:{
    '5 3':{cards:8, count: (5<<4) | 3},
    '3 5': {cards:8, count:(3<<4) | 5},
    '4 2': {cards:6, count:(4<<4) | 2},
    '2 4': {cards:6, count:(2<<4) | 4},
    '-6': {cards:9, count:(9<<4) | 3},
    '+6': {cards:8, count:(3<<4) | 9},
    '-4': {cards:8, count:(6<<4) | 2},
    '+4': {cards:8, count:(2<<4) | 6}
  }
};
buttonsGroup = {
  mit: [
        [{key:'+3', color:'primary'}, {key:'-3', color:'warn'},  {key:'+7', color:'primary'}, {key:'-7', color:'warn'}],
        [{key:'+4', color:'primary'}, {key:'-4', color:'warn'},  {key:'+6', color:'primary'}, {key:'-6', color:'warn'}]
      ],
 advance: [
   [{key:'3L', color:'primary'}, {key:'3H', color:'warn'},  {key:'7L', color:'primary'}, {key:'7H', color:'warn'}],
   [{key:'2 4', color:'primary'}, {key:'4 2', color:'warn'},  {key:'3 5', color:'primary'}, {key:'5 3', color:'warn'}]
 ]
};

buttons = [];
flashed=[];
page = 0;
activeButtons = [];


marginTop=0;
cardsOnDisplay = false;
state = State.idle;
buttonWidth=0;
disableButtons(value) {
  for(const key in this.disabled) {
    if (value && this.disabled[key]) {
      this.cachedDisabledKeys.push(key);
    }
    this.disabled[key] = value;
  }
  if (!value) {
    for(const key of this.cachedDisabledKeys) {
      this.disabled[key] = true;
    }
    this.cachedDisabledKeys =[];
  }
}

getButtons(page) {
  if (globalVars['method'] == 'mit') {
    this.buttons = this.buttonsGroup.mit[page];
    return;
  }
  this.buttons = this.buttonsGroup.advance[page];
}

getActiveButtons() {
  for (const elm of this.buttons) this.activeButtons.push(elm.key);
}


flashAll() {
  this.buttons.map((elm)=> {
       if(!this.flashed.includes(elm.key)) this.flashes[elm.key].flash();
     });

  this.flashed = [...this.activeButtons];
}

unflashAll() {
  this.flashed.map((elm)=>this.flashes[elm].unflash());
  this.flashed=[];
}

unflashExcept(key) {
  this.flashed.map((elm)=> {
    if (key!=elm) this.flashes[elm].unflash()
  });
  this.flashed = [key];
}

afterChange(event:any):void {

    if (this.flashed.length) {
      this.unflashAll();
    }

    if (!event.currentSlide) {
      this.disabled.forward=false;
      this.disabled.backward=true;
      return;
    }
    if ((this.slidesCount-1)==event.currentSlide) {
      this.disabled.forward=true;
      this.disabled.backward=false;
      return;
    }
    this.disabled.forward=false;
    this.disabled.backward=false;
}



setPlayList() {
  switch(this.method) {
    case 'mit': {
      switch(this.audioCount.advance) {
        case 0: {
          this.mssapPlaylist[0].title = "Classical high-low intro";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-1.m4a";
          this.mssapPlaylist[0].duration = 30000;
          return;
        }
        case 1: {
          this.mssapPlaylist[0].title = "Classical high-low first example";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-2.m4a";
          this.mssapPlaylist[0].duration = 43000;
          return;
        }
        case 2: {
          this.mssapPlaylist[0].title = "Classical high-low second example";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-3.m4a";
          this.mssapPlaylist[0].duration = 100000;
          return;
        }

        default : return
      }
    }
    default: {
      switch(this.audioCount.advance) {
        case 0: {
          this.mssapPlaylist[0].title = "Revised high-low intro";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-1.m4a";
          this.mssapPlaylist[0].duration = 90000;
          return;
        }
        case 1: {
          this.mssapPlaylist[0].title = "Revised high-low 2nd example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-2.m4a";
          this.mssapPlaylist[0].duration = 42000;
          return;
        }
        case 2: {
          this.mssapPlaylist[0].title = "Revised high-low 3rd example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-3.m4a";
          this.mssapPlaylist[0].duration = 38000;
          return ;
        }
        case 3: {
          this.mssapPlaylist[0].title = "Revised high-low fourth example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-4.m4a";
          this.mssapPlaylist[0].duration = 37000;
          return ;
        }

        default: return ;
      }
    }
  }
}

constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);

    }

  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
    MobileSendKeyRoute(this.store,[{key:"reset"}]);
    if (this.flashed.length) {
      this.unflashAll();
    }
    globalVars['modifyDeck'] = {count:0, type: ''};
    if (this.cardsOnDisplay) {
      MobileDrawCardResetAction(this.store);
    }
    globalVars['netcount']=false;
    globalVars['cardsOut'] = 3;
   }

   drawCard() {
     if (this.cardsOnDisplay) {
       this.action=Action.draw;
       MobileDrawCardResetAction(this.store);
       return;
     }
     this.action = Action.none;
     globalVars['netcount'] = this.page==5;
     MobileDrawCardAction(this.store);
   }

   getIndex() {
     // let index = this.mit? this.page-1: this.page-1;
     if ([3,5].includes(this.page)) return 1;
     return this.page-1;

   }

   movePage(key) {
     if (key=='forward') {
       this.slickModal.slickNext();
       return;
     }
     this.slickModal.slickPrev();
   }

   buttonSkipped(key) {
     if (this.cardsOnDisplay) {
       MobileDrawCardResetAction(this.store);
       this.action = Action.buttonsReset;
       this.actionMethod=()=> {
         this.buttons=[];
         this.movePage(key);
       }
     }
     this.state=State.idle;
   }

   processButton(key) {
     this.state = State.button_pressed;
     this.unflashExcept(key);
     let cards;
     let type = this.mix.buttons.includes(key)? 'mix': (key.includes('+') || key.includes('L'))? 'low': 'high';
     if (type =='mix') {
       const e = this.mix.count[key];
       cards = e.count;
     } else {
       const fchar = key[0];
       cards = ['+','-'].includes(fchar)? +key[1]: +fchar;
     }
     globalVars['modifyDeck'] = {count:cards, type: type};
     this.ncards = cards;
     globalVars['cardsOut'] = cards;
     this.drawCard();
     this.disableButtons(true);
     return;
   }


   setupButtons(key) {
     this.state = State.wait_for_buttons_pressed;
       MobileDrawCardResetAction(this.store);
       this.unflashAll();
       this.action = Action.move;
       this.actionMethod=()=> {
         this.getNewButtons();
         this.movePage(key);

       }
   }

   clicked(key) {

     if (key=='audio') {
       this.audioEnable = this.audioEnable? false: true;
       return;
     }
     if (key=='dbackward') {
       if (this.cardsOnDisplay) {
         MobileDrawCardResetAction(this.store);
         this.unflashAll();
         this.action = Action.move;
         this.actionMethod=()=> {
          this.router.navigate('mobile/concept-top');
         }
         return;
       }
       this.router.navigate('mobile/concept-top');
       return;
     }
    const buttonPressed = this.activeButtons.includes(key);
    if (!buttonPressed) {
      this.page += key == 'forward'? 1: -1;
      this.audioCount.advance += key =='forward'? 1: -1;
      this.setPlayList();
      this.audioEnable = false;
      this.setTopMessage();
    }
    switch(this.state) {
      case State.idle: {
        const enableButtons = this.mit? this.page>=1 : [1,3,5].includes(this.page);
        //show buttons
        if (enableButtons) {
          this.state = State.wait_for_buttons_pressed;
          if (this.cardsOnDisplay) {
            this.setupButtons(key);
            return;
          }
          this.getNewButtons();
        }
        this.movePage(key);
        return;
      }
      case State.wait_for_buttons_pressed: {
        if (buttonPressed) {
         this.processButton(key);
       } else  if(this.method == 'mit') {
         if (key=='forward') {
           this.setupButtons(key);
           this.getNewButtons(1);
           return;
         }
         if (this.page == 0) {
           this.buttonSkipped(key)
         } else {
           this.setupButtons(key);
           this.getNewButtons(0);
         }


       }  else this.buttonSkipped(key);
          return;
      }
      case State.button_pressed : {
        if (buttonPressed) {
         this.processButton(key);
       } else  if(this.method == 'mit') {
           if (key=='forward') {
             this.setupButtons(key);
           } else {
             this.setupButtons(key);
             this.getNewButtons(0);
           }
       }  else this.buttonSkipped(key);
        return;
      }
    }
   }

   getNewButtons(index=0) {
     this.getButtons(index? index: this.getIndex());
      this.getActiveButtons();
      this.flashAll();
   }

   eventHandler(e:any):void {
     switch(e) {
       case 'removed': {
          this.cardsOnDisplay = false;
         switch(this.action) {
           case Action.draw: {
              MobileDrawCardAction(this.store);
              break;
           }
           case Action.buttonsReset: {
             this.action = Action.none;
             this.actionMethod();
             break;
           }
           case Action.move: {
             this.actionMethod();
             break;
           }
         }
         this.action = Action.none;
         break;
       } case 'drawn': {
          this.flashAll();
          this.disableButtons(false);
          this.cardsOnDisplay = true;
         break;
       } default: break;
     }
   }

  setTopMessage() {
    if (this.method=='mit') {
      switch(this.page) {
        case 0: {
          GenericTopTopAction(this.store, [getMethodDesc(''), "Add 1 for any low and -1 for any high card"], 'tigger');
          return;
        }
        case 2:
        case 1: {
          GenericTopTopAction(this.store, [getMethodDesc(''), "Try all 4 buttons"], 'tigger');
          return;
        }
        default: return;
      }
      return;
    }
    switch(this.page) {
      case 0: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "For each low or high cards add 1 to the count",
                      "Attach label 'L' for the low count",
                      "Attach label 'H' for the high count"], 'tigger');
        return;
      }
      case 1: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "Examples of low or high count",
                        "Try all 4 buttons"], 'tigger');
        return;
      }
      case 2: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "High and low card count",
                        "Maintain 2 seperate count",
                        "High card count followd by low card count"],
                         'tigger');
        return;
      }
      case 3: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Examples of low and high count",
                  "Each card only effects one count"  ], 'tigger');
        return;
      }
      case 4: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Running count calculation",
                  "Do this at the end of the hand not during"], 'tigger');
        return;
      }
      case 5: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Examples of running-count",
                  "Press the button and pay attention to the last card with the red circle"
                  ], 'tigger');
                  break;
      }
    }

  }

   istate() {
        let note='';
        let difficulty;
        this.method=globalVars['method'];
        const method=getMethodDesc('');
        this.margin.top = 30;
        if (isJack()) {
            this.slidesCount=this.statements.advance.length;
            // this.margin.top=20;
        } else if (globalVars['method']=='mit') {
          this.slidesCount=this.statements.mit.length;
          // this.margin.top=25;
        }

        BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],lastIcon:'settings'});
   }

   setTopMargin() {
     this.marginTop = this.buttons.length? 100: globalVars['image'].card.height*3 + 100;
   }

   buildFlash() {
     const buttonGroup = globalVars['method'] == 'mit'? this.buttonsGroup.mit : this.buttonsGroup.advance;
     for (const list of buttonGroup) {
       for (const elm of list) {
         this.flashes[elm.key] = new nFlash;
       }
     }
   }

   ngOnInit() :void{
        globalVars['vpath']="concept/adding-count";
      	imageDims();
        this.buildFlash();
        this.istate();
        this.setTopMessage();
        this.setPlayList();
        this.setTopMargin();
        const image = globalVars['image'];
        // this.buttonWidth = globalVars['mobileDevice']? 10: image.card.width;
        this.buttonWidth = globalVars['mobileDevice']? 10: 30;
        //
        this.mit = globalVars['method'] == 'mit';
        this.subscriptions.push(
            this.store
              .select('mobileTopBottom')
              .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
              .subscribe((data: KeyPressedDm) => {
                  switch(data.key) {
                    case 'home' : {
                        setTimeout(() => {
                          this.router.navigate('mobile/home');
                        }, 200);
                      break;
                    }
                    case 'backward' : {
                           if(this.settingCurrentlyActive) { return; }
                           let timeout=200;
                           if (this.cardsOnDisplay ) {
                             MobileDrawCardResetAction(this.store);
                             timeout=1400;
                           }
                           setTimeout(() => {
                             this.router.navigate('mobile/train');
                           }, timeout);
                         break;
                    } case 'settings': {
                          // this.istate();
                          let timeout=0;
                          if (this.cardsOnDisplay) {
                            MobileDrawCardResetAction(this.store);
                            timeout=1400;
                          }
                           globalVars['setting'] = {
                            func: GenericSlideConceptTrain,
                            route:'mobile/adding-strategy'
                          };
                          setTimeout(() => {
                            this.router.navigate('mobile/setting-train');
                          }, timeout);
                           break;
                       }
                  }
                           })
          );
         this.subscriptions.push(
               this.store
                 .select('genericReducer')
                 .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                 .subscribe((data: GenericDataUnit) => {
                   if (data.action==GenericDataUnitEnum.die) {
                     return;
                   }
                 }
               )
          );




      }

onEnded(e) {

}



  }
