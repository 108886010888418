import { Component, OnInit} from '@angular/core';
import {globalVars} from '../../services/global.service';
// import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import {trigger,state, style,transition,animate,query,stagger,keyframes} from '@angular/animations';
import { Store } from '@ngrx/store';

@Component({
  selector: 'anms-mobile-fair',
  templateUrl: './fair.component.html',
  animations: [
    // CommonListAnimations.zindex,
    // CommonListAnimations.sdrop
     trigger('zindex', [
      transition("*=>*", [
               // query(':enter', style({ opacity: 0 }), { optional: true }),
                 query(':enter', stagger('100ms', [
                   animate('1s ease-in', keyframes([
                     style({ opacity:  1, translate: 'z-index:0', offset: 0 }),
                     style({ opacity:  1, translate: 'z-index:50', offset: 0.7 }),
                     style({ opacity:  1, translate: 'z-index:0', offset: 1 }),
                   ]))]), { optional:  true }),

    ])
  ])

  ]
})
export class FairComponent {
 oddsImages = {
   first: {dealer:['/assets/cards/10H_100x120.png', '/assets/cards/Gray_back_100x120.png'], player:['/assets/cards/10C_100x120.png', '/assets/cards/5C_100x120.png']},
   second: {dealer:['/assets/cards/10S_100x120.png', '/assets/cards/6S_100x120.png'], player:['/assets/cards/10D_100x120.png', '/assets/cards/5D_100x120.png']}

 };
 // bridgeImage = 'assets/misc/bridge_125x125.jpg';
 bridgeImage = 'assets/misc/new_bridge_200x200.jpg';
 animList=[];
 showMsg=false;
fheader=['Blackjack deep dive', '25 vs 50 percent odds', 'Make it a fair game'];
 fairness={
   first:['Unfair game', '25% odds'],
   second:['Fair game',   '50% odds']
 };
 gvars=globalVars;
 margin_top = 0;
  constructor(private store:Store<any>) {}
  ngOnInit() {
    this.margin_top = this.gvars['desktop']? 50: 0;
    GenericTopTopAction(this.store, this.fheader);
    this.animList.push(0);
    setTimeout(() => {
      this.showMsg=true;
    }, 2000);
  }
}
