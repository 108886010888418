
  <ng-container *ngIf="mode=='flat' else stacked">
        <div *ngIf="label.length" class="{{labelClass}}" style="margin-left:15px" [@flash]="nflash.status"> {{label}} </div>
        <div   style="position: relative;min-height:{{cheight+25}}px; margin-left:{{baseMarginLeft}px;">
                 <div class="flex">
                     <img src="{{card}}"  [@list]  class="cards-spacing" *ngFor="let card of cards" style="margin-top:5px">
                 </div>
                 <div    class="numbers-nav" *ngIf="sbadge.enabled"
                         style="position: absolute; top:30px; left:{{dealerBadgeMargin+30}}px">{{sbadge.value}}
                  </div>
                  <span [@blist]="badges.length">
                       <span  *ngFor="let badge of badges;let i=index"  class="card-count-dressing"
                               style="position: absolute; top:{{offset.height}}px; left:{{leftMargin(i)}}px;">{{badge}}
                        </span>
                   </span>
                   <span class="numbers-nav-reduced" *ngIf="reduced.enabled"
                        style="position: absolute; top:{{offset.height*2}}px; left:{{reduced.left}}px;"
                   > {{reduced.badge}}
                   </span>
        </div>

  </ng-container>

  <ng-template #stacked>

      <div *ngIf="label.length" class="{{labelClass}}" [@flash]="nflash.status"> {{label}} </div>
    <div class="cards" style="position: relative; min-height:{{cheight}}px; min-width:{{2*image.width}}">
         <img src="{{card}}"   style="margin-left:{{marginLeft}}px" [@list] class="{{cardsMargin}}" *ngFor="let card of cards.slice(0,2)">
         <img src={{cards[2]}}  *ngIf="2<cards.length<4"   style="position: absolute; top:{{ilocation['2']['top']}}px; left:{{ilocation['2']['left']}}px;">
         <img src={{cards[3]}}  *ngIf="3<cards.length<5"   style="position: absolute; top:{{ilocation['3']['top']}}px; left:{{ilocation['3']['left']}}px;">
         <img src={{cards[4]}}  *ngIf="4<cards.length<6"   style="position: absolute; top:{{ilocation['4']['top']}}px; left:{{ilocation['4']['left']}}px;">
         <img src={{cards[5]}}  *ngIf="5<cards.length<7"   style="position: absolute; top:{{ilocation['5']['top']}}px; left:{{ilocation['5']['left']}}px;">
         <img src={{cards[6]}}  *ngIf="6<cards.length<8"   style="position: absolute; top:{{ilocation['6']['top']}}px; left:{{ilocation['6']['left']}}px;">
         <img src={{cards[7]}}  *ngIf="7<cards.length<9"   style="position: absolute; top:{{ilocation['7']['top']}}px; left:{{ilocation['7']['left']}}px;">
         <img src={{cards[8]}}  *ngIf="8<cards.length<10"   style="position: absolute; top:{{ilocation['8']['top']}}px; left:{{ilocation['8']['left']}}px;">
         <img src={{cards[9]}}  *ngIf="9<cards.length<11"   style="position: absolute; top:{{ilocation['9']['top']}}px; left:{{ilocation['9']['left']}}px;">
         <img src={{cards[10]}}  *ngIf="10<cards.length<12"   style="position: absolute; top:{{ilocation['10']['top']}}px; left:{{ilocation['10']['left']}}px;">
         <img src={{cards[11]}}  *ngIf="11<cards.length<13"   style="position: absolute; top:{{ilocation['11']['top']}}px; left:{{ilocation['11']['left']}}px;">


         <span    class="numbers-nav" *ngIf="sbadge.enabled"
                 style="position: absolute; top:30px; left:30px">{{sbadge.value}}
          </span>

    </div>
  </ng-template>
  <!-- style="position: absolute; top:{{offset.height}}px; left:{{leftMargin(i)}}px;width:{{offset.width}}px; height:25px">{{badge}} -->
  <!-- style="position: absolute; top:{{reduced.top}}px; left:{{reduced.left+5}}px;" -->


<!-- <span  *ngFor="let badge of badges;let i=index"  class="{{cardCountClass}}" -->
