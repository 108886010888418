  <div class="top-top-flex-row" style="margin-top:10px;margin-left:{{gvars['center-width']}}px" *ngIf="disable==false">
      <img src="/assets/misc/{{content.image}}.png" >
      <div class="flex-column" style="margin-left:7px">
            <p *ngFor="let msg of content.message; let i=index"> {{msg}} </p>
            <div *ngIf="flags.cardCount" class="flex-column">
              <underline font="{{font}}" color="black" label="Book-suggestion:" value="{{rule}}"> </underline>
              <p style="margin-top:20px" [@flash]="flash.status"> Card-count: {{gvars['cardCount']}} </p>
            </div>
      </div>
</div>
