import { Component, ChangeDetectionStrategy, OnInit,OnDestroy, Output, Input, EventEmitter} from '@angular/core';
import { DealerService} from '../../../services/deck.service';
//import { MatMenuModule} from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { print } from '../../../services/common.service';
import { globalVars,getImage } from '../../../services/global.service';
import { Subscription } from 'rxjs';
import { PlayCardsDataUnit, MobileCountCardDataUnit} from '../../store/cards.model';
import {MobileInsertCardAction,MobileInsertBadgeAction, MobileCardsPeerErase,MobileCardsMode, MobileDropCardAction, MobileCardsBackgroundOff} from '../../store/cards.action.reducer';
import { SendPlayerCardDrawnAction } from '../../../store/switchcards.action.reducer';

import {MobileSendKeyRoute,MobileSendUnFlash} from '../../store/bottom.action.reducer';

// import {MobileCardsComponent } from './cards.component';
import {  DROP, REPLACE}  from '../../../store/switchcards.model';

@Component({
  selector   : 'mobile-player-cards-display',
  templateUrl: './playing.cards.component.html',
  styleUrls: [],
  // host: {
  //   '(@clist.done)': 'Clist($event)',
  // },
//  changeDetection: ChangeDetectionStrategy.OnPush,
  })

// export class MobilePlayerCardsComponent extends MobileCardsComponent implements OnInit, OnDestroy {
export class MobilePlayerCardsComponent implements OnInit, OnDestroy {
  cardsSend=0;
  count=0;
  subscriptions=[];
  @Output() event = new EventEmitter(); //currently only one event type raised when all the cards are dropped
  @Input() id =0;
  @Input() label='';
  @Input() mode='flat';


  constructor(private dealerService: DealerService,    private store: Store<any>) {
    // super(dealerService, store);
  }
/////////////////////////////////////
send(cards:any):void {
  const list:any=[];
  for (const card of cards) {
    list.push( {card: getImage(card), badge:false, value:this.id})
  }
  MobileInsertCardAction(this.store, list,this.id); // use panel 0
  this.cardsSend=cards.length;

}
//////////////////////
  ngOnInit():void {
    const service = this.dealerService;
    setTimeout(() => {
      MobileCardsMode(this.store, this.id);
    }, 500);
    MobileCardsBackgroundOff(this.store, this.id);

  

    // super.ngOnInit();
    this.subscriptions.push(
      this.store
        .select('mobileCard')
        .pipe(filter(event => event.typeof=== 'PlayCardsDataUnit' && event.id == this.id))
        .subscribe((data: PlayCardsDataUnit) => {
          // console.log(`player card data:${JSON.stringify(data)}`)
          if (data.label == REPLACE) {
            MobileDropCardAction(this.store, this.id, "DROP-1"); // special case for split to drop one hard
            return;
          }
          if (data.label == DROP) {
            MobileCardsPeerErase(this.store, this.id);
            return;
          }
          this.send(data.cards);
        })
    );

  }

  // event on clist
  // Clist(event: AnimationEvent) {
  //   const ignore = this.cardList.shift();
  // }
  cardsEvent(e:any):void {
    // this.event.emit(event);
    //console.log(`player cardsEvent:${JSON.stringify(e)}`);
    // console.log(`player::event-id:${e.id}, player-id:${this.id}, e.action:${e.action}`)
    if (e.id != this.id) return;
    // if (e.action=='allRemoved') {
    //   alert(`card removed event detected.`)
    // }
    if (e.action=='drawn') {
      SendPlayerCardDrawnAction(this.store);
       // console.log('player drawn recvd')
      this.dealerService.updateGlobalCardCount();
    }
  }
  ngOnDestroy():void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    this.dealerService.shuffle();
  }
}
