import {Component, OnInit, Input} from '@angular/core';
import {UserService  } from '../services/user.service';
import {ValidateEmail, globalVars,MobileRouterService} from '../services/global.service';
import { Observable } from "rxjs";


@Component({
  selector: 'app-contact',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})

export class ContactustComponent implements OnInit {

  email: string='';
  subject: string='';
  content: string='';

  response = false;
  message: any;
  basic = true;
  obj = false;
  keys =[];
  mobile=false;

  reset() :void{
   this.basic = true;
   this.email='';
   this.subject='';
   this.content='';
  }

  constructor(
   private userService:UserService,
   private router: MobileRouterService
  ) { }

  ngOnInit() :void {
      this.mobile=globalVars['mobileDevice'];
   }


   handleError ( err:any):any {
    //( `handle error called with :${err}` );
    return Observable.throw ( err );
  }
/*
  emailCheck() {
     return /@/.exec(this.email) && /\./.exec(this.email) ;
  }
*/

  subjectCheck ():any { return this.subject.length ? true : false; }
  contentCheck ():any { return this.content.length ? true : false; }

  contact(): void{

     if (ValidateEmail(this.email)) {
       if ( this.subjectCheck()) {
          if ( this.contentCheck()) {
              this.userService.sendPlainEmail(this.email, this.subject, this.content) ;
              this.reset();
              this.router.navigate('mobile/train');
           } else {
                 alert('Email content missing');
           }
       } else {
          alert('Email subject missing');
       }
     } else {
       alert('Enter correct email address');
     }
  }

}
