
   <div  style="margin-left:{{vars['center-width']}}px">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)" >
                    <div ngxSlickItem class="slide" >
                        <div class="flex-column" *ngIf="vars['mobileDevice'] else others" style="margin-top:15px">
                              <div *ngIf="error.login">
                                    <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                              </div>
                              <mat-form-field >
                                <input matInput required placeholder="email Address" [(ngModel)]="loginData.email">
                              </mat-form-field>
                               <p style="color:red; font-style: italic"> Enter password or pin </p>
                              <mat-form-field >
                                <input matInput required type="password" placeholder="Password" [(ngModel)]="loginData.password">
                              </mat-form-field>
                              <div *ngIf="error.password && loginEnabled">
                                <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                              </div>
                              <mat-form-field>
                                <input matInput required  placeholder="pin" [(ngModel)]="loginData.pin">
                              </mat-form-field>
                              <div *ngIf="error.pin">
                                <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                              </div>


                               <div class="flex-column" *ngIf="loginEnabled==false">
                                    <mat-form-field>
                                      <input matInput required type="password" placeholder="Repeat Password" [(ngModel)]="loginData.repeatPassword" [disabled]="loginEnabled">
                                      <div *ngIf="error.password">
                                        <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                      </div>
                                    </mat-form-field>
                             </div>
                             <div class="flex-column">
                                   <button mat-raised-button *ngIf="loginEnabled"
                                         [color]="colors['login']"  (click)="clicked('login')" class='margin-top-10px' style="height:50px">login</button>
                                   <button mat-raised-button
                                               [color]="colors['signup']"  (click)="clicked('signup')" class='margin-top-10px' style="height:50px">signup</button>
                             </div>
                          </div>

                        <ng-template #others>
                          <mat-card class="example-card">
                              <mat-card-content>
                                <div class="flex-column">
                                      <div *ngIf="error.login">
                                            <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                      </div>
                                      <mat-form-field >
                                        <input matInput required placeholder="email Address" [(ngModel)]="loginData.email">
                                      </mat-form-field>
                                       <p style="color:red; font-style: italic"> Enter password or pin </p>
                                      <mat-form-field >
                                        <input matInput required type="password" placeholder="Password" [(ngModel)]="loginData.password">
                                      </mat-form-field>
                                      <div *ngIf="error.password && loginEnabled">
                                        <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                      </div>
                                      <mat-form-field>
                                        <input matInput required  placeholder="pin" [(ngModel)]="loginData.pin">
                                      </mat-form-field>
                                      <div *ngIf="error.pin">
                                        <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                      </div>
                                       <div class="flex-column" *ngIf="loginEnabled==false">
                                            <mat-form-field>
                                              <input matInput required type="password" placeholder="Repeat Password" [(ngModel)]="loginData.repeatPassword" [disabled]="loginEnabled">
                                              <div *ngIf="error.password">
                                                <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                              </div>
                                            </mat-form-field>
                                     </div>
                                     <mat-card-actions >
                                             <div class="flex-column" >
                                                   <button mat-raised-button *ngIf="loginEnabled" [color]="colors['login']"
                                                   (click)="clicked('login')" class='margin-top-20px' style="height:50px">login</button>
                                                   <button mat-raised-button [color]="colors['signup']"  (click)="clicked('signup')" class='margin-top-20px'
                                                     style="height:50px">signup</button>
                                             </div>
                                      </mat-card-actions>

                                  </div>
                          </mat-card-content>
                    </mat-card>
                        </ng-template>
                    </div>
                    <div ngxSlickItem class="slide">
                        <mat-card class="example-card" >
                              <mat-card-title>Check your email for 5 digit Verification Code</mat-card-title>
                              <mat-card-content>
                                    <div>
                                      <p> </p>
                                      <mat-form-field >
                                        <input matInput required placeholder="verification code" [(ngModel)]="loginData.verification">
                                      </mat-form-field>
                                      <div *ngIf="error.verification">
                                        <span style="color:red; font-size: 90%;"> {{errorMessage}}</span>
                                      </div>
                                    </div>

                                </mat-card-content>
                                <mat-card-actions>
                                  <p> </p>
                                  <button mat-raised-button  [color]="'primary'"  (click)="clicked('verification')" class=' margin-top-10px'>submit</button>
                                </mat-card-actions>

                      </mat-card>
                    </div>
                    <div ngxSlickItem class="slide" >
                              <mat-card class="example-card" >
                                      <mat-card-title>Signup</mat-card-title>
                                      <mat-card-content>
                                            <p style="color:red;"> Your account is not activated, possibly browser caching problem.</p>
                                            <p> if you have clicked the link on your activation email, clear browser history. </p>
                                            <p> if not, please take a look at your activation email and click the link. </p>
                                        </mat-card-content>
                                        <mat-card-actions>
                                          <p> </p>
                                          <button mat-raised-button  [color]="'primary'"  (click)="clicked('note')" class='margin-top-10px'>Got it</button>
                                        </mat-card-actions>

                            </mat-card>
                    </div>
        </ngx-slick-carousel>
    </div>
