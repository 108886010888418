
<div style="margin-left:{{vars['center-width']}}px; height:100%; width:100%">
        <div  style="position: relative; margin-top:{{margin.top}}px">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)" style='width:100%;'>
                    <ng-container  *ngFor="let stmts of statements[method]">
                    <div ngxSlickItem  class="slide">
                      <div class="margin-side-10">
                         <Lp  *ngFor="let stmt of stmts" text="{{stmt}}"> </Lp>
                      </div>
                      <div style="width:25%;margin-left:50px; margin-top:20px" *ngIf="audioEnable">
                            <ngx-audio-player [autoPlay]="false" muted="muted" style="background:yellow; !important"

                                [playlist]="mssapPlaylist"
                                [disablePositionSlider]="false"
                                [displayRepeatControls]="true"
                                [displayVolumeControls]="true"
                                [displayVolumeSlider]="true"

                                [displayTitle]="'adding-count'"

                                (trackEnded)="onEnded($event)">

                           </ngx-audio-player>
                       </div>
                    </div>
                   </ng-container>
                 </ngx-slick-carousel>

                  <!-- <mobile-cards-display (event)="eventHandler($event)" *ngIf="buttons.length" style="position: absolute; top:40px"></mobile-cards-display> -->
                  <mobile-cards-display (event)="eventHandler($event)"  style="position: absolute; top:40px"></mobile-cards-display>


                 <div class="flex-column" style="position: absolute; top: {{marginTop}}px; left:{{buttonWidth}}px">
                        <ng-container *ngIf="buttons.length">
                            <div class='flex' *ngIf="buttons.length" style="margin-left:10%">
                                    <button  mat-mini-fab   *ngFor="let elm of buttons" [@flash]="flashes[elm.key].status"
                                    [color]="elm.color" style="margin-left:15px" (click)="clicked(elm.key)"> {{elm.key}}</button>
                            </div>
                        </ng-container>

                       <div class="flex-row" style="margin-left:8%; margin-top:10px">
                             <button mat-flat-button  (click)="clicked('dbackward')"
                               [disabled]="disabled.dbackward" [color]="'primary'" class="scale-p81">  <<- </button>

                               <button mat-flat-button  (click)="clicked('backward')"
                                 [disabled]="disabled.backward" [color]="'warn'" class="scale-p81 margin-left-c-buttons "> <mat-icon> skip_previous</mat-icon></button>

                                 <button mat-flat-button  (click)="clicked('audio')"
                                   [disabled]="disabled.audio" [color]="'primary'" class="scale-p81 margin-left-c-buttons"> Audio <mat-icon> play_circle</mat-icon></button>

                              <button mat-flat-button  style='margin-left:8px' (click)="clicked('forward')"
                                   [disabled]="disabled.forward" [color]="'accent'"  class="scale-p81 margin-left-c-buttons"> <mat-icon > skip_next </mat-icon></button>
                       </div>
                 </div>


        </div>
</div>
