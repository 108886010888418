import { Component, OnDestroy, OnInit,ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars } from '../../services/global.service';
import { DealerService } from '../../services/deck.service';
import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { GenSettingAction } from '../../store/gen-setting.action.reducer';
import { GenSettingEnum } from '../../store/gen-setting.model';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {rules} from '../services/rule.service';

enum PageEnum {
  top,
  strength,
  table
};

interface tableColumns {
  p:string;
  1:string;
  2:string;
  3:string;
  4:string;
  5:string;
  6:string;
  7:string;
  8:string;
  9:string;
  10:string;
};

@Component({
  selector: 'anms-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['../styles.scss']

})


export class RulesComponent  implements OnDestroy, OnInit  {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  // strengths = {'Strong-high':0, 'Medium-high':1, 'Strong-low':2, 'Medium-low':3};
  columns= [];
  columns_hash = {
    'Book':  ['p','2','3','4','5','6','7','8','9','10','A'],
    'Prediction': ['p', 'd', 'counts','Prediction', 'Book']
  };

  paginatorMargin="40px";
  selectedTab=0
  bgcls={
    p:"null",
    d:"null",
    A:"null",
    2:"null",
    3:"null",
    4:"null",
    5:"null",
    6:"null",
    7:"null",
    8:"null",
    9:"null",
    10:"null",
    counts:"null",
    'Prediction':"accent-bg",
    'Book':"warn-bg",
  };

  debug=false;
  tableShow=false;
    slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
    arrows: false,
   "infinite": false,
   // 'showArrow': false,
   'swipeToSlide': true,
 };
  colors={
    'Book':'warn',
    'Prediction': 'primary',
    'high cards very likely': 'primary',
    'low cards very likely': 'warn'
  };



  gcls(col) {
    switch(col) {
      case 'p': return "player_table_margin";
      case 'Prediction': return "accent-bg";
      case 'Book': return "warn-bg";
      case 'counts': return "rules-times-table";
      default: return "null";
    }
  }
  isSoft(type) { return type=='Soft';}
  isSplit(type) { return type=='Split';}

  transform(dict, type) {
    const list=[];
    for (let pcard in dict) {
      // console.log(`key:${key}, value:{ value)}`);
      let dpcard=pcard; // modify the pcard for soft and split
      if (this.isSoft(type)) {
         dpcard=`1,${pcard}`;
      } else if (this.isSplit(type)) {
           dpcard=`${pcard},${pcard}`;
      }
       const d={...dict[pcard]}; d['p']=dpcard;

       list.push(d);
    }
    return list;
  }
  getList(rule, type, strength="null") {
    if (rule=='Book') {
      this.debug=true;
      return this.transform(rules['Book'][type], type);
    }
    this.debug=false;
    const list=[];
    const transform={
      d:'double',
      h: 'hit',
      s: 'stand',
      sp: 'split',
      'd,i':'double, insurance',
      'h,i': 'hit, insurance'
    };
    // console.log(`rule:${rule}, type:${type}, strength:${strength}`);

     // for (const item of  rules[strength][type]) {
       for (const item of  rules[rule][type]) {

         const cc={...item};
         const Prediction=cc['Prediction'];
         const book=cc['Book'];
         const counts=cc['counts'];
         const d={}
         for (let s of counts) { // extract count
            s=s.replace(/"/g, "");
            let [decision, count] = s.split(':');
            d[decision]=count;
         }
         // cc['counts']=`${d[D]} vs ${d['Book']}`;
         cc['counts']=`${d[Prediction]} vs ${d[book]}`;

         cc['Prediction']=transform[Prediction];
         cc['Book']=transform[book];

         list.push(cc);
     }
     return list;
  }

   subscriptions = [];
   enabled=false;
   gvars=globalVars;
   dtable :  MatTableDataSource<tableColumns>;
   pageSizeOptions=[];
   page={c:PageEnum.top, p:[]};
   selected = {
     rule:'',
     strength:'',
   };

  // clicked(event:any){
  // }
  constructor(public store: Store<any>, private routerService:MobileRouterService) {
  }

  // assign(strength) {
  //   if (this.tableShow) {
  //     this.strengthTable.data = this.tables[strength];
  //     return;
  //   }
  //   this.strengthTable = new MatTableDataSource<StrengthTableIf>(this.tables[strength]);
  //
  //
  //   this.tableShow=true;
  // }
  bookCol() {
    return this.columns_hash['Book'];
  }
  jackCol() {
    return this.columns_hash['Prediction'];
  }
  bookTopMsg() {
    GenericTopTopAction(this.store, ['Book', 'Digits 1 through 10',"Represents dealer's face card"], 'tigger');

  }
  strengthMsg() {
    GenericTopTopAction(this.store, ["Decisions based on prediction", "High-cards are more likely",'Low-cards are more likely'], 'tigger');

  }
  jackMsg() {
      const strength=this.selected.strength=='low cards with high-likelihood'? 'Low cards very likely': 'High cards very likely';
      GenericTopTopAction(this.store, ["Modified-book", strength,'Running over 1000 shuffles','counts: Prediction vs Book'], 'tigger');
  }
  topMsg() {
    GenericTopTopAction(this.store, ['Overview of the rules', "Book vs decisions based on prediction"], 'tigger');

  }
  ngOnInit() {
    // BottomAppleModify(this.store, { enable:['backward'], disable:['forward', 'lastIcon'],  lastIcon:'settings'});
    // BottomAppleModify(this.store, { disable:['forward', 'lastIcon'],  lastIcon:'settings'});
      BottomAppleModify(this.store, {enable:['lastIcon', 'backward'], disable:['forward'], lastIcon:'settings'});
    globalVars['vpath']="rules";

    this.topMsg();
    this.pageSizeOptions=globalVars['mobileDevice']?[3,4]: [5,10];
    // this.assign('Strong-high');
    this.columns=this.bookCol();
    this.dtable = new MatTableDataSource<tableColumns>(this.getList('Book', 'Soft'));
    this.paginatorMargin=globalVars['mobileDevice']?"0px":"40px";

    setTimeout(() => {
      this.enabled=true;

    }, 400);
     this.subscriptions.push(
     this.store
       .select('mobileTopBottom')
       .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
       .subscribe((data: KeyPressedDm) => {
         // need to see approriate data with keys pressed
         if (data.key=='backward') {
           this.goback();
           return;
         }

       }));

      }

   ngOnDestroy() :void{
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
   getOptions() {
    if (globalVars['mobileDevice']) {
       return [6,5]
    }
    if (globalVars['midDevice']) {
       return [8,7,5]
    }
    return [10, 7, 5];
  }
   ngAfterViewInit() {
         this.dtable.paginator = this.paginator;
  }
  afterChange(event:any):void {
 }



 goback() {
   const len=this.page.p.length;
   if (len==0) {
     this.routerService.navigate('mobile/train');
     return;
   }
   const page=this.page.p.pop();
   this.page.c=page;
   this.selectedTab=0;
   this.slickModal.slickGoTo(page);
   switch(page ){
     case PageEnum.top: {
        this.topMsg();
        return;
     }
     case PageEnum.strength: {
       this.strengthMsg();
       return;
     }

   }
 }
  movePage(to) {
    this.page.p.push( this.page.c);
    this.page.c=to;
    this.slickModal.slickGoTo(to);
  }

  clicked(key) {
   BottomAppleModify(this.store, { enable:['backward'],  lastIcon:'settings'});
   // if (key=='book') {
   //    key='Books';
   // } else if (key=='modified') {
   //    key='Predictions';
   // }
   switch(key) {
      case 'Book': {
        this.selected.rule='Book';
        this.columns=this.bookCol();
        this.bookTopMsg();
        this.dtable.data=this.getList('Book', 'Soft');
        this.movePage(PageEnum.table);
        return;
      }
    
      default: {
        this.selected.rule='jack-count';
        this.columns=this.jackCol();
        this.selected.strength=key;
        this.selected.rule=key;
        this.enabled=true;
        this.jackMsg();
        this.dtable.data=this.getList(this.selected.rule, 'Soft', this.selected.strength);

        this.movePage(PageEnum.table);
        return;
      }


    }
   }
   tabClicked(event) {
     // console.log(`this.selected.rule:${this.selected.rule}, event.tab.textLabel:${event.tab.textLabel}`)
     this.dtable.data=this.getList(this.selected.rule, event.tab.textLabel, this.selected.strength);
   }
}
