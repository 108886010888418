import { Component, OnDestroy, AfterViewInit,OnInit, ViewChild, EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars,isJack,imageDims ,maxCardsPerCol,decksMapping} from '../../services/global.service';
import { DealerService } from '../../services/deck.service';
import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import { map, filter } from 'rxjs/operators';
import { GenSettingAction } from '../../store/gen-setting.action.reducer';
import { GenSettingEnum } from '../../store/gen-setting.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {MobileSendKeyRoute, BottomResetMenuPressed, BottomAppleModify} from '../store/bottom.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { GenericTopTopAction,GenericSlideOnlineTrain,GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,Flash} from '../../services/common.service';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';

declare var webkitSpeechRecognition: any;
// declare var SpeechRecognition: any;
const IDLE = 0;
const DEFAULT_COLOR = 'primary';
enum SettingActiveEnum {
	active,
	idle
};
interface ResultTableIf {
	Hand: number;
	Player: string;
	Dealer: string;
	Won: string;
	Lost: string;
	Net: number;
};
interface locationIF {
  row: number;
  col: number;
};

interface shandsIF {
  hands:string;
  bet:string;
}

interface CountGetIF {
    max: string;
    bet: number;
};
interface HandQueryIF {
  hand: Cards;
  index:number;
}
interface PSIF {
  low:number;
  med:number;
  high:number;
}
interface Selected {
  decks: string;
  splits: string;
  hands: number;
  soft17: string
};
interface PAIRIF {
  key:string;
  value:any;
};
interface DELAY_DECISION_IF {
  turn:string;
  value: string;
}

interface PredictCardIf {
	max:string;
	nxtCard:number;
	Slow:boolean;
	Shigh:boolean;
	SShigh:boolean;
	SSlow:boolean;
	Mhigh:boolean;
	Mlow:boolean;
	weak:boolean;

};
const MIN=0;
const MAX=1;
enum SRstateEnum {
	idle,
	start,
	stop
}
class SpeechRecognitionClass {
	recognition=new webkitSpeechRecognition();
	synth=window.speechSynthesis;
	event: EventEmitter<string>;
	validString=[ '2', '3', '4', '5', '6', '7', '8','9','10','ace','king', 'queen','for','ate',
	             'jack','two', 'three', 'four', 'five','six', 'seven', 'eight', 'nine', 'ten'];
	validMap={
		2:2, 3:3, 4:4, 5:5, 6:6, 7:7, 8:8, 9:9, 10:10,
		two:2, three:3, four:4,
		five:5, six:6, seven:7, eight:8, nine:9, ten:10,
		for:4,ate:8,
		ace:1, jack:10, queen:10, king:10};
  count=0;
	rcvd=0;
	state=SRstateEnum.idle;
	config() {
		this.recognition.continuous=true;
    this.recognition.lang="en-US"
    this.recognition.interimResults=false;
    this.recognition.maxAlternatives=1;
		this.recognition.onresult = (e) => {
						let transcript = e.results[e.results.length-1][0].transcript.trim().toLowerCase();
						 // console.log(`rcvd voice: transcript:<${transcript}>`)

						transcript=transcript.replaceAll('comma','');
						// console.log(`rcvd voice: removing comma transcript:<${transcript}>`)

						for (let str of transcript.split(' ')) {
							str=str.replace(/\s+/, "") ;
							// console.log(`rcvd voice: str:${str}`)
							if (this.validString.includes(str)) {
								// console.log(`rcvd voice through filter: ${str}, validMap:${this.validMap[str]}, rcvd:${this.rcvd}, count:${this.count}`)
								this.event.emit(this.validMap[str]);
								this.rcvd++;
								if (this.rcvd==this.count) {
									this.recognition.stop();
								}
							}
						}
		};
	}
	constructor(event) {
    const synth=window.speechSynthesis;
		this.event = event;
		// this.locEvent= new EventEmitter<number>();
		this.config();
  }
	end() {
		if (this.state ==SRstateEnum.start) {
			// console.log(`rcvd stop request`)
			this.recognition.stop();
			this.state=SRstateEnum.stop;
		}
	}

	start(count) {
		 if (!globalVars['voice']) return;
		  this.rcvd=0;
			const timeout= count==100? 1000:0;
			setTimeout(() => {
				this.count=count;
			}, timeout);
			// console.log(`trying to start with count of ${count}`)
			if (this.state != SRstateEnum.start) {
				this.recognition.start();
				this.state=SRstateEnum.start;
			}
	}
		talk(decision) {
			const utter=new SpeechSynthesisUtterance(decision);
			utter.onend=() => {
				this.rcvd=0;
				this.count=1;
				this.recognition.start();
		  };
			this.synth.speak(utter);
			// console.log(`talking ${decision}`)
		}
	}

class ResultReport {
   hands=0;
   net=0;
   list: Array<ResultTableIf>;
   columns=['Hand', 'Player','Dealer', 'Won','Lost','Net'];
   length() { return this.list.length;}
   join(cards) {
		 const list=[];
		 for (let c of cards) {
			 if (c==1) {list.push('A'); continue;}
			 list.push(c);
		 }
	  return list.join();
   }
   constructor() {
	   //this.list=[{Hand:0, Player: '0', Dealer:'1', Won:'-', Lost:'20', Net:this.net}];
	   this.list=[];
	  }
   loss(hand, player,dealer, bet) {
     const loss = `\$${bet}`;
		 this.net-=bet;
     this.list.push({Hand:hand, Player: this.join(player), Dealer:this.join(dealer), Won:'-', Lost:loss, Net:this.net});
   }
   pushed(hand, player,dealer, bet) {
     this.list.push({Hand:hand, Player: this.join(player), Dealer:this.join(dealer), Won:'-', Lost:'-', Net:this.net});
   }
   won(hand, player,dealer, bet) {
     const win = `\$${bet}`;
		  this.net+=bet;
     this.list.push({Hand:hand, Player: this.join(player), Dealer:this.join(dealer), Won:win, Lost:'-', Net:this.net});
   }
   // main push
	 _push(sum, bet, player, dealer, dealerSum) {
		 // console.log(`player:${JSON.stringify(player)}, dealer:${JSON.stringify(dealer)}`)
				 if (sum > 21) { // player busted
					 this.loss(++this.hands, player, dealer, bet);
					 return;
				 }
				 if (dealerSum > 21) { // dealer busted
					 this.won(++this.hands, player, dealer, bet);
					 return;
				 }
				 if (sum == dealerSum) { // push
					 this.pushed(++this.hands, player, dealer, bet);
					 return;
				 }
				 if ( dealerSum > sum) { // dealer won
					 this.loss(++this.hands, player, dealer, bet);
					 return;
				 }
				 // player won
				 this.won(++this.hands, player, dealer, bet);
	 }

   push(sums, bets, player, dealer,dealerSum) {
		   let i=0;
		   for (const sum of sums) {
						  const p=player[i];
						  const bet=bets[i++];
							this._push(sum, bet, p, dealer.cards, dealerSum);
			}
   }
	 pushc(sums, bets, player, dealer, dealerSum) { // blackjack cases
		 let i=0;
 		 for (const sum of sums) {
						const p=player[i];
						const bet=bets[i++];
						this._push(sum, bet, p.cards, dealer.cards, dealerSum);
		}
	 }
};

class CardsInTheDeck {

  makeup={
     low:5,
     high:5,
     med:3,
     suit: 4
  };

  cards = {
     low:0,
     high:0,
     med:0,
  };
  tcards=0;
  constructor(){
		const decks=globalVars['ndecks'];
    this.tcards = decks*52; // total cards
    const cards = this.cards;
    const calN=(t:any)=> this.makeup[t] * this.makeup.suit*decks;
    cards.low = calN('low');
    cards.high = calN('high');
    cards.med = calN('med');
  }
  push(T:any):void {
    this.cards[T]--;
    this.tcards--;
  }
  prob(T:any) :any{
    return this.cards[T]/ this.tcards;
  }
};
class  CardStrength {
	  cards= new CardsInTheDeck();
		thold = {
			high: {strong:1, sstrong:1.2, medium:0.8 },
			medium: {strong:1.2, sstrong:1.3, medium:1 },
			low: {strong:1.4, sstrong:1.5, medium:1.2 }

		};
		constructor() {}
		push (T) {
			this.cards.push(T);
		}
		ps():PSIF {
	    return {low: this.cards.prob('low'),
	            high: this.cards.prob('high'), med: this.cards.prob('med')};
	  }

	  // find the max probality
	  reduce(ps:any, ivalue:any, func:any ):PAIRIF {
	    let tmp=ivalue;
	    let t='';
	    for (let [key, value] of Object.entries(ps)) {
	      if (func(value,tmp)) {
	        tmp=value;
	        t=key;
	      }
	    }
	    return {key: t, value: tmp}
	  }

		nxtCard(T) {
			switch(T) {
				case 'high' : return 10;
				case 'low'  : return 4;
				default     : return 8;
			}
		}
		meets(l1,l2,rvalue) {
			return (l1/l2) >= rvalue;
		}
// 		redictions:{"max":"high","nxtCard":0,"Slow":false,"Shigh":false,"SShigh":false,"SSlow":false,"Mhigh":true,"Mlow":false,"weak":true}
// 2online.component.ts:579 predictions:{"max":"high","nxtCard":0,"Slow":false,"Shigh":true,"SShigh":false,"SSlow":false,"Mhigh":false,"Mlow":false,"weak":true}
		assign(pb, rvalue, risk) {
			// console.log(`assign pb:${JSON.stringify(pb)}`);
			rvalue.Slow=this.meets(pb.low,pb.high,this.thold[risk].strong);
			rvalue.Shigh=this.meets(pb.high,pb.low,this.thold[risk].strong);
			rvalue.SSlow=this.meets(pb.low,pb.high,this.thold[risk].sstrong);
			rvalue.SShigh=this.meets(pb.high,pb.low,this.thold[risk].sstrong);
			if (rvalue.Shigh || rvalue.SShigh  || rvalue.Slow || rvalue.SSlow) { return;}
			if ( this.meets(pb.high,pb.low,this.thold[risk].medium)) {
				 rvalue.Mhigh=true;
				 return;
			}
			if ( this.meets(pb.low,pb.high,this.thold[risk].medium)) {
				 rvalue.Mlow=true;
				 return;
			}
				rvalue.weak=true;
		}
		highRisk(pb, max) {
				const rvalue :PredictCardIf= {max:'', nxtCard:0,Slow:false,Shigh:false,SShigh:false,SSlow:false, Mhigh:false, Mlow:false, weak:false};
				rvalue.max=max.key;
				this.assign(pb, rvalue, 'high');
				rvalue.nxtCard = this.nxtCard(max.key);
				return rvalue;
		}
		mediumRisk(pb, max) {
				const rvalue :PredictCardIf= {max:'', nxtCard:0,Slow:false,Shigh:false,SShigh:false,SSlow:false, Mhigh:false, Mlow:false, weak:false};
				rvalue.max=max.key;
				this.assign(pb, rvalue, 'medium');
				rvalue.nxtCard = this.nxtCard(max.key);
				return rvalue;
		}
		lowRisk(pb, max) {
				const rvalue :PredictCardIf= {max:'', nxtCard:0,Slow:false,Shigh:false,SShigh:false,SSlow:false, Mhigh:false, Mlow:false, weak:false};
				rvalue.max=max.key;
				this.assign(pb, rvalue, 'low');
				rvalue.nxtCard = this.nxtCard(max.key);
				return rvalue;
		}

		prediction () {
			const probalities = this.ps();
			const max = this.reduce(probalities, 0, (a:any,b:any)=>a>b);
	    // const min = this.reduce(probalities,100, (a:any,b:any)=>a<b);
		  switch(globalVars['risk']) {
				case 'high': {return this.highRisk(probalities, max); }
				case 'moderate': {return this.mediumRisk(probalities, max); }
				default: {return this.lowRisk(probalities, max); }
			}

		}

};
class Count {
  cardsInThedeck:CardsInTheDeck;
  cards = {
    low:0,
    high:0,
    med:0
  };
	list=[];
	listIndex=0;
	extreme=false;
	cardStrength:CardStrength;
  constructor(){
    this.cardsInThedeck = new CardsInTheDeck();
		this.cardStrength   = new CardStrength();
  }
  strEasy() :any{
    const cards=this.cards;
    if (!(cards.high+cards.low)) return 'O';
    if (cards.high==0) {
      return `${cards.low}L`;
    }if (cards.low==0) {
      return `${cards.high}H`;
    } return `${cards.high}O${cards.low}`;
  }
	strJackEasy() :any{
		const cards=this.cards;
		if (cards.high==0) return `${cards.low}`;
		if (cards.low==0) return `${cards.high*10}`;
		return `${cards.high*10+cards.low}`;
  }
  str() :any{
    const cards=this.cards;
    switch (globalVars['method']) {
      case 'advance':
      case 'jack' : return this.strEasy();
			case 'single' : return this.strJackEasy();
      case 'mit': return (cards.high>cards.low)? `-${cards.high-cards.low}`: `${cards.low-cards.high}`;
      default: return `${cards.high}`;
    }
  }
  newHand() {
		this.listIndex=this.list.length;
	}

	backtrack() {
		const length = this.list.length;
		for (let i=this.listIndex; i!=length;i++) {
			const card = this.list[i];
			if (card ==10) { this.cards.high--;}
			else if (card < 7) { this.cards.low--}
		}
	}
	reduceit() :void{
		if (this.cards.low>this.cards.high) {
			this.cards.low-=this.cards.high;
			this.cards.high=0;
			return;
		}
		this.cards.high-=this.cards.low;
		this.cards.low=0;
	}
  push(card:any) :void{
		this.list.push(card);
    if (card==10) {
      this.cards.high++;
      this.cardStrength.push('high');
      return;
    }
    if ([7, 8, 9].includes(card)) {
      this.cards.med++;
      this.cardStrength.push('med');
      return;
    }
    this.cards.low++;
    this.cardStrength.push('low');
  }
  prediction() { return this.cardStrength.prediction();}
  getSuggestedHands():shandsIF {
    // const strength=this.strength();
    // if (strength.key=='high') {
    //   return strength.value=='medium'? {hands:'1', bet:'2'}: {hands:'2', bet:'4'};
    // }
    return {hands:'one', bet:'1'};
  }


}

class BetManagement {
	list:Array<number>;
	index:number;
	constructor(list) {
		this.list=list;
		this.index=0;
	}
	split() {
		this.list.splice(this.index,0, this.list[this.index]);
	}
	double() {
		this.list[this.index]*=2;
	}
	next() {
		this.index++;
	}
	get() {
		return this.list;
	}
	update(decision) {
		switch(decision) {
			case 'DOUBLE' : {
				this.double();
				return;
			}case 'SPLIT' : {
				this.split();
				return;
			} case 'INS': {

				return;
			}
		}

	}
	updateInsurance(index) {
		const bet=this.list[index];
		this.list[index] += bet/2;
	}
	 print(msg) {
		 console.log(`BetManagement::${msg}::list:${this.list}`);
	 }


};

class Decision {
  cards: Cards;
  count: Count;
  id: number;
  splitCount=0;
  split=false;
  maxSplits:number;
  decks:string;
  stack=[];
  splitsMap={one:1, two:2, three:3, four:4, five:5,six:6};
  constructor(id: number, cards: Cards, count:Count, maxSplits:any, decks:any) {
    this.cards=cards;
    this.count=count;
    this.id = id;
    this.maxSplits=this.splitsMap[maxSplits];
    this.decks=decks;
  }
  twoCards():any { return this.cards.length==2;}
  reinstate():any { this.cards.reinstate();} // get the hand ready for
  splitAllowed(maxSplit:any):any { return this.splitCount <maxSplit; }
  aceSplitAllowed():any {
    return this.splitAllowed(this.decks == 'six-decks'? this.maxSplits: 1);
  }
  busted():any { return this.cards.busted;}
  aceSplitAllowed_(Slow):boolean {
    if (Slow) {
       return false;
    }
    return true;
  }

  partOfSplit() { return this.cards.splitCount; }
  insurance(dealer) {
		const sum=this.cards.sum;
		const prediction = this.count.prediction();
		if (prediction.SShigh) { // very strong
			return sum != 21;
		}
		 return prediction.Shigh &&  sum!=21 && [20,19].includes(sum);
	 }

	splitDecison_14x16(fcard, Slow) { // delaer possible hand is in range of [14,15,16]
		let decision;
		switch(fcard) { // first player card
			 case 8: {
					decision = Slow? 'SPLIT': 'STAND';
					break;
			 }
			 case 7: {
					decision = Slow? 'SPLIT': 'STAND';
					break;
			 }
			 case 6: {
					decision = Slow? 'SPLIT': 'STAND';
					break;
			 }
			 case 5: {
					decision = Slow? 'SPLIT': 'DOUBLE';
					break;
			 }
			 case 4: {
					decision = Slow? 'SPLIT': 'DOUBLE';
					break;
			 }
			 case 2:
			 case 3: {
					decision = 'HIT';
					break;
			 }
		}
		if (decision=='SPLIT') {
				 this.cards.split();
				 this.splitCount++;
		}
		return decision;
	}
	splitDecison_12x13(fcard, Slow) {
		let decision;
		switch(fcard) { // first player card
			 case 8: {
					 decision = Slow? 'SPLIT': 'STAND';
					 break;
			 } case 7: {
					 decision = Slow? 'SPLIT': 'STAND';
					 break;
			 } case 6: {
					 decision = Slow? 'SPLIT': 'STAND';
					 break;
			 } case 5: {
					 decision = Slow? 'SPLIT': 'DOUBLE';
					 break;
			 } case 2:
				 case 3:
				 case 4: {
					 decision = 'HIT';
					 break;
			 }
		}
		if (decision=='SPLIT') {
				 this.cards.split();
				 this.splitCount++;
		}
		 return decision;
	}
	splitDecison_others(fcard, Slow) {
		let decision;
		switch(fcard) {
			 case 8: {
					 decision = 'STAND';
						break;
			 } case 7: {
					 decision = 'STAND';
					 break;
			 } case 6: {
					 decision = Slow? 'HIT': 'STAND';
					 break;
			} case 5: {
					 decision = Slow? 'SPLIT': 'DOUBLE';
					 break;
			 } case 2:
				 case 3:
				 case 4: {
					 decision = 'HIT';
					 break;
			 }
		}
		if (decision=='SPLIT') {
						this.cards.split();
						this.splitCount++;
		}
		return decision;
	}
  get(dealer:any): string{
    if (this.stack.length) {
      if (this.split) { // Ace split
        this.split=false;
        if (this.cards.lastCard(1) && this.aceSplitAllowed()) {
          this.stack.shift();
        } else {
          this.cards.split();
          return this.stack.shift();
        }
      }
    }
    if (this.split) { // this is for Ace split
       this.cards.split();
       this.split=false;
    }
    // const bet=count.bet;
    const soft17 = this.cards.isSoftOfValue(7);
    const soft18 = this.cards.isSoftOfValue(8);

    const dealerShowing = dealer.showing();
    const aceUp = dealer.aceUp();
    let sum = this.cards.sum;
		sum = soft17?7: sum;
		// alert(`sum:${sum}, soft17:${soft17}`)
		const prediction = this.count.prediction();
		// console.log(`predictions:${JSON.stringify(prediction)}`);

    // console.log(`probalities:${JSON.stringify(ps)}, strength:${strength}`);
    if (prediction.weak) { // normal cases with card strength weak
      if ([15,16].includes(sum)) {
         return 'STAND';
      }
      if ([12,13,14].includes(sum)) {
         return dealerShowing>6? 'HIT': 'STAND';
      }
      if (sum>21) { return 'BUSTED'};
      if (sum>=17) { return 'STAND'};
      return 'HIT';
    }
    const nxtCard=prediction.nxtCard;
    const dSum=nxtCard+dealerShowing;

    if (this.cards.isSplit()) {
        const aceUp = this.cards.aceUp();
        const splitAllowed = aceUp?this.aceSplitAllowed():this.splitAllowed(this.maxSplits);
				// console.log(`aceUp:${aceUp}, splitAllowed:${splitAllowed}`);
        if (splitAllowed) {
          if (aceUp) {
            if (this.aceSplitAllowed_(prediction.Slow)) {
              this.split=true;
              this.splitCount++;
              this.stack=[ 'STAND','HIT', 'STAND'];
              return 'SPLIT';
            }
          } else {
            const fcard=this.cards.get(0);
																// forcing split for testing
																//this.splitCount++;
																//this.cards.split();
															//	return 'SPLIT';
		        if ([14,15,16].includes(dSum)) {return this.splitDecison_14x16(fcard, prediction.Slow);}
		        if ([12,13].includes(dSum)) { return this.splitDecison_12x13(fcard, prediction.Slow);}
					 	return this.splitDecison_others(fcard, prediction.Slow);
        }
      }
    }
    switch(sum) {
      case 12: {
          if ([17,18,19,20].includes(dSum)) {
              return nxtCard <10? 'HIT': 'STAND';
          }
          if ([12,13,14,15,16].includes(dSum)) {
             return nxtCard <6? 'HIT': 'STAND';
          }
          if ([8,9].includes(dSum)) {
             return nxtCard <10? 'HIT': 'STAND';
          }
          return 'STAND';
      }
      case 13: {
          if ([17,18,19,20].includes(dSum)) {
              return nxtCard <9? 'HIT': 'STAND';
          }
          if ([12,13,14,15,16].includes(dSum)) {
             return nxtCard <6? 'HIT': 'STAND';
          }
          if ([8,9].includes(dSum)) {
             return nxtCard <9? 'HIT': 'STAND';
          }
          return 'STAND';
      }
      case 14: {
          if ([17,18,19,20].includes(dSum)) {
              return nxtCard <8? 'HIT': 'STAND';
          }
          if ([12,13,14,15,16].includes(dSum)) {
             return nxtCard <6? 'HIT': 'STAND';
          }
          return 'STAND';
      }
      case 16:
      case 15: {
        if ([17,18,19,20].includes(dSum)) {
            return nxtCard <7? 'HIT': 'STAND';
        }
          return 'STAND';
      }
    }
    // console.log(`this.cards cards:${JSON.stringify(this.cards)}, length:${this.cards.length}`)

    // if (this.cards.isSplit() && this.splitCount < this.maxSplits) {
		if (this.twoCards() && this.cards.softDouble() && dealerShowing<7  ) {
			if ([5,6].includes(dealerShowing)) {
				return prediction.SShigh ? 'DOUBLE': 'HIT';
			}
			if (soft18) return 'STAND';
			return 'HIT';
		}
		if (this.twoCards() && [9,10,11].includes(sum)) {
			const SShigh=prediction.SShigh;
			const thold = 17;
			 if (SShigh) {
				 let i=0;
				 for (let _sum of [9,10,11]) {
					 if (dSum < (thold+i++)) {
						 this.stack.push('STAND'); // next rule is stand after double
						 return 'DOUBLE';
					 }
				 }
			 }
			 return 'HIT';
		}
    if (this.twoCards() && [9,10,11].includes(sum)) {
			const SShigh=prediction.SShigh;
			const thold = 17;
			 if (SShigh) {
				 let i=0;
				 for (let _sum of [9,10,11]) {
					 if (dSum < (thold+i++)) {
						 this.stack.push('STAND'); // next rule is stand after double
						 return 'DOUBLE';
					 }
				 }
			 }
			 return 'HIT';
    }
    if (sum>=17) {
       return   sum>21  ? 'BUSTED': 'STAND';
    }
    if (sum<12) {
       return   'HIT';
    }
    // PLACE HOLDER
    return 'STAND';
    // if (sum<9) {
    // }
    // if ()
    // switch(this.getCurrentSum()) {
    //   case 17:case 18
    // }
  }
}
enum AribterState {
   firstRound,
   secondRound,
   dealerReached,
   individual,
   done
};
enum AribterDealerStateEnum {
	first,
	second
};
const DEALER=100;
class Arbiter {
   list: Array<Decision>;
   state:AribterState;
   count: number;
   round:number;
   length:number;
   sums=[];
   stack=[]; //used for intsurance purpouse
   insCount=0;
   bustedCount=0;
   return=false;
   partOfSplit=0;
   decisions={};
	 handsDone=[];
	 dealerState:AribterDealerStateEnum=AribterDealerStateEnum.first;
   bet:BetManagement;
	 // speechRecognition:SpeechRecognitionClass;
   // constructor (bet:Array<number>, speechRecognition) {
		 constructor (bet:Array<number>) {

     this.reset();
     this.bet= new BetManagement(bet);
		 // this.speechRecognition=speechRecognition;
   }
   recycle() :any{
     const rval= this.return;
     this.return=false;
     return rval;
   }
   next() :any{
       return this.stack.length?
                 this.stack[0] == DEALER? false: true
              :this.list.length>0;
    }
   allbusted():any {
     return this.bustedCount==this.length;
   }
   reset() :void{
     this.list=[];
     this.state=AribterState.firstRound;
     this.count=0;
     this.length=0;
     this.partOfSplit=0;
   }
   push(elem: Decision):void {
     this.list.push(elem);
     this.length++;
   }
   current() :any{
     return this.stack.length? this.stack[0]:this.list[0].id;
   }
	 insurance(dealer) {
		 const list=[];
		 let i=0;
		 for (let d of this.list) {
			  const ins = d.insurance(dealer);
				if (ins) {
					this.bet.updateInsurance(i);
				}
				i++;
			  list.push(ins?'Y':'N');
		 }
		 return list;
	 }
   decision(dealer:any):any {
	   /*
      let force_id=DEALER;
     if (this.stack.length) {
       force_id=this.stack.shift();
     }
    */
     const index=this.stack.length?this.stack.shift(): 0;
     //console.log(`arbiter::decision:index:${index}`);
     const decision = this.list[index].get(dealer);

		 this.bet.update(decision);
     //this.bet.print('after');
     this.partOfSplit=this.list[index].partOfSplit();
     /*
     if (decision in ['SPLIT', 'DOUBLE']) {
        if (!(index in this.decisions)) {
	 this.decisions[index]=[];
        }

     }
    */
		// if (this.partOfSplit) alert(`part of the split`)
         // console.log(`decision for hand:${id}, ${decision}`);

     if (['BUSTED', 'STAND'].includes(decision)) {
       if (decision=='BUSTED') this.bustedCount++;
       this.sums.push(this.list[0].cards.sum);
			 let list=[];
			 for (let card of this.list[0].cards.cards) {
				 list.push(card);
			 }
			 // console.log(`cards :${JSON.stringify(list)}`)
			 this.handsDone.push(list);
       const current = this.list[0];
       //alert(`hand:${index}inside the splitCount:${current.splitCount}`);
       if (current.splitCount) {
         current.reinstate();
         current.splitCount--;
         this.bet.next();
       } else {
	 // move to next hand
         this.list.shift();
         this.bet.next();
         // console.log(`decision list-size: ${this.list.length}`)
       }
     }
      // const id = this.list.length? this.list[0].id: 100;
     // console.log(`hand:${id}, decision:${decision}`);
     if (/INS/.exec(decision) && this.stack.length==0) {
       this.insCount += /YE/.exec(decision)?1:0;
       for (let i=1;i!=this.list.length;i++) {
         this.stack.push(i);
       }
       this.stack.push(DEALER);
     }
     return decision;
   }
   hands() { return this.handsDone;}
	 bjSums() {
		 const sums=[];
		 for (let d of this.list) {
			 sums.push(d.cards.sum);
		 }
     return sums;
	 }

	 turn():any {
		 // console.log(`state:${AribterState.firstRound}, count:${this.count}, length:${this.length}`)
		 switch(this.state) {
				case AribterState.firstRound: {
					const current = this.count;
					this.count++;
					if (this.count==this.length) {
						 this.count=0;
						 this.state = AribterState.dealerReached;
						 this.dealerState=AribterDealerStateEnum.first;
					}
					return current;
				} case AribterState.secondRound: {
					const current = this.count;
					if (++this.count==this.length) {
						 this.state = AribterState.individual;
					}
					return current;
				}
				case AribterState.dealerReached: {
					this.state = AribterState.secondRound;
					this.return=true;
					this.dealerState=AribterDealerStateEnum.second;
					return DEALER;
				}
				case AribterState.individual: {
					const turn = this.list.length? this.list[0].id: DEALER;
					// console.log(`AribterState.individual:turn:${turn}`);
					this.count++;
					return turn;
				}
		 }
	 }
   active():any { return this.state == AribterState.individual;}
   // just turned individual for switching turn to first hand
   turned() :any{
     return this.active() && this.count==0;
   }
	 dealerFirstStage() { return this.dealerState == AribterDealerStateEnum.first;}
}

class Cards {
  cards=[];
  length=0;
  sum=0;
  busted=false;
  splitCard='';
  splitCount=0;
  id:number;

  constructor(id:any) {
    this.id=id;
  }
  get(index:number): any {
	  return this.cards[index];
  }

  reset():void {
    this.cards=[];
    this.length=0;
    this.splitCount=0;
    this.sum=0;
  }
  incr() :void{
    this.length++;
  }
  decr() :void{
    this.length--;
  }
  active() :any{ return this.length>1;}

  push(card:any):void {
    this.cards.push(card);
    this.incr();
    this.sum=this._sum();
    // console.log(`Cards[${this.id}]pushing to card , value:${card} length:${this.length},sum:${this.sum}`)

  }


  split() :void{
    // console.log(`Cards[${this.id}](split)::this.cards:${JSON.stringify(this.cards)} length:${this.cards.length}`);
     this.splitCard=this.cards.shift();
     this.sum = this._sum();
     this.splitCount++;
     this.decr();
   }
  reinstate() :void{
     this.cards=[this.splitCard];
     this.sum = this._sum();
     this.length=1;
     this.splitCount++;
  }
  showing():any { return this.cards[0];}
  lastCard(card:any):any { return this.cards.slice(-1)[0] == card;}
  aceUp():any { return this.showing()==1;}
	checkBJ() { return this.cards.length==1 && this.aceUp();}
  isSplit():any { return this.cards.length==2 && this.cards[0]==this.cards[1] && ![8,9,10].includes(this.cards[0 ]);}
  isAceSplit():any { return this.cards[0]==1 && this.isSplit();}
	soft() { return this.cards.includes(1);}
  pop() :void {
    this.cards.pop();
    this.decr();
    this.sum=this._sum();
  }
	softDouble() {
		return this.soft() && this.sumWithoutAces()<8
	}

  isSoftOfValue(value: number, ncards=-1) :any {
		// if (this.cards.includes(1) && this.checkSoft(value)) {
		// 	 return ncards==-1? true:  this.cards.length ===ncards;
		// }
		if (this.cards.includes(1)) {
			 if ( this.checkSoft(value)) {
				 return ncards==-1? true:  this.cards.length ===ncards;
			 }
		}
		return false;
  }
  checkSoft(value) {
		const sum = this.sumWithoutAces();
		return value==(1+sum) || value ==(10+sum);
	}
  sumWithoutAces() {
		let cards=this.cards.slice();
		let j=0;

		while(1) {
			let i=0;
			while(cards.length && cards.includes(1)) {
				if (cards[i]==1) {
					 cards.splice(i,1);
					 i=0;
				} else {
					i++;
				}
			}
			break;
		}
		return cards.reduce((prev, current)=>prev+current,0)
	}
  _sum() :any{
      if (this.length==1) {
          if (this.cards.includes(1)) {
            return 1;
          }
          return this.cards[0];
      }

      let cards=this.cards.slice()
			// treat aces as '1' for time being and then take them out by adding 10 to the sum
      if (this.cards.includes(1)) {
				/*
         const sum = this.sumWithoutAces();
				 const nAces = cards.reduce((prev, current)=> current==1?prev+1:prev, 0)
				 if (sum==6 && this.id==DEALER &&  globalVars['soft17']=='stay') return 17;
				 return [7,8,9,10,11].includes(sum) ? 10+sum+nAces: sum+nAces;
				 */
				 let sum = 0;
					this.cards.forEach( num => {
					  sum += num;
					});
					return [8,9,10,11].includes(sum)? 10+sum: sum;
      } // end of special cases for Aces
			const sum = this.cards.reduce((prev:any,current:any)=>prev+current, 0);
      this.busted = sum>21;
      return sum;
  }
};
enum PageEnum {
	  bet,
		play,
		result
};
@Component({
  selector: 'anms-online-bj',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
  animations: [
      trigger('flash', [
        transition(`* => ${FADEIN}`, [
          style({ opacity: 0 }),
          animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
        ]),

        transition(`* => ${FADEOUT}`, [
          animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
        ])
      ]),
      trigger('cardFlip', [
        state('default', style({
          transform: 'none'
        })),
        state('flipped', style({
          transform: 'rotateY(180deg)'
        })),
        transition('default => flipped', [
          animate('600ms')
        ]),
        transition('flipped => default', [
          animate('300ms')
        ])
      ])
    ]
})


export class MobileOnlineBJcomponent  implements OnDestroy, OnInit  {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('paginator') paginator: MatPaginator;
   subscriptions= [];
   selected : Selected = {decks:'single-deck', splits:'two', soft17:'Hit', hands:1};
   enabled:boolean;

   slideConfig :any= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    'showArrow': false,
    'swipeToSlide': false,
		"arrows": false

  };
  vars = globalVars;
  mobile=false;
  hands=[];
  state: 'default'| 'flipped' ='default';
  hmap = {one:1, two:2, three:3, four:4, five:5, six:6};
  hands2play=[];
  bet=[];
  cards={
    dealer:new Cards(DEALER),
    player:[],
    active:false
  };
  pressed='';
  stack=[];
  ccount: Count;
  splitsEnable=false;

	suggested = {
		play:'',
		bet:''
	};
  arbiter: Arbiter;
  turn:string;
  total=0;
  cardsDisabled=false;
  // status={won:{count:0, total:0}, loss:{count:0, total:0}, 'total-hands':0, 'winning-pcnt':'', 'losing-pcnt':''};
  columns=['hand', 'splits','cards','@','count','INS','action'];
  dataSource=[];
  resultReport: ResultReport;

  cardsImage ={one:[], two:[], three:[], four:[], five:[]};
  colCards=[];
  resultTableInit=false;
	buttons = {
		B: { name : 'BET', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false, flash: new Flash() },
		S: { name : 'SHUFFLE', color: DEFAULT_COLOR, status: IDLE, disabled: false, flashed: false, flash: new Flash() },
		R: { name : 'RESULT', color: DEFAULT_COLOR, status: IDLE, disabled: true, flashed: false, flash: new Flash()},
		P: { name : 'PLAY', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false,flash: new Flash()},
		BLJ: { name : 'BLJ', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false,flash: new Flash()},
		NULL: { name : 'BLJ', color: DEFAULT_COLOR, status: IDLE, disabled: true,  flashed: false,flash: new Flash()},

	};
  die=false;
	currentSlide=0;
	betting=[];

  resultTable :  MatTableDataSource<ResultTableIf>;
  flashed='';
  pageSizeOptions=[];
	icon='refresh';
	dealerAction='';
  speechEvent: EventEmitter<number>;
	speechRecognition: SpeechRecognitionClass;

	settingState:SettingActiveEnum=SettingActiveEnum.idle;
	settingWasActive=false;
	buttonStatus(key) {
		const k = key=='BLJ'? 'BLJ': 'NULL';
		return this.buttons[k].status;
	}
	_flashit(name: string,  color='warn'):void {
				const button = this.buttons[name];
				if (button.flashed) {
					return;
				}
				button.color=color;
				button.disabled = false;
				button.flash.flash(button);
				button.flashed = true;
				this.flashed=button;
	}
	flashit(names) {
		for (let k of names) {
			this._flashit(k);
		}
	}
	_unflash(dict:any):void {
		dict.flash.unflash();

		// if (dict.name != 'home') {
		//   dict.disabled= true;
		// }
		dict.flashed=false;
		dict.color= DEFAULT_COLOR;
	}
	unflash(key:any):void  {
		if (key=='all') {
			for (let [key, dict] of Object.entries(this.buttons)) {
				if (dict['flashed']) {
					this._unflash(dict);
				}
			}
			return;
		}
		this._unflash(this.buttons[key])
 }
 unflashPre() { this.unflash(this.flashed);}
	tooltip(key) {
		switch(key) {
			case 'B': return "BET";
			case 'S': return "SHUFFLE";
			case 'R': return "RESULT";
			case 'P': return "PLAY";
			default: return 'xx';
		}
	}



  constructor(public store: Store<any>,
              private dealerService: DealerService,
              private routerService:MobileRouterService) {
       		this.resultReport = new ResultReport();
					this.speechEvent= new EventEmitter<number>();
					this.speechRecognition= new SpeechRecognitionClass(this.speechEvent);
					this.resultTable = new MatTableDataSource<ResultTableIf>(this.resultReport.list);
    }
  istate() {
       const msg=['Online assist', `${decksMapping()}`];
			 if (globalVars['voice']) {
				 msg.push('Voice recognition is activated');
			 }
       GenericTopTopAction(this.store, msg);
       BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],  lastIcon:'settings'});
       //BottomAppleModify(this.store, { enable:['backward','lastIcon'],  disable:['forward']});
	    this.flashit(['P']);
  }
  afterChange(event:any) {
		 this.currentSlide=event.currentSlide;
	 }


  updateStatus(dealer:any, bj=false):void {
		if (bj) {
			this.resultReport.pushc(this.arbiter.bjSums(), this.bet, this.cards.player, this.cards.dealer, dealer);
		} else {
			this.resultReport.push(this.arbiter.sums, this.bet, this.arbiter.hands(), this.cards.dealer, dealer);
		}
    // if (this.resultTableInit) {
		// 	this.resultTable.data=this.resultReport.list;
		// 	return;
		// }
		this.resultTable.data=this.resultReport.list;
    this.resultTableInit=true;
    // this.resultTable = new MatTableDataSource<ResultTableIf>(this.resultReport.list);
  }
  /*
   Note :
    After the dealer is done dealing a hand make sure to clear the stack
    */
  reset() :void{
    this.cardsDisabled=false;
    this.hands=[];
		this.currentSlide=0;
    this.cards.dealer.reset();
		this.icon='refresh';
		this.dealerAction='';

  }

  hardReset() :void{
    this.reset();
    this.ccount = new Count();
		this.suggest('1 hand', 'minimum');
		this.slickModal.slickGoTo(PageEnum.bet);
  }
	suggest(hand, bet) {
		this.suggested={play: hand, bet:bet};
	}
  setBetting() :void{
    const prediction = this.ccount.prediction();
    this.betting=[];
		if (prediction.SShigh) {
			this.suggest('2 hands', 'up to the maximum');
			return;
		}
		 if (prediction.Shigh) {
			 this.suggest('2 hands', 'up to 3/4 of the maximum');
 			 return;
    }
    if (prediction.Mhigh) {
			this.suggest('1 hand', 'upto half of the maximum');
			return;
    }
		this.suggest('1 hand', 'minimum');
  }
  dealerHand(sum:any, bj=false):void {
    this.cardsDisabled=true;
     this.updateStatus(sum, bj);
     this.reset();
     this.flashit(['B','P', 'R']);
     this.setBetting();
     BottomAppleModify(this.store, {enable:['lastIcon']});
		 if (globalVars['voice']) {
			 this.speechRecognition.end();
		 }
  }
  handDecision() :any{
     return this.arbiter.decision(this.cards.dealer);
  }

  assignCount() :void{
    for (const source of this.dataSource) {
       source['count'] = this.ccount.str();
    }
  }
	assignInsurance(list) {
		let i=0;
    for (const source of this.dataSource) {
       source['INS'] = list[i];
    }
  }

	configHands() {
		this.bet=[];
	   for (const elm of globalVars['online-bet']) {
			if (elm) this.bet.push(elm);
	   }
		 this.ccount.newHand();
	  const nhands = this.bet.length;
      this.selected.hands=nhands;
      this.hands=[];
      // this.arbiter = new Arbiter(this.bet, this.speechRecognition);
			this.arbiter = new Arbiter(this.bet);

      this.dataSource=[];
      this.ccount.reduceit();
      // const label=globalVars['method']=='jack'? 'O':'0';
      for (let i=0;i!=nhands;i++) {
         this.hands.push(i);
         const cards = new Cards(i);
         this.cards.player[i]=cards;
         this.arbiter.push(new Decision(i,cards, this.ccount, this.selected.splits, this.selected.decks));
         this.dataSource.push({hand:`hand-${i+1}`,splits:0, cards:'?',count:this.ccount.str(), '@':0, action:'?'})
      }
      this.dataSource.push({hand:`dealer`,splits:0, cards:'?', '@':0, count:this.ccount.str(), action:'?'})
      // this.topAnimate=true;
      // setTimeout(() => {
      //   this.model='play';
      //   this.topAnimate=false;
      // }, 500);
	}
	bclicked(value) {
		switch(value) {
			case 'B': {
				this.slickModal.slickGoTo(PageEnum.bet);
				this.unflash('B');
				break;
			}case 'P' :{
					this.configHands();
					this.slickModal.slickGoTo(PageEnum.play);
					 BottomAppleModify(this.store, {disable:['lastIcon']});
					this.unflash('all');
					if (globalVars['voice']) {
						this.speechRecognition.start(globalVars['online-bet'].length*2+1);
					}
					if (this.state != 'default') this.flipit();
					break;
			}case 'R' : {
				this.slickModal.slickGoTo(PageEnum.result);
				this.unflash('R');
				if (this.state == 'default') this.flipit();
				break;
			}case 'S': {
				this.ccount = new Count();
				break;
			} case 'BLJ': {
				//hand ended
				this.cards.dealer.push(10);
				this.ccount.push(10);
				this.dealerHand(21, true);
				this.unflash('BLJ');
				this.buttons.BLJ.disabled=true;
				break;
			} default : { // refresh case
				this.ccount.backtrack();
				this.buttons.BLJ.disabled=true;
				this.cards.dealer.reset();
				this.configHands();
				break;
			}
		}
	}
  join(cards) {
		const list=[];
		for (let c of cards) {
			if (c==1) { list.push('A'); continue;}
			list.push(c);
		}
	  return list.join();
  }
  clicked(key:any, value:any ):void {
    // event contains {type:['add', 'minus'], value:[5,25,100,500,1k,5k,25k,100k]}
    // betN carries info on which bet-hand
    // console.log(`key:${key}, value:${value}`)
    switch(key) {
     case 'card': {
        if (value=='Shuffle') {
          this.hardReset();
          break;
        }
        const turn=this.arbiter.turn();
        this.turn=`${turn}`;
        this.buttons.BLJ.disabled=false;
        this.stack.push(turn);
        this.ccount.push(value==1?10:value);
        this.assignCount();
        if (turn===DEALER) { //dealer
          if (this.arbiter.recycle()) {
            this.cardsDisabled=true;
            setTimeout(() => {
              this.turn='0';
              this.drive_decision();
            }, 800);
          }
          this.cards.dealer.push(value);
          const source=this.dataSource.slice(-1)[0];
					const sum = this.cards.dealer.sum;
          source['cards']=this.join(this.cards.dealer.cards);
          source['@']=sum;
					if (this.arbiter.dealerFirstStage()) { return ;}
					if (this.cards.dealer.checkBJ()) { // flash the blackjack button
							this.flashit(['BLJ']);
							this.icon='';
							this.dealerAction='BLJ';
							this.assignInsurance(this.arbiter.insurance(this.cards.dealer));
							return;
					}

          if (this.cards.dealer.isSoftOfValue(21,2)) {
            this.dealerHand(21);
             return;
          }
          if (sum > 16) {
             this.dealerHand(sum);
             return;
          }
          return;
        }
				this.assignInsurance(['-','-','-','-']);
				this.icon='refresh';
				this.dealerAction='';
				if (this.buttons.BLJ.flashed) {
						this.unflash('BLJ');
						this.buttons.BLJ.disabled=true;
				}
        this.cards.player[turn].push(value);
        const source=this.dataSource[+this.turn];
        source['cards']=this.join(this.cards.player[turn].cards);
        source['@']=this.cards.player[turn].sum;
        if (this.arbiter.active() ) {
          this.drive_decision();
        }
        return;
      }
    }
  }
  assign(turn:any, key: string, value:any) {
    const elem=this.dataSource[turn];
    elem[key]=value;
  }


  drive_decision(turn=null) :void{
    this.turn =turn? turn :  `${this.arbiter.current()}`;
    this.cardsDisabled=false;
    let decision = this.arbiter.decision(this.cards.player[this.turn]);
		alert(`this.turn:${this.turn}, decision:${decision}`);

		if (globalVars['voice']) {
			this.speechRecognition.talk(decision);
		}
    this.assign(this.turn,'action', decision );
    const partOfSplit = this.arbiter.partOfSplit;
    if (partOfSplit) {
       this.assign(this.turn,'splits', partOfSplit );
    }
    let timeout=1000;
    const stack:Array<DELAY_DECISION_IF>=[];
    if (this.arbiter.allbusted()) {
      setTimeout(() => {
        this.dealerHand(21);
      }, 600);
       return;
    }
		let k=0;
    while(/BUSTED|STAND|INS/.exec(decision)) {
      this.cardsDisabled=true;
      const next = this.arbiter.next();
      let interval=null;
			if (k++==4) {
				alert(`exiting reaching max value 10`); break;
			}
      if (next) {
        this.turn = `${this.arbiter.current()}`;
        // decision = this.arbiter.decision(this.cards.dealer);
				 decision = this.arbiter.decision(this.cards.player[this.turn]);
  alert(`inside loop::turn:${this.turn}, decision:${decision}`)
      	this.splitsEnable = decision.partOfSplit? true: false;
        // console.log(`next::turn:${this.turn}, decision:${decision}`);
        stack.push({turn: this.turn, value:decision});

        setTimeout(() => {
            const decision = stack.shift();
            this.assign(decision.turn, 'action',decision.value);
            if (!decision.value.includes('INS')) {
              this.cardsDisabled=false;
            } else if (this.arbiter.allbusted()) {
                setTimeout(() => {
                  this.dealerHand(21);
                }, 600);
                return;
            }

          }, timeout);
        timeout+=1500;
      } else {
        // setTimeout(() => {
          this.cardsDisabled=false;
          this.turn='100';
					if (globalVars['voice']) {
						this.speechRecognition.start(100);
					}
          if (decision.includes('INS')) {
            // this.slickModal.slickNext();
          }
        // }, timeout);


        break;
      }

    }
    alert(`exiting the decision`)
  }
	getImage(num, rank, dims) {
		return `/assets/cards/${num}${rank}_${dims}.png`;
	}
	pushCards(list, cards) {
		 let j=0;
		 	const dims = globalVars['image'].dims;
			for (const card of cards) {
				const rank=j++%2?'H':'S';
				list.push({img:this.getImage(card['card'], rank, dims), count:card['count']});
			}
	}


  ngOnInit():void {
    BottomResetMenuPressed(this.store);
		this.suggest( '1 hand','minimum');
		//
    this.istate();
    this.pageSizeOptions=globalVars['mobile']?[3,4]: [5,10];
		// this.pageSizeOptions=globalVars['mobile']?[3]: [1,3];

    // form cards
    let j=0;
    this.pushCards(this.cardsImage.one, [{card:'A', count:1}, {card:'6', count:6},{card:'J', count:10}]);
    this.pushCards(this.cardsImage.two, [{card:'2', count:2}, {card:'7', count:7},{card:'Q', count:10}]);
    this.pushCards(this.cardsImage.three, [{card:'3', count:3}, {card:'8', count:8},{card:'K', count:10}]);
    this.pushCards(this.cardsImage.four, [{card:'4', count:4}, {card:'9', count:9}]);
    this.pushCards(this.cardsImage.five, [{card:'5', count:5}, {card:'10', count:10}]);

    // switch(maxCardsPerCol()) {
    //   case 6: {this.colCards=[6,6,1]; break;}
    //   default: {this.colCards=[7,6]; break;}
    // }
		this.colCards=[7,6];

    this.ccount = new Count();

       this.subscriptions.push(
           this.store
             .select('mobileTopBottom')
             .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
             .subscribe((data: KeyPressedDm) => {
							   if (data.target=='parent') {
									  this.die=true;
										return;
								 }
                 switch(data.key) {
									 case 'home' : {
											 this.die=true;
											 setTimeout(() => {
												 this.routerService.navigate('mobile/home');
											 }, 200);
										 break;
									 }
                   case 'backward' : {
                      if(this.settingState==SettingActiveEnum.active) { return; }
                          this.die=true;
                          setTimeout(() => {
                            this.routerService.navigate('mobile/train');
                          }, 200);
                        break;
                      } case 'settings': {
                          // this.istate();
													globalVars['setting'] = {
													 func:globalVars['free']? GenericSlideConceptTrain: GenericSlideOnlineTrain,
													 route:'online'
												 };
												 this.routerService.navigate('mobile/setting-train');
                          break;
                      }
                 }
                          })
         );
         this.subscriptions.push(
               this.store
                 .select('genericReducer')
                 .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                 .subscribe((data: GenericDataUnit) => {
                   if (data.action==GenericDataUnitEnum.die) {
                     this.die=true;
                     return;
                   }
                   if (data.action==GenericDataUnitEnum.settingDone) {
					            	 this.istate();
				                 // this.settingState=SettingActiveEnum.active;
				                 // this.flipit();
					            	 // this.settingWasActive=false;
                   }

                 }
               )
          );
					this.speechEvent.subscribe((card: number) => {
						this.clicked('card', card)
					});

      }

   ngOnDestroy():void {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
   flipit() {
   	  this.state= this.state=='default'? 'flipped': 'default';
   }
	 getOptions() {
		 return globalVars['mobile']?[3,4]: [5,7,10];
	 }
   ngAfterViewInit() {
     this.resultTable.paginator = this.paginator;
  }

}
