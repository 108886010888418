import { Component, OnInit, ViewChild} from '@angular/core';
import {globalVars, imagesAttr} from '../../services/global.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {GenericTopTopAction} from '../store/generic.action.reducer';
// import {trigger,state, style,transition,animate, query, keyframes, stagger} from '@angular/animations';
import {  map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { CommonListAnimations} from '../shared/animations/mobile-animations';

@Component({
  selector: 'anms-mobile-cards-struct',
  templateUrl: './cards.component.html',
  animations: [
  CommonListAnimations.blist,
  CommonListAnimations.sdrop
   ]
})
export class CardStructComponent {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  images={
    low:[
    '/assets/cards/2C',
    '/assets/cards/3D',
    '/assets/cards/4H',
    '/assets/cards/5S',
    '/assets/cards/6C'
   ],
   high:['/assets/cards/10C',
         '/assets/cards/JD',
         '/assets/cards/QH',
         '/assets/cards/KS',
         '/assets/cards/AC'
       ],

   ignored:['/assets/cards/7C',
               '/assets/cards/8D',
               '/assets/cards/9S'],
       };
  image={width:0, height:0};
  constructor(private store:Store<any>) {}

  roundNumber(n1, n2) {
    return (n1/n2).toFixed(2);
  }


  gvars = globalVars;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };


 top_msg = {
   0: ['13 unique cards', 'Repeated 4 times in single-deck', 'Repeated 8 times in double-deck', 'Repeated 24 times in six-deck' ],
   1: ["High-cards:", 'Consider 10, Jack, Queen, King', " As a '10'"],
   2: ['Low-cards:', 'Consider 2, 3, 4, 5', "As a '4'"],
   3: ['Class of low and high cards', '8 our of 13 considered', 'Not complete'],
   4: ['include Ace and 6 to the mix', 'Ace into high-cards', '6 into low-cards'],
   5: ['ignored for simplicity', 'in a near feature we are going to consider them']

 };
  dropLabel=false;
  numbers=[0,1,2,3,4];
  inumbers=[0,1,2];
  hwidth=0;
  cards={};
  page = -1;
  showCards={low:false, high:false, ignored:false};
  subscription:any;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    const image=globalVars['image'];
    for (const key in this.images) {
            this.images[key] = this.images[key].map(img=> `${img}${image.dims}.png`);
    }
    let device='midDevice';
    if (globalVars['mobileDevice']) device="mobile";
    else if (globalVars['desktop']) device="desktop";
    this.cards=imagesAttr[device]['card'];
    this.hwidth = Math.floor(this.cards['width']/2)-10;
    GenericTopTopAction(this.store, this.top_msg[0]);
    this.subscription =
          this.store
            .select('genericReducer')
            .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
            .subscribe((data: GenericDataUnit) => {
              if (data.action==GenericDataUnitEnum.lowUpArrow) {
                this.clicked();
              }
            }
          );
  }
  // store.dispatch( new GenericAction ({mode:'bet',action: GenericDataUnitEnum.lowUpArrow,typeof:'GenericDataUnit'}));
  fmargin() {return this.page? 20: 260;}
  show() {
    this.showCards['low']=true;
    setTimeout(() => {
      this.showCards['high']=true;
    }, 600);
    setTimeout(() => {
      this.showCards['ignored']=true;
    }, 1200);
  }
  clicked() {
    switch(++this.page) {
      case 0 : {this.show(); break;}
      case 1 : {this.dropLabel=true; break;}
      case 2 : {this.images['ignored']=[]; this.inumbers=[]; break;}

    }
}

}
