
    <div class="flex">
          <ng-container *ngFor="let button of qbuttons.buttons;let i=index">
                  <button mat-fab   (click)="clicked(button)" style="margin-left:5px" class="toolbar-scale numbers-nav-primary"> {{button}}</button>
          </ng-container>
   </div>
  <div class="flex"  style="margin-top:20px;" *ngIf="lesson" >
        <button mat-flat-button  (click)="clicked('dbackward')"
          [disabled]="false" [color]="'primary'" class="margin-left-c-buttons">  <<- </button>

          <button mat-flat-button  (click)="clicked('cards')"
             [disabled]="control.cards"  [color]="'warn'" class="margin-left-c-buttons">  cards  </button>

          <button mat-flat-button  (click)="clicked('speed')"
                   [disabled]="control.speed" [color]="'warn'"  class="margin-left-c-buttons">  speed  </button>

           <button mat-flat-button  (click)="clicked('speed')"
                    [disabled]="control.speed" [color]="'primary'"  class="margin-left-c-buttons">  continuous  </button>
  </div>
