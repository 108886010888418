
export enum GenericDataUnitEnum {
   reset,
   config_enable,
   config_disable,
   pause,
   stop,
   greset,
   msg,
   disableSetting,
   settingDone,
   gotoPrev,
   gotoNext,
   die,
   toptopEnabled,
   toptopDisabled,
   trainSlideSetting,
   conceptSlideSetting,
   singleCard,
   singleSpeed,
   restoreFromCach,
   addCardCount,
   timer,
   timerReset,
   timerThumbReset,
   starttimer,
   stoptimer,
   uptimer,
   downtimer,
   removeCardCount,
   disable,
   dieSlave,
   updateRule,
   enable,
   strength,
   weak,
   medium,
   strong,
   lowUpArrow,
   testTimerUpdate,
   elapsed,
   incrHands
};
export interface GenericDataUnit {
   typeof:string;
   action:any;
   id?:string;
   msg?:Array<string>,
   mode?:string,
   image?:string,
   value?:any


};

export interface FlipDataUnit {
   typeof:string;
   action:{};
   id:number;
};
