
  <div class="top">
    <div class="column">
           <p> Enter your bets:</p>
            <div  class="row" style="margin-left:40px">
                <button mat-raised-button   [disabled]="false" [color]="'primary'" (click)="clicked(back)" [matTooltip]="back=='refresh'? 'reset':'previous-hand'" class="dscale"><mat-icon> {{back}} </mat-icon> </button>
                 <div class="hand" style="margin-top:5px"> {{hand}} hand:${{price}} </div>
                 <button mat-raised-button [color]="'primary'" [disabled]="disabled.forward" (click)="clicked('forward')"  class="dscale" style="margin-left:14px"><mat-icon> arrow_forward </mat-icon> </button>
            </div>
            <div style="margin-left:60px; margin-top:5px" *ngIf="gvars['mobileDevice'] else others">
                 <div class="row">
                      <button  mat-mini-fab  *ngFor="let key of numbers[1]" [@flash]="flashes[key].status" [color]="'warn'" class=" imargins" (click)="clicked(key)"> {{key}}</button>
                      <button  mat-mini-fab  [@flash]="flashes['+'].status" [color]="'primary'" class=" imargins"  (click)="clicked('add')"><mat-icon>add</mat-icon></button>
                  </div>
                  <div class="row" style="margin-top:5px">
                       <button  mat-mini-fab *ngFor="let key of numbers[2]" [@flash]="flashes[key].status" [color]="'warn'" class=" imargins" (click)="clicked(key)"> {{key}}</button>
                       <button  mat-mini-fab  [@flash]="flashes['-'].status" [color]="'primary'" class=" imargins"   (click)="clicked('minus')"><span style="font-weight:900;font-size:40px">-</span></button>
                   </div>
            </div>
            <ng-template #others>
                  <div style="margin-left:60px; margin-top:5px">
                       <div class="row">
                            <button  mat-fab  *ngFor="let key of numbers[1]" [@flash]="flashes[key].status" [color]="'warn'" class=" imargins" (click)="clicked(key)"> {{key}}</button>
                            <button  mat-fab  [@flash]="flashes['+'].status" [color]="'primary'" class=" imargins"  (click)="clicked('add')"><mat-icon>add</mat-icon></button>
                        </div>
                        <div class="row" style="margin-top:5px">
                             <button  mat-fab *ngFor="let key of numbers[2]" [@flash]="flashes[key].status" [color]="'warn'" class=" imargins" (click)="clicked(key)"> {{key}}</button>
                             <button  mat-fab  [@flash]="flashes['-'].status" [color]="'primary'" class=" imargins"   (click)="clicked('minus')"><span style="font-weight:900;font-size:40px">-</span></button>
                         </div>
                  </div>
            </ng-template>
            <button mat-flat-button  (click)="audioEnable=audioEnable?false: true" style="margin-top:40px; max-width:350px; heigth:350px"
              [disabled]="false" [color]="'primary'" > Audio <mat-icon> play_circle</mat-icon></button>

            <div style="width:25%;margin-left:50px; margin-top:20px" *ngIf="audioEnable">
                  <ngx-audio-player [autoPlay]="false" muted="muted" style="background:yellow; !important"

                      [playlist]="mssapPlaylist"
                      [disablePositionSlider]="false"
                      [displayRepeatControls]="true"
                      [displayVolumeControls]="true"
                      [displayVolumeSlider]="true"

                      [displayTitle]="'adding-count'"

                      (trackEnded)="onEnded($event)">

                 </ngx-audio-player>
             </div>
          </div>
    </div>
    <!-- [disabled]="false" [color]="'primary'" class="scale-p8"> Audio <mat-icon> play_circle</mat-icon></button> -->

  <!-- <button mat-raised-button   [disabled]="false" [color]="'primary'" (click)="clicked(back)" [matTooltip]="back=='refresh'? 'reset':'previous-hand'" class="dscale "><mat-icon> {{back}} </mat-icon> </button> -->
