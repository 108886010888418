<div style="margin-left:{{gvars['center-width']}}px; height:100%; position: relative"  *ngIf="!die" >
                  <anms-thumbs-timer  #thumbs_timer style="margin-left:100px">   </anms-thumbs-timer>
                  <mobile-cards-display style="margin-top:{{marginTop}}" (event)="eventHandler($event)" ></mobile-cards-display>
                  <ng-container *ngIf="showNumbers else showArrow">
                     <anms-bottom-numbers (thumbsEvent)="tevent($event)"
                     style="position: absolute; left:{{number_panel.margin_left}}px; top:{{number_panel.margin_top}}px"> </anms-bottom-numbers>
                  </ng-container>
                  <ng-template #showArrow>
                            <div class="flex-row"  style="margin-left:10%; margin-top:20px">
                                   <button mat-flat-button  style='margin-left:5px' (click)="clicked()"
                                        [disabled]="false" [color]="'primary'" > <mat-icon > skip_next </mat-icon></button>
                            </div>
                 </ng-template>
</div>
