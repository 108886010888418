 <!-- <div class="margin-side-20 slick-wrapper"> -->
  <div style="margin-left:{{vars['center-width']}}px;margin-top:50px"  *ngIf="!die">

              <ngx-slick-carousel class="carousel " #slickModal="slick-carousel"  [config]="slideConfig" (afterChange)="afterChange($event)" >
                       <div ngxSlickItem *ngFor="let stmtList of statements[vars['ndecks']]" class="slide">
                            <p> {{extraMsg}} </p>
                            <p> Likelihood is::</p>

                             <ng-container   *ngFor="let stmt of stmtList">
                                <Lp text="{{stmt.content}}"> </Lp>
                             </ng-container>
                       </div>
             </ngx-slick-carousel>
            <div class="flex-row"  style="margin-left:30px; margin-top:20px" *ngIf="settingCurrentlyActive==false">


                    <button mat-flat-button  (click)="clicked('backward')"
                      [disabled]="disabled.backward" [color]="'warn'" class="scale-p9 margin-left-c-buttons"> <mat-icon> skip_previous</mat-icon></button>

                   <button mat-flat-button  style='margin-left:5px' (click)="clicked('forward')"
                        [disabled]="disabled.forward" [color]="'warn'"  class="scale-p9 margin-left-c-buttons"> <mat-icon > skip_next </mat-icon></button>
            </div>
</div>

<!-- <div style="margin-left:{{vars['center-width']}}px; margin-top:30px"  *ngIf="!die"> -->
