import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService,globalVars,getMethodDesc } from '../../services/global.service';

import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import {MobileSendKeyRoute,  BottomResetMenuPressed,BottomAppleModify} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction, GenericSlideCountTrain,GenericSlideConceptTrain, GenericSettingRestoreFromCach,GenericSettingTopTopDisabled} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
enum SettingActiveEnum {
	active,
	idle
};
@Component({
  selector: 'anms-mobile-train',
  templateUrl: './top.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.manim,
    CommonListAnimations.flip,
    // CommonListAnimations.right2leftYanim

  ]
})

export class MobileTrainTopComponent implements OnDestroy, OnInit  {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  routes={};
  buttons=[];
  subscriptions=[];
  mobile=false;

  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': true,
 };
 mouseClass={
	 'Card-counting': 'enter',
	 Training:'leave',
	 Prediction:'leave',
	 Rules:'leave',
	 Betting:'leave',
	 Certification: 'leave',
	 License:'leave',
	 Play:'leave',
 };
 chosen="Card-counting";
 mouse(key) {
	 this.mouseClass[key]='enter';
	 this.mouseClass[this.chosen]='leave';
	 this.chosen=key;
 }
 settingState:SettingActiveEnum=SettingActiveEnum.idle;
 settingWasActive=false;
  gvars=globalVars;
	columns=['Introduction', 'Practice'];
	source=[
		{Introduction:'Card-counting', Practice:'Training'},
		{Introduction:'Prediction', Practice:'License'},
		{Introduction:'Rules', Practice:'Play'},
		{Introduction:'Betting', Practice:'Play'},

	];

  constructor(public store: Store<any>, private routeService:MobileRouterService) {
      // super(store,['Concept', 'Count', 'runnigcount', 'Rules', 'Betting', 'Playing']);

    }
  // mouse(key) {
	// 	this.mouseClass[key]='enter';
	// 	this.mouseClass[this.chosen]='leave';
	// 	this.chosen=key;
	// }
  istate() { // initial state
		const training=globalVars['method']=="single"? "Single deck": "Single, double and six decks";
    GenericTopTopAction(this.store, [getMethodDesc(''),training, 'Follow in order'], 'tigger');
    BottomAppleModify(this.store, {switch:'apple', disable:['forward', 'backward'], enable:['lastIcon'], lastIcon:'settings'});
  }
  ngOnInit() :void{
      this.mobile=globalVars['mobileDevice'];
      globalVars['page']='TrainTopComponent';

      BottomResetMenuPressed(this.store);
      this.istate();
      this.settingState=SettingActiveEnum.idle;

      // if (this.routeService.refreshed()) {
      //   this.routeService.navigate('mobile/top');
      // }
     this.routes = {
       'Card-counting': 'mobile/concept-top',
       Training:'mobile/count-top',
			 Certification: 'mobile/License',
			 Play:'mobile/play',
       Prediction:'mobile/count-classification',
       Rules:'mobile/rules',
       Betting:'mobile/bets',

     };
		 this.buttons = Object.keys(this.routes);
    this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
            .subscribe((data: KeyPressedDm) => {
							 if (data.key=='home') {
								  this.routeService.navigate('mobile/home');
							  return;
							 }

               if (data.key=='settings') {
										 globalVars['setting'] = {
											func:GenericSlideCountTrain,
											route:'mobile/train'
										};
										this.routeService.navigate('mobile/setting-train');

								}

            })
        );


    }

   ngOnDestroy() :void{
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

   clicked(button:any):void {
		   button=button=='License'? 'Certificate': button;
		   this.mouse(button);
       this.routeService.navigate( this.routes[button]);

   }

}
