


  <div style="margin-left:{{gvars['center-width']}}px; width:100%; margin-top:30px;" *ngIf="topEnabled">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style='width:80%' (afterChange)="afterChange($event)" >
              <div ngxSlickItem class="slide">
                  <div class="card-list-width" >
                      <p class="cards-header"> Five high cards: </p>

                      <div class="flex-row level-top" [@cards]="images.length" >
                             <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                      </div>
                  </div>
              </div>
              <div ngxSlickItem class="slide">
                <!-- <p class="landing-warn-bg"> Low cards: </p> -->
                <div class="card-list-width" >

                      <p  class="cards-header"> Five low cards: </p>

                      <div class="flex-row level-top" [@cards]="images.length"  >
                             <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                      </div>
                </div>
              </div>
              <div ngxSlickItem class="slide">
                <!-- <p class="landing-accent-bg" > Ignore cards: </p> -->
                <div class="card-list-width" >

                    <p  class="cards-header"> Three ignore cards: </p>

                    <div class="flex-row level-top" [@cards]="images.length" >
                           <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                    </div>
                </div>
              </div>
    </ngx-slick-carousel>

    <div style="margin-top:50px; margin-left:50px;">
              <div class="flex-row"  >
                <button mat-flat-button  (click)="clicked('dbackward')"
                  [disabled]="false" [color]="'primary'" class="scale-p8">  <<- </button>

                      <button mat-flat-button  (click)="clicked('backward')"
                        [disabled]="disabled.previous" [color]="'warn'" class="scale-p8 margin-left-c-buttons"> <mat-icon> skip_previous</mat-icon></button>

                     <button mat-flat-button   (click)="clicked('forward')"
                          [disabled]="disabled.next" [color]="'warn'"  class="scale-p8 margin-left-c-buttons "> <mat-icon > skip_next </mat-icon></button>
              </div>
        </div>
  </div>
