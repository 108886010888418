
<div  style="margin-left:{{gvars['center-width']}}px">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  style='width:80%' (afterChange)="afterChange($event)">
      <div ngxSlickItem class="slide">
          <div class="flex-column" style="margin-top:30px">
               <h2> Abbreviation </h2>

                          <p> sp:: split cards </p>
                          <p> s :: stand the hand</p>
                          <p>h :: hit the hand </p>
                          <p> d :: double down the hand </p>
                          <p> i :: take insurance on the hand </p>

                 <div class="flex-column" >
                      <button mat-flat-button   (click)="clicked('Book')" class="rules-buttons" style="margin-top:20px; margin_left:40px"
                         [color]="'warn'"> Decision based on the Book</button>

                         <button mat-flat-button   (click)="clicked('low cards with high-likelihood')" class="rules-buttons" style="margin-top:20px; margin_left:40px"
                            [color]="'primary'"> Decision based on low-cards prediction</button>

                          <button mat-flat-button   (click)="clicked('high cards with high-likelihood')" class="rules-buttons" style="margin-top:20px; margin_left:40px"
                                   [color]="'accent'"> Decision based on high-cards prediction</button>
                 </div>
          </div>
      </div>
      <div ngxSlickItem class="slide">
                <mat-tab-group dynamicHeight (selectedTabChange)="tabClicked($event)" style="margin-top:25px" [(selectedIndex)]="selectedTab">
                    <mat-tab *ngFor="let cards of ['Soft','Split', 'Hard']" [label]="cards" >
                      <div  class="mat-elevation-z8 font" >
                              <mat-table #table [dataSource]="dtable" >
                                    <ng-container  *ngFor="let col of columns" [matColumnDef]="col">
                                      <mat-header-cell *matHeaderCellDef class="{{gcls(col)}}"> <span class="rule-cell"> {{col}} </span>  </mat-header-cell>
                                      <!-- <mat-cell *matCellDef="let element" class="{{gcls(col)}}"><span  class="rule-cell"> {{element[col]}} </span> </mat-cell> -->
                                      <mat-cell *matCellDef="let element" class="{{gcls(col)}}"> <span class="rule-cell">{{element[col]}} </span> </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                              </mat-table>
                      </div>
                    </mat-tab>
                </mat-tab-group>
                <mat-paginator #paginator  [pageSizeOptions]="getOptions()" showFirstLastButtons  style="margin-right:{{paginatorMargin}}"></mat-paginator>
      </div>
    </ngx-slick-carousel>
</div>
<!-- <mat-header-cell *matHeaderCellDef class="{{classes[col]}}"> {{col}} </mat-header-cell>
 <div class="flex-row-space-between">
</div>

<mat-cell *matCellDef="let element" class="{{classes[col]}}"> {{element[col]}} </mat-cell> -->
