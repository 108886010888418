
export const CARDS_DRAWN = 'drawn';

export const ENABLE_DISPLAY = 'enable-display';
export const GET_DECISION = 'get-decision';
export const END_OF_HAND = 'end-of-hand';
export const RESET = 'reset';
export const INSURANCE = 'Insurance';
export const INSURANCE_YES = 'Insurance-yes';
export const INSURANCE_NO = 'Insurance-no';
export const SPLIT = 'split';
export const STAND = 'stand';
export const DOUBLE = 'double';
export const HIT = 'hit';
export const DEALER_BJACK = 'dealer-black-jack';
export const DEALER = 'Dealer';
export const DEALER_FACEUP = 'dealer-face-up';
export const DROP='DROP';
export const PLAYER = 'Player';
export const NONE = 'None';
export const DELETE_SECOND = 'delete-2nd-card';
export const CHANGE_BORDER = 'change_border';
export const REPLACE_BLANK = 'replace-blank';
export const REPLACE = 'REPLACE';
export const IGNORE = 'ignore-directive';
export const SECOND_SPEED = 700;
export const MAX_NUMBER_OF_HANDS = 3 ;
export const DISPLAY_ARBITER = 'display-arbiter-content';
export const DISABLE_EXTENDED = 'disable-extended-display';
export const ENABLE_EXTENDED = 'enable-extended-display';
export const DROP_BEFORE_DRAW = 'drop_before_draw';
export const DISPLAY_BADGE = 'displayBadge';
export const DEALER_DISPLAY_ID = MAX_NUMBER_OF_HANDS;
export const EXTENDED_DISPLAY_ID = 4;
export const DEALER_DISPLAY_ID_STR = `${DEALER_DISPLAY_ID}`;
export const UPDATE_COUNT = 'Update_count_meter';

//import * as lodash from 'lodash';

import {lpull, lrange,lsum} from '../services/global.service';
import {book_rule_get} from '../mobile/services/rule.service';

export interface CardDm  {
    num: number;
    ssuit: string;
    suit: string;
    rank: number;
};

export const FAKE_CARD = { num: 10, ssuit: '', suit: '', rank: 10 };

type cardList = CardDm [];

export class Hand {
    id: number;
    bet: Array<number>;
    type: string; // player or dealer
    cards: cardList;
    result: {status: string, net: number} ;
    insurance: boolean;
    aceSplit: boolean;
    insuranceOky = true;
    handId : string;
    net: number;
    ndecks : number;
    stayOnSoft17 : boolean;
    dlist: Array<string>;
    maxSplit: number;
    splitCount: number;
    _ended: false;


    constructor(id: number, bet: number, type: string, ndecks, stayOnSoft17, maxSplit) {
        this.id = id;
        this.bet = [bet];
        this.type = type;
        this.cards = [];
        this.result = {status: 'None', net: 0};
        this.insurance = false;
        this.net = 0;
        this.dlist=[];
        this.ndecks = ndecks ;
        this.stayOnSoft17 = stayOnSoft17;
        this.splitCount = 0;
        this.maxSplit = maxSplit;
    }
    insuranceSet():any { return this.insuranceOky ;}
    insuranceReset():void { this.insuranceOky = false ;}
    sid(id: string) :void{ this.handId = id;}
    gid() :any { return this.handId;}

    push(card: CardDm):void {
        this.cards.push(card);
    }
    pushDecision(decision: string) :void { this.dlist.push(decision);}
    cardValue(card: CardDm) :any{
        return card.num > 10 ? 10 : card.num;
    }
    valuePerCard(index: number):any {
        const hand = this.cards[index].num;
        return hand > 10 ? 10 : hand;
    }
    split() :any{
        const splitAllowed = this.splitCount < this.maxSplit ;
        let split =  splitAllowed && this.cards.length === 2 && (this.cards[0].num === this.cards[1].num);

        if ( split && this.isItAce(0) && this.isItAce(1)) {
           if (this.id >= 16) {
              return this.ndecks > 2;
           }
        }
        return split;
    }
    isItAceSplit():any {
        this.aceSplit =  this.length() === 2 && this.isItAce(0) && this.isItAce(1);
        return this.aceSplit;
    }
    dExists(decision: string):any {
        return this.dlist.includes(decision);
    }
    wasItAceSplit():any {
       const split = this.dExists('Split');
       return split ? this.isItAce(0) : false;
    }
    value (card: number):any {
        return card < 10 ? card : 10;
    }
    tookInsurance() { this.insurance = true; }
    isItAce(index: number):any {
      return this.cards[index].num === 1 ;
    }
    aceFirstCard():any {
      return this.isItAce(0);

    }
    dealerPossibleBjack():any {
      return this.cards.length === 2 && this.isItAce(0) ;
    }
    aceSplitDone():any {
      if (this.isItAce(0)) {
           if (this.isItAce(1)) {
              return this.ndecks > 2 ? false : true;
           }
        return true;
      }
      return false;
    }
    soft():any {
       return this.aceExists() != -1;
    }
    strictSoft():any {
       return this.cards.length === 2 && this.soft();
    }
    strictSoftWithValueOf(value: number):any {
       return this.strictSoft() && this.sum() === value;
    }
    softSum(value) :any{
        return this.soft() && this.sum()==value;
    }
    softSum2(value) :any{
        return this.cards.length === 2 && this.softSum(value);
    }
    soft17() :any{ return this.softSum(7);}
    soft18() :any{ return this.softSum(8);}
    soft17_2c() :void { this.softSum2(7);}
    soft18_2c() :void { this.softSum2(8);}

    cardsValueList(): string {
      const rval = [];
      for (const card of this.cards) {
          switch(card.num) {
             case 1 : { rval.push('Ace'); break;}
             case 11 : { rval.push('Jack'); break;}
             case 12 : { rval.push('Queen'); break;}
             case 13 : { rval.push('King'); break;}
             default : { rval.push(`${card.num}`); break;}
          }
      }
       return rval.join(',');

    }
    sum(upto=-1):any {
        upto = (upto===-1) ? this.cards.length : upto;
        const acePos = this.aceExists();
        const cards = this.cards.map(value => this.cardValue(value));
        //let sum = lodash.sum(this.cards.slice(0,upto));
        let sum = lsum(cards.slice(0,upto));
        if (acePos !== -1) { // aceExists
           const list=[8,9,10,11];
           if (this.wasItAceSplit()) {
                  list.push(7);
           }
           if (list.includes(sum)) {
              sum += 10;
           }
        }
        return sum;
    }

    adjust(key):void  {
      const bet =  this.bet[0];
     //print(`adjust-before:key:${key}, ${JSON.stringify(this.bet)}`);
      if (key === 'Double') {
        this.bet.push(bet);
      } else if (key==='Insurance') {
        this.bet.push(bet/2*-1);
      }
     //print(`adjust-after:key:${key}, ${JSON.stringify(this.bet)}`);
    }

    length():any { return this.cards.length;}

    aceExists():any {
        let pos = -1;
        for (const card of this.cards) {
            ++pos;
            if (card.num === 1) {
               return pos;
            }
        }
        return -1;
    }
    display():void  {
        console.log(`hand-display[${this.id}]: ${JSON.stringify(this.cards)}`);
    }

    blackJack() :any{
       return this.strictSoftWithValueOf(21) && !this.dlist.includes('Split');
    }

   advanceDecisions():any {
        let sum = this.sum();
        // console.log(`decisions:sum;${sum}, id:${this.id}`);
        const decision = this.double() ? [DOUBLE] : [];
                const num = this.cards[0].num;
        if (this.split()) {
                if ([1,2,3].includes(num)) {
                    return decision.concat([SPLIT, HIT]);
                }
                if ([4,5].includes(num)) {
                    return decision.concat([SPLIT, DOUBLE,HIT]);
                }
                if ([6, 7, 8].includes(num)) {
                    return decision.concat([SPLIT, HIT, STAND]);
                }
                if ([9,10].includes(num)) {
                    return decision.concat([SPLIT,  STAND]);
                }
                return decision.concat([SPLIT, STAND]);
        } else if (this.soft()) {
                    if (sum > 18) {
                       return decision.concat([STAND]);
                    }
		    const _2cards=this.cards.length === 2;
		    sum-=[17,18].includes(sum) && _2cards? 10:0;
                    if ([7,8].includes(sum)) {
                      const rval= [STAND, HIT];
                      if ( _2cards ) {
                        return [...rval, DOUBLE];
                      }
                      return rval;
                    }
                    if (sum < 12 ) {
                         return  decision.concat([HIT]);
                    }
                    return sum < 17 ? decision.concat([HIT,STAND]) : decision.concat([STAND]);
        } else {
                if (sum < 12) {
                   return decision.concat([HIT]);
                }
                return sum < 17 ? [HIT,STAND] : [STAND];
        }
   }

    decisions(d1card:any) :any{
       return this.advanceDecisions();
    }
    double() :any{
        if (this.cards.length !=2) return false;
        const sum = this.sum();
        if (this.soft()) {
          return lrange(3, 9).includes(sum);
        }
        return lrange(8,12).includes(sum);
    }
    bust() :any{ return this.sum() > 21; }
    drop(index: number) :any{
        return this.cards.splice(index,1)[0];
    }
    num(index: number) :any{
           return this.cards[index].num;
    }
    calculateNet(dhand:number, length:any) :void{
       let phand = this.sum();
       let pbets = lsum(this.bet);
       this.net=0;
       if ([7,8].includes(phand) &&  this.soft()) {
         phand+=10;
       }
       if ([7,8].includes(dhand) &&  this.soft()) {
         dhand+=10;
       }
       const p_busted = phand > 21;
       const d_not_busted = dhand < 22;
       const dealerWinner = p_busted  || ((dhand>phand) && d_not_busted);
       const extra=Math.floor(pbets/2);
        // const extra = this.insurance?  pbets/2: 0
       if (this.blackJack() && this.insurance) {
          this.net = pbets; //even money
          return;
       }

       if (dealerWinner) {
             if (dhand===21 && length==2 && this.insurance  ) { // dealer bjack
                this.net = 0 ;
                return;
             }

             this.net -= pbets + (this.insurance?extra:0);
             return;
       }
       if (phand === dhand) return;
       if (this.blackJack()) {
         if (!this.insurance) pbets+=extra;
       } else if (this.insurance) {
          pbets -= extra;
       }
       this.net = pbets;
       if (isNaN(this.net)) {
alert(`caught NaN exception: bet:${JSON.stringify(this.bet)}, cards:${JSON.stringify(this.cards)}; phand:${phand}, dlist:${JSON.stringify(this.dlist)}`);
       }
    }

    setResult() :void{
        if (this.net === 0) {
             this.result = {status: 'push', net: 0};
        } else if ( this.net > 0 ) {
             this.result = {status: 'won', net: this.net};
        } else {
             this.result = {status: 'lost', net: this.net};
        }
    }


}

export type DisplayParam = {
    dealer: number, extended: number
};
export type Cntrl2SwitchSetupParam = {
    display: DisplayParam;
    hands: {};
};

class HandsParser {
   hands: {};
   keyList=null;
   dealerId: number;
   keyListLength = 0;
   net = 0;
   idList = Array.from('123456789');
   round = 0;
   constructor(hands: {}, dealerId: number) {
       this.hands = hands;
       this.keyList = lpull(Object.keys(hands), 'id');
       const hid = hands['id'];
       this.dealerId = dealerId;
       for(const hand of this.keyList) {
           if ( +hand === this.dealerId ) {
              this.hands[hand].sid(`${hid}`);
           } else {
               this.hands[hand].sid(`${hid}-${this.idList.shift()}`);
           }
       }
       this.keyListLength = this.keyList.length;
   }
   getList():any { return  this.keyList; }
   updateList(key):void {
       this.keyList.splice(this.keyList.indexOf(`${this.dealerId}`), 0, `${key}`);
       this.keyListLength++;
   }
   cardsList() :any{
      let list = [];
      const hands = this.hands;
      for (const hand of this.keyList) {
          list.push( {hand: hand, cards:hands[hand].cardsList()});
      }
      return {length: this.keyList.length, list: list};
   }
    //
    // check to see if all hands are black jack, excludes dealer hand
    //
    allBlackJack():boolean {
        for (const key of this.keyList) {
            if (+key === this.dealerId) { continue ;}
            const hand  = this.hands[key];
            if ( !hand.blackJack()) return false;
        }
        return true;
    }
    allBusted():boolean{
        for (const key of this.keyList) {
            if (+key === this.dealerId) { continue ;}
            if (this.hands[key].sum() < 22) return false;
        }
        return true;
    }

    totalHands():number {
        let total = 0;
        for (const key of this.keyList) {
            if (+key < MAX_NUMBER_OF_HANDS) {
                total++;
            }
        }
        return total;
    }
    nHands() {
	     let total = 0;
        for (const key of this.keyList) {
            if (+key == MAX_NUMBER_OF_HANDS) {
		    return total;
            }
                total++;
        }
        return total;

    }
    dealerOpencards(): boolean {
       let hands = this.nHands() ;
       for (const key of this.keyList) {
            if (+key === this.dealerId) { continue ;}
            const hand  = this.hands[key];
            hands -= hand.sum() > 21 || hand.blackJack() ? 1 : 0;
        }
        return hands ? true: false;
    }
    result(global) :any{
        let net = 0 ;
        const results = [];
        const dealerSum = this.hands[this.dealerId].sum();
        this.round++;
        let hands = 0;
        let gNet = global['hands-net'];
        const shuffleCount=global['#shuffle'];
        const round=global['#round'];
        for (const key of this.keyList) {
            if (this.dealerId === +key) { continue; }
            const hand = this.hands[key];
            const sum = hand.sum();
/*
            if (dealerSum > 21 ) { _net = 'Loss';}
            else if (sum > 21 ) { _net = 'Loss';}
            if ( sum > dealerSum ) { _net = 'Won';}
            else if (sum < dealerSum)  { _net = 'Loss';}
*/
            let _net = 'Push';
            if (hand.net) {
               _net =  hand.net > 0 ? 'Won' : 'Loss';
            }
            // console.log(`gnet:${gNet}, hands-net:${hand.net}`)
	    //console.log(`hand:${key}, sum:${sum}, hand.net:${hand.net} gnet:${gNet}, gnet:${gNet+hand.net}`);
            gNet += hand.net;
            //results.push({'#round': this.round, '#hand':++hands, bet: lsum(hand.bet), 'dealer-hand': dealerSum, 'player-hand': sum, result:_net,
            results.push({'#shuffle': shuffleCount, '#round': round, '#hand':++hands, bet: lsum(hand.bet), 'dealer-hand': dealerSum, 'player-hand': sum, result:_net,
                           net: gNet});
        }
        // global['current-net'] = gNet-global['hands-net'];
        global['hands-net']= gNet;
        return results;
    }


}

export class Cntrl2SwitchSetupDm {
 hands: {};
 list: string [];
 dtype: string;
 handsParser: HandsParser;
 cid:any;
 nhands: number;
 dealerShowingCard = 0;
    constructor(params: Cntrl2SwitchSetupParam) {
        this.hands = params.hands;
        this.dtype = 'Cntrl2SwitchSetupDm';
        //this.list = Object.keys(this.hands);
        this.handsParser = new HandsParser(this.hands, DEALER_DISPLAY_ID);
        this.nhands = Object.keys(this.hands).length-1;
    }


    newHand(source: number, dest: number):void {
        //print(`newHand source:${source} dest:${dest}`);
        const pid = dest%16; // get the parent id
        this.hands[pid].splitCount++;
        const sourceHand = this.hands[source];
        const card = sourceHand.drop(0);
        const bet  = sourceHand.bet;
        sourceHand.pushDecision('Split');
        this.hands[dest] = new Hand(dest,0, PLAYER, sourceHand.ndecks, sourceHand.stayOnSoft17, sourceHand.maxSplit);
        this.hands[dest].bet = bet.slice();
        this.hands[dest].push(card);
        this.hands[dest].dlist.push(SPLIT);
        this.hands[dest].type = this.getTitle(dest);
        const hid = sourceHand.gid().split('-') ;
        hid[hid.length-1] = `${dest}`;
        this.hands[dest].sid(hid.join('-')) ;
        this.hands[dest].insuranceReset();
        this.hands[dest].dlist= sourceHand.dlist.slice();
        this.handsParser.updateList(dest);
/*
 * check to see if split id is inserted correctly
        console.log(`the new hand the list is : ${JSON.stringify(this.handsParser.getList())}`);
        alert();
*/
    }
    cardsList(): any {
      return this.handsParser.cardsList();
    }

    getTitle(index: number): any {
        if (this.isDealer(index)) {
            return DEALER;
        }
        if (index >= 16) {
            const splitId = Math.floor(index/16);
            const baseId = index%16;
            return `sp-${splitId}`+ '$' + `(${this.hands[index].bet})`;
        }
        return `player-${index+1}` + '$' + `(${this.hands[index].bet})`;
    }
    setFirstSplitTitle(index: number):void {
        const _index = `${index}`;
        this.hands[_index].type = 'sp-0 $' + `(${this.hands[index].bet})`;
    }
    displayCards(id: number) :void{
        this.hands[id].display();
    }
    displayAllCards() :void{
        for (const hand in this.hands) {
            // console.log(`hand:${hand} has following:`);
            this.displayCards(+hand);
        }
    }
    dealerOpencards() :any{ return this.handsParser.dealerOpencards();}
    allBusted():any { return this.handsParser.allBusted();; }
    allBlackJack():any { return this.handsParser.allBlackJack(); }
    totalHands():any { return this.handsParser.totalHands(); }
    handsIndex():any { return Object.keys(this.hands);}
    isSoft(pid) {
	    return this.hands[pid].soft();
    }
    isSplit(pid) {
	    return this.hands[pid].split();
    }
    dealerAce():any {
        return this.hands[DEALER_DISPLAY_ID].cards[this.dealerShowingCard].num === 1;
    }
    dealerFacecard() {
	const num =  this.hands[DEALER_DISPLAY_ID].cards[this.dealerShowingCard].num;
        return num>10? 10: num;
    }
    dealerSum():any {
        return this.hands[DEALER_DISPLAY_ID].sum();
    }
    playerSum(pid):any {
        return this.hands[pid].sum();
    }
    playerBjack(index: string):any {

        //return +index === EXTENDED_DISPLAY_ID? false: this.hands[index].blackJack();
        return  this.hands[index].blackJack();
    }
    isDealer(id: number):any {
        return id === DEALER_DISPLAY_ID;
    }
    did():any { return DEALER_DISPLAY_ID; }
    dHand():any { // dealer hand
        return this.hands[DEALER_DISPLAY_ID];
    }
    dHandLength():any {
        return this.dHand().length;
    }
    pHandLength(id: number):any {
        return this.hands[id].length();
    }
    dealerBjack():any {
        return this.dHand().blackJack();
    }
    dealer21():any {
        return this.dHand().is21();
    }
    insuranceOky(index):any {
      return this.hands[index].insuranceSet();
    }
    dealerPossibleBjack():any { return this.dHand().dealerPossibleBjack();}
    activity(index: string , result: {}):void {
        this.hands[index].result = result;
    }
    bet(index: string) :any {
        return this.hands[index].bet;
    }
   tookInsurance(index):void { this.hands[index].tookInsurance(); }
   setcid(pid) { this.cid=pid;}
   getcid() { return this.cid;}
   getDecisions(pid: number, strategy: string) :any{
     this.cid=pid;

	   //return strategy=='book'? book_rule_get(this, pid): this.hands[`${pid}`].decisions(this.hands[DEALER_DISPLAY_ID].cards[1].num);
	   const dec= strategy=='book'? book_rule_get(this, pid): this.hands[`${pid}`].decisions(this.hands[DEALER_DISPLAY_ID].cards[1].num);
	   // if (strategy != 'book')
		 //   console.log(`pid[${pid}] dec:${dec}`);
	   return dec;
   }

    busted(index: number):any { return this.hands[`${index}`].bust(); }
    getExtendedDisplay() :any{ return EXTENDED_DISPLAY_ID;}

    collectHands():any {
      const keys = Object.keys(this.hands);
      const rval = {};
      for (const key of keys) {
          const id = +key;
          if (id >= 16) {
              const base = id & 7 ;
              if (base in rval) {
                  rval[base].push(key);
              } else {
                  rval[base] = [key];
              }
          }

      }
      return rval;
    }
    result(global) :any { return this.handsParser.result(global); }
    calculate(dvalue: number, length: number):void {
         //console.log(`calculate is called with dealer value  :${JSON.stringify(dvalue)}`);
       const handsList = this.handsParser.getList();
       for (const index of handsList)  {
           if (+index === DEALER_DISPLAY_ID) { continue; }
           this.hands[index].calculateNet(dvalue, length);
       }
    }
}

export class Cntrl2switchDecisionDm {
    action: string;
    id: number;
    data_type: string;
    constructor( id: number, action: string) {
        this.action = action;
        this.id = id;
        this.data_type = 'Cntrl2switchDecisionDm';
    }
}
export type Switch2CntrParam = {
    id: number;
     action: string;
     hands: {};
     decisions: string[];
};
export class Switch2CntrlDm {
     hands: {};
    action: string;
    id: number;
    data_type: string;
    decList: string [];
    constructor(params: Switch2CntrParam ) {
            this.hands = params.hands;
            this.action = params.action;
            this.id = params.id;
            this.data_type = 'Switch2CntrlDm';
            this.decList = params.decisions;
    }
}
export interface CardsSummary {
     sum: number;
     cards: string;
     color: string;
};
//type CardsList = Array<CardDm>;
export type CardUnion = CardDm | CardsSummary;
type CardsList = Array<CardUnion>;


export class Switch2CardDisplayDm {
    id: number;
    data_type: string;
    type: string;
    //cards:CardsList ;
    cards:any ;
 //   speedModifier: number;
    color: string;
    value: number;
    nhands: number;
    //constructor(id: number, cards: CardsList, type?: string, speedModifier?: number) {
    //constructor(id: number, cards: CardsList, type?: string, speedModifier?: number) {
    constructor(id: number, cards: any, type: string, value: number, nhands=0) {
        this.id = id;
        this.data_type = 'Switch2CardDisplayDm';
        this.type = type;
        this.cards = cards;
        this.value=value;
        this.nhands = nhands;
    }
  //  speed() { return this.speedModifier;}
}
export class CardDisplay2SwtichDm {
    id: number;
    data_type = 'CardDisplay2SwtichDm';
    action: string;
    constructor (id: number, action: string) {
        this.id = id;
        this.action = action;
    }
}
export interface MobileResultDataUnit {
  typeof: string;
  data:any;
}
