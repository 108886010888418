import { Component, ViewChild, EventEmitter, Output, OnInit, OnDestroy} from '@angular/core';
import {ThumbsComponent} from '../thumbs/component'
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import { DealerService, CardCount } from '../../../services/deck.service';

@Component({
  selector: 'anms-thumbs-timer',
  templateUrl: './component.html',
  styleUrls: [],
})

export class ThumbsTimerComponent {
  @ViewChild("thumbs") thumbs: ThumbsComponent;
  @Output() timerEvent = new EventEmitter<any>();

  count = 0;
  subscription: any;

  constructor( public dealerService: DealerService, private store: Store<any>) { }

  thumbsevent(event) {
    this.thumbs.thumbsevent(event)
  }

  tevent(event) {
    if (event=='reset') {
        this.thumbs.thumbsevent('reset');
        this.dealerService.shuffle();
        return;
    }
    this.timerEvent.emit();
  }


   ngOnDestroy() {
     this.subscription.unsubscribe();
   }

   ngOnInit() {
       this.subscription =
             this.store
               .select('genericReducer')
               .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
               .subscribe((data: GenericDataUnit) => {
                 if (data.action==GenericDataUnitEnum.incrHands) {
                   this.count += 1;
                   return;
                 }
               }

        );
}
}
