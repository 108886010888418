import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';


import {TopBottomComponent} from './top-bottom/component';
import {AboutComponent} from './static/about.component';
import {FairComponent} from './static/fair.component';
import {CardStructComponent} from './static/cards.component';
import {PredictionOverviewComponent} from './static/prediction.component';



import {MobileTrainSettingComponent} from './train/setting.train.component';
import {MobileCardClassificationComponent} from './train/card.classification.component';
import {MobileTimeLeftComponent} from './time-left/component';
import {BaseListComponent} from './shared/list/component';
import { MobileTrainTopComponent} from './train/top.component';
import {ConceptTopComponent} from './train/concept.top.component';
import {MobileAddingStrategyComponent} from './train/adding.strategy.component';
import {MobileNetcountStrategyComponent} from './train/netcount.strategy.component';
import {CountClassificationComponent} from './train/count.classification.component';
import {RulesComponent} from './train/rules.component';
import {MobileBetComponent} from './train/bets.component';
import {CountTopComponent} from './train/count.top.component';
import {MobileLearningComponent} from './train/learning/learning.component';
import {ParagraphComponent} from './train/paragraph.component';
import {UnderlineComponent} from './train/underline.component';
import {MobileCardsComponent} from './train/cards/cards.component';
import {MobileBaseCardsComponent} from './shared/cards/cards.component';
import {MobileTestingComponent} from './train/testing/testing.component';
import {MobileMathTestingComponent} from './train/net-math/math.component';
import {MobileDealerCardsComponent} from './train/cards/dealer.cards.component';
import {MobilePlayerCardsComponent} from './train/cards/player.cards.component';
import {BottomNumbersComponent} from './train/numbers/component';
import {NumbercellComponent} from './illustration/numbercell.component';
import {NumbercellsComponent} from './illustration/numbercells.component';
import {ThumbComponent} from './train/thumb/component';
import {ThumbsComponent} from './train/thumbs/component';
import {ThumbsTimerComponent} from './train/thumbs-timer/component';
import {ThumbsTimerNumberComponent} from './train/thumbs-timer-number/component';


import {MobileLicenseComponent} from './train/license/license.component';
import {MobileShuffleComponent} from './train/shuffle-notification/shuffle.component';
import {MobilePlayingBetComponent} from './train/play/bet/bet.component';
import {BetItemComponent} from './train/play/bet/betitem.component';
import {FlipCardComponent} from './flipped-card/flip.component';
import {  RouterModule } from '@angular/router';
import {MobilePlayComponent} from './train/play/play.component';
import {MobileOnlineBJcomponent} from './online/online.component';
import {TimerComponent} from './timer/timer.component';
import { MatTableModule} from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {  MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule} from '@angular/material/radio';
import {  MatInputModule } from '@angular/material/input';
import {  MatSelectModule } from '@angular/material/select';
import { MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {  MatTabsModule } from '@angular/material/tabs';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import {TextFieldModule} from '@angular/cdk/text-field';
import { FormsModule }             from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MatFormFieldModule} from '@angular/material/form-field';

import { StoreModule } from '@ngrx/store';

import {MobileRoutingModule} from './mobile-routing.module';
import {LandingPageComponent} from './landing-page-game/lpagegame.component';
import {ProxyComponent} from './proxy/proxy.component';
import {TopTopComponent} from './top-top/toptop.component';
import {OnlineBettingComponent} from './online-betting/component';


import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

const matModule = [
  CommonModule,
  MatTableModule,
  MatButtonModule,
  MatIconModule,
  MatGridListModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatTooltipModule,
  MatCardModule,
  MatMenuModule,
  MatChipsModule,
  MatToolbarModule,
  MatTabsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  TextFieldModule,
  MatListModule,
  MatInputModule,
  MatRadioModule,
  StoreModule,
  SlickCarouselModule,
  MatSlideToggleModule,
  FormsModule
];
const comps = [
  MobileOnlineBJcomponent,
  BetItemComponent,
  MobilePlayerCardsComponent,
  MobileDealerCardsComponent,
  MobilePlayingBetComponent,
  MobilePlayComponent,
  MobileShuffleComponent,
  MobileLicenseComponent,
  ParagraphComponent,
  UnderlineComponent,
  MobileMathTestingComponent,
  MobileBaseCardsComponent,
  MobileTestingComponent,
  MobileCardsComponent,
  TopBottomComponent,
  MobileTimeLeftComponent,
  AboutComponent,
  FairComponent,
  CardStructComponent,
  PredictionOverviewComponent,
  MobileBetComponent,
   MobileTrainTopComponent,
  BaseListComponent,
  MobileTrainSettingComponent,
  MobileCardClassificationComponent,
  MobileAddingStrategyComponent,
  MobileNetcountStrategyComponent,
  CountClassificationComponent,
  RulesComponent,
  CountTopComponent,
  MobileLearningComponent,
  LandingPageComponent,
  ConceptTopComponent,
  ProxyComponent,
  BottomNumbersComponent,
  ThumbsComponent,
  ThumbComponent,
  NumbercellComponent,
  NumbercellsComponent,
  TopTopComponent,
  FlipCardComponent,
  OnlineBettingComponent,
  TimerComponent,
  ThumbsTimerComponent,
  ThumbsTimerNumberComponent
];

@NgModule({
  declarations: comps,
  imports : [
    NgxAudioPlayerModule,
    MobileRoutingModule,
    ...matModule
  ],
  exports: [
    ...comps,
    ...matModule
  ]
})
export class MobileModule { }
