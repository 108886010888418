
 <div style="position: relative; margin-left:{{vars['center-width']}}px;height:80%;" class="white-border">
          <div  style="margin-top:{{marginTop}}px" *ngIf="!die" >
                <mobile-cards-display (event)="eventHandler($event)" ></mobile-cards-display>
              <!-- <div  style="position: absolute; top:{{totalHeight}}px;left:{{vars['center-width']+200}}px;" *ngIf="controlEnabled"> -->
                          <div class="flex-row"  style="position: absolute; left:{{margins.left}}px; top:{{margins.top}}px" *ngIf="controlEnabled" >
                                   <button mat-flat-button  (click)="clicked('dbackward')"
                                      [disabled]="false" [color]="'primary'" >  << </button>

                                    <button mat-flat-button  (click)="clicked('cards')"
                                      [disabled]="disabled.test" [color]="'accent'" class="margin-left-c-buttons">  cards  </button>

                                    <!-- <button mat-flat-button  (click)="clicked('speed')"
                                      [disabled]="disabled.test" [color]="'warn'" class="margin-left-c-buttons">  speed  </button> -->

                                      <button mat-flat-button  (click)="clicked('test')"
                                        [disabled]="disabled.test" [color]="'accent'" class=" margin-left-c-buttons">  test  </button>

                                 <button mat-flat-button   (click)="clicked('forward')"
                                      [disabled]="disabled.forward" [color]="'primary'"  class="margin-left-c-buttons"> <mat-icon > skip_next </mat-icon></button>
                          </div>
            <!--  </div> -->
          </div>
  </div>
  <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" >
    <div ngxSlickItem class="slide">
         <mobile-cards-display (event)="eventHandler($event)" ></mobile-cards-display>
    </div>
   </ngx-slick-carousel> -->
